import React from "react";
import Select from "react-select";
import ReactQuill from "react-quill";
import {
    buildRow,
    getBoolSelectVal,
    hideModal
} from "../../util/FormatUtil";
import {PostedTest} from "../admin/TestManagement";
import {sweetalert} from '../../App';
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {isEmptyObject} from "jquery";
import {CustomFieldBuilder} from "../../customfields/CustomFieldBuilder";
import GetField from "../../customfields/GetField";

interface TestManagementState {
    selectedTest:PostedTest
    allowedResults:{label, value}[]
    allowedSources:{label, value}[]
    customFieldData?
}

interface TestManagementModalProps{
    selectedTest
    onSubmit?:(test:PostedTest) => void
    specimenSources:{label, value}[]
    resultTypes:{label, value}[]
    pageCustomQuestions?
    allResults
}
export class TestManagementModal extends React.Component<TestManagementModalProps, TestManagementState> {
    public static ID = "testmanagement"

    constructor(props) {
        super(props);
        this.state = {
            selectedTest: {
                ID:  null,
                ShortName: '',
                Name:  '',
                IsEnabled: null,
                ReportDescription: '',
                ExportParseURL: '',
                TestOrderedCode: '',
                TestOrderedDescription: '',
                TestPerformedCode: '',
                TestPerformedDescription: '',
                LowRangeStart: '',
                LowRangeEnd: '',
                ModerateRangeStart: '',
                ModerateRangeEnd: '',
                HighRangeStart: '',
                HighRangeEnd: '',
                ValidResults: [],
                ValidSpecimenSources: [],
                CustomFieldData: null,
                PrescriptionRecommendationThreshold: null,
                IncludeInReporting: null,
            } as PostedTest,
            allowedResults:[],
            allowedSources:[]
        }
        this.clearFields = this.clearFields.bind(this)
    }

    componentDidMount(): void {
        $(`#${TestManagementModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    componentWillReceiveProps(nextProps: Readonly<TestManagementModalProps>, nextContext: any) {
        if(!isEmptyObject(nextProps.selectedTest)){
            let test = nextProps.selectedTest;
            this.setState({
                selectedTest: {
                    ID: test.ID,
                    ShortName: test.ShortName,
                    Name: test.Name,
                    IsEnabled: test.IsEnabled !== null ? getBoolSelectVal(test.IsEnabled) : null,
                    ReportDescription: test.ReportDescription,
                    ExportParseURL: test.ExportParseURL,
                    TestOrderedCode: test.TestOrderedCode,
                    TestOrderedDescription: test.TestOrderedDescription,
                    TestPerformedCode: test.TestPerformedCode,
                    TestPerformedDescription: test.TestPerformedDescription,
                    LowRangeStart: test.LowRangeStart,
                    LowRangeEnd: test.LowRangeEnd,
                    ModerateRangeStart: test.ModerateRangeStart,
                    ModerateRangeEnd: test.ModerateRangeEnd,
                    HighRangeStart: test.HighRangeStart,
                    HighRangeEnd: test.HighRangeEnd,
                    CustomFieldData: test.CustomFieldData && JSON.stringify(test.CustomFieldData) !== '{}' ? JSON.parse(test.CustomFieldData) : null,
                    CreatedDate: test.CreatedDate ? test.CreatedDate : new Date(),
                    PrescriptionRecommendationThreshold: test.PrescriptionRecommendationThreshold ? test.PrescriptionRecommendationThreshold : null,
                    IncludeInReporting: test.IncludeInReporting !== null ? getBoolSelectVal(test.IncludeInReporting) : null
                } as PostedTest,
                allowedResults:test.allowedResults ? test.allowedResults : '',
                allowedSources:test.allowedSources ? test.allowedSources: '',
            });
        }
    }

    clearFields(){
        this.setState({
            selectedTest: {
                ID: null,
                ShortName: '',
                Name: '',
                IsEnabled: null,
                ReportDescription: '',
                ExportParseURL: '',
                TestOrderedCode: '',
                TestOrderedDescription: '',
                TestPerformedCode: '',
                TestPerformedDescription: '',
                LowRangeStart: null,
                LowRangeEnd: null,
                ModerateRangeStart: null,
                ModerateRangeEnd: null,
                HighRangeStart: null,
                HighRangeEnd: null,
                ValidResults: [],
                ValidSpecimenSources: [],
                CustomFieldData: null,
                ResultIDs: [],
                SpecimenIDs: [],
                CreatedDate: null,
                PrescriptionRecommendationThreshold: null,
                IncludeInReporting: null,
            } as PostedTest,
            allowedSources:[],
            allowedResults:[]
        })
    }


    render() {

        // console.log('Test Mgmt Modal PROPS', this.props)
        // console.log('Test Mgmt Modal STATE', this.state)

        //checking to see if selected results allow for non-standard values. 
        //if true, display value ranges for test
        let hasNonStandard = false;
        this.state.allowedResults && this.state.allowedResults.map(r => {
            if(this.props.allResults.find(rt => rt.ID === r.value).AllowNonStandardResultValue){
                hasNonStandard = true
            }

        })



        return(
            <div className="modal fade form_modal" id={TestManagementModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Test Management</h5>
                            <button  style={{outline: 'none'}} type="button" onClick={() => this.clearFields()} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body m-3">

                            {buildRow("Name",
                                <input className="form-control"
                                       id="Name"
                                       maxLength={500}
                                       autoComplete="off"
                                       type={"search"}
                                       name={"Name"}
                                       aria-label="Name"
                                       readOnly={this.state.selectedTest.ID != null}
                                       onChange={(e) =>{
                                           this.setState( (prevState) => ({
                                               selectedTest: {
                                                   ...prevState.selectedTest,
                                                   Name: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedTest.Name}
                                />)}
                            {buildRow("Short Name",
                                <input className="form-control"
                                       id="ShortName"
                                       maxLength={45}
                                       autoComplete="off"
                                       type={"search"}
                                       name={"ShortName"}
                                       aria-label="Short Name"
                                       onChange={(e) =>{
                                           this.setState( (prevState) => ({
                                               selectedTest: {
                                                   ...prevState.selectedTest,
                                                   ShortName: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedTest.ShortName}
                                />)}
                            {buildRow("Is Enabled?",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={this.state.selectedTest.IsEnabled}
                                    aria-label="Is Enabled?"
                                    onChange={(e) => this.setState((prevState)=>({
                                        selectedTest: {
                                            ...prevState.selectedTest,
                                            IsEnabled: e
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                            />)}
                            {buildRow("Include in Reporting?",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={this.state.selectedTest.IncludeInReporting}
                                    aria-label="Include in Reporting?"
                                    onChange={(e) => this.setState((prevState)=>({
                                        selectedTest: {
                                            ...prevState.selectedTest,
                                            IncludeInReporting: e
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                            />)}
                            {buildRow("Test Ordered Code (LOINC)",
                                <input className="form-control"
                                       id="TestOrderedCode(LOINC)"
                                       maxLength={500}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"Test Ordered Code (LOINC)"}
                                       aria-label="Test Ordered Code (LOINC)"
                                       onChange={(e) =>{
                                           this.setState( (prevState) => ({
                                               selectedTest: {
                                                   ...prevState.selectedTest,
                                                   TestOrderedCode: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedTest.TestOrderedCode}
                                />)}
                            {buildRow("Test Ordered Description (LOINC)",
                                <input className="form-control"
                                       id="TestOrderedDescription(LOINC)"
                                       maxLength={500}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"Test Ordered Description (LOINC)"}
                                       aria-label="Test Ordered Description (LOINC)"
                                       onChange={(e) =>{
                                           this.setState( (prevState) => ({
                                               selectedTest: {
                                                   ...prevState.selectedTest,
                                                   TestOrderedDescription: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedTest.TestOrderedDescription}
                                />)}
                            {buildRow("Test Performed Code (LOINC)",
                                <input className="form-control"
                                       id="TestPerformedCode(LOINC)"
                                       maxLength={500}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"Test Performed Code (LOINC)"}
                                       aria-label="Test Performed Code (LOINC)"
                                       onChange={(e) =>{
                                           this.setState( (prevState) => ({
                                               selectedTest: {
                                                   ...prevState.selectedTest,
                                                   TestPerformedCode: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedTest.TestPerformedCode}
                                />)}
                            {buildRow("Test Performed Description (LOINC)",
                                <input className="form-control"
                                       id="TestPerformedDescription(LOINC)"
                                       maxLength={500}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"Test Performed Description (LOINC)"}
                                       aria-label="Test Performed Description (LOINC)"
                                       onChange={(e) =>{
                                           this.setState( (prevState) => ({
                                               selectedTest: {
                                                   ...prevState.selectedTest,
                                                   TestPerformedDescription: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedTest.TestPerformedDescription}
                                />)}
                            {buildRow("Allowed Results",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    isMulti={true}
                                    value={this.state.allowedResults}
                                    aria-label="Allowed Results"
                                    onChange={(e) =>{
                                        if(!hasNonStandard){
                                            this.setState( (prevState) => ({
                                                selectedTest: {
                                                    ...prevState.selectedTest,
                                                    LowRangeStart: null,
                                                    LowRangeEnd: null,
                                                    ModerateRangeStart: null,
                                                    ModerateRangeEnd: null,
                                                    HighRangeStart: null,
                                                    HighRangeEnd: null
                                                }
                                            }))
                                        }
                                        this.setState({allowedResults: e as any})
                                    }}
                                    className={"state_select"}
                                    options={this.props.resultTypes}
                                />)}


                            {/* if hasNonStandard is true, display ranges  */}
                            {hasNonStandard && 
                            <>
                            <div>
                                <div className="row">
                                    <div className="col-6">
                                    {
                                        buildRow("Low Range Start",
                                        <input className="form-control"
                                               id="LowRangeStart"
                                               maxLength={500}
                                               autoComplete={"off"}
                                               type={"number"}
                                               name={"Low Range Start"}
                                               aria-label="Low Range Start"
                                               onChange={(e) =>{
                                                   this.setState( (prevState) => ({
                                                       selectedTest: {
                                                           ...prevState.selectedTest,
                                                           LowRangeStart: e.target.value
                                                       }
                                                   }))
                                               }}
                                               value={this.state.selectedTest.LowRangeStart}
                                        /> 
                                        )
                                    }
                                    </div>
                                    <div className="col-6">
                                    {
                                        buildRow("Low Range End",
                                        <input className="form-control"
                                               id="LowRangeEnd"
                                               maxLength={500}
                                               autoComplete={"off"}
                                               type={"number"}
                                               name={"Low Range End"}
                                               aria-label="Low Range End"
                                               onChange={(e) =>{
                                                   this.setState( (prevState) => ({
                                                       selectedTest: {
                                                           ...prevState.selectedTest,
                                                           LowRangeEnd: e.target.value
                                                       }
                                                   }))
                                               }}
                                               value={this.state.selectedTest.LowRangeEnd}
                                        /> 
                                        )
                                    }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                    {
                                        buildRow("Mod Range Start",
                                        <input className="form-control"
                                            id="ModRangeStart"
                                            maxLength={500}
                                            autoComplete={"off"}
                                            type={"number"}
                                            name={"Mod Range Start"}
                                            aria-label="Mod Range Start"
                                            onChange={(e) =>{
                                                this.setState( (prevState) => ({
                                                    selectedTest: {
                                                        ...prevState.selectedTest,
                                                        ModerateRangeStart: e.target.value
                                                    }
                                                }))
                                            }}
                                            value={this.state.selectedTest.ModerateRangeStart}
                                        /> 
                                        )
                                    }
                                    </div>
                                    <div className="col-6">
                                    {
                                        buildRow("Mod Range End",
                                        <input className="form-control"
                                            id="ModRangeEnd"
                                            maxLength={500}
                                            autoComplete={"off"}
                                            type={"number"}
                                            name={"Mod Range End"}
                                            aria-label="Mod Range End"
                                            onChange={(e) =>{
                                                this.setState( (prevState) => ({
                                                    selectedTest: {
                                                        ...prevState.selectedTest,
                                                        ModerateRangeEnd: e.target.value
                                                    }
                                                }))
                                            }}
                                            value={this.state.selectedTest.ModerateRangeEnd}
                                        /> 
                                        )
                                    }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                    {
                                        buildRow("High Range Start",
                                        <input className="form-control"
                                               id="HighRangeStart"
                                               maxLength={500}
                                               autoComplete={"off"}
                                               type={"number"}
                                               name={"High Range Start"}
                                               aria-label="High Range Start"
                                               onChange={(e) =>{
                                                   this.setState( (prevState) => ({
                                                       selectedTest: {
                                                           ...prevState.selectedTest,
                                                           HighRangeStart: e.target.value
                                                       }
                                                   }))
                                               }}
                                               value={this.state.selectedTest.HighRangeStart}
                                        /> 
                                        )
                                    }
                                    </div>
                                    <div className="col-6">
                                    {
                                        buildRow("High Range End",
                                        <input className="form-control"
                                               id="HighRangeEnd"
                                               maxLength={500}
                                               autoComplete={"off"}
                                               type={"number"}
                                               name={"High Range End"}
                                               aria-label="High Range End"
                                               onChange={(e) =>{
                                                   this.setState( (prevState) => ({
                                                       selectedTest: {
                                                           ...prevState.selectedTest,
                                                           HighRangeEnd: e.target.value
                                                       }
                                                   }))
                                               }}
                                               value={this.state.selectedTest.HighRangeEnd}
                                        /> 
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                {
                                        buildRow("Prescription Recommendation Threshold",
                                        <input className="form-control"
                                               id="PrescriptionRecommendationThreshold"
                                               maxLength={500}
                                               autoComplete={"off"}
                                               type={"number"}
                                               name={"Prescription Recommendation Threshold"}
                                               aria-label="Prescription Recommendation Threshold"
                                               onChange={(e) =>{
                                                   this.setState( (prevState) => ({
                                                       selectedTest: {
                                                           ...prevState.selectedTest,
                                                           PrescriptionRecommendationThreshold: e.target.value
                                                       }
                                                   }))
                                               }}
                                               value={this.state.selectedTest.PrescriptionRecommendationThreshold
                                               }
                                        /> 
                                        )
                                    }
                                </div>
                            </div>
                            </>
                            }

                            {buildRow("Allowed Specimen Source",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    isMulti={true}
                                    value={this.state.allowedSources}
                                    aria-label="Allowed Specimen Source"
                                    onChange={(e) =>{
                                        this.setState({allowedSources: e as any})
                                    }}
                                    className={"state_select"}
                                    options={this.props.specimenSources}
                                />)}

                            {/*Custom Field Data*/}

                            {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField 
                                                            val={val} 
                                                            selectedItem={this.state.selectedTest ? this.state.selectedTest : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedTest: e})
                                                            }}  
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }


                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Test Result Description</label>
                                <div className="col-sm-12  p-0 m-0">
                                    <ReactQuill theme="snow" 
                                        value={this.state.selectedTest.ReportDescription}
                                        // modules={{keyboard: {
                                        //     bindings: {
                                        //     tab: {
                                        //         key: 9,
                                        //         handler: function (range, context) {
                                        //         return true;
                                        //         },
                                        //     },
                                        //     },
                                        // }}}
                                                onChange={(e) =>{
                                                    this.setState( (prevState) => ({
                                                        selectedTest: {
                                                            ...prevState.selectedTest,
                                                            ReportDescription: e
                                                        }
                                                    }))
                                                }}
                                    />
                                </div>
                            </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    let formValidation = {
                                        Name: this.state.selectedTest.Name,
                                        ShortName: this.state.selectedTest.ShortName,
                                        IsEnabled: this.state.selectedTest.IsEnabled,
                                        TestOrderedCode: this.state.selectedTest.TestOrderedCode,
                                        TestOrderedDescription: this.state.selectedTest.TestOrderedDescription,
                                        TestPerformedCode: this.state.selectedTest.TestPerformedCode,
                                        TestPerformedDescription: this.state.selectedTest.TestPerformedDescription,
                                        LowRangeStart: this.state.selectedTest.LowRangeStart ? this.state.selectedTest.LowRangeStart : null,
                                        LowRangeEnd: this.state.selectedTest.LowRangeEnd ? this.state.selectedTest.LowRangeEnd : null,
                                        ModerateRangeStart: this.state.selectedTest.ModerateRangeStart ? this.state.selectedTest.ModerateRangeStart : null,
                                        ModerateRangeEnd: this.state.selectedTest.ModerateRangeEnd ? this.state.selectedTest.ModerateRangeEnd : null,
                                        HighRangeStart: this.state.selectedTest.HighRangeStart ? this.state.selectedTest.HighRangeStart : null,
                                        HighRangeEnd: this.state.selectedTest.HighRangeEnd ? this.state.selectedTest.HighRangeEnd : null,
                                        PrescriptionRecommendationThreshold: this.state.selectedTest.PrescriptionRecommendationThreshold ? this.state.selectedTest.PrescriptionRecommendationThreshold : null,
                                        IncludeInReporting: this.state.selectedTest.IncludeInReporting,
                                        AllowResults: this.state.allowedResults && this.state.allowedResults.length > 0 ? this.state.allowedResults : null,
                                        AllowSources: this.state.allowedSources && this.state.allowedSources.length > 0 ? this.state.allowedSources : null
                                    }

                                    let validator = new Validator<any>()
                                        .withValidation("Name", Validators.requireNotBlankAndLength(500))
                                        .withValidation("ShortName", Validators.requireNotBlankAndLength(45, "Short Name"))
                                        .withSimpleValidation("IsEnabled", Validators.requireNonNullValidator("Is Enabled"))
                                        .withSimpleValidation("IncludeInReporting", Validators.requireNonNullValidator("Include In Reporting"))
                                        .withValidation("TestOrderedCode", Validators.requireNotBlankAndLength(500, "Test Ordered Code"))
                                        .withValidation("TestOrderedDescription", Validators.requireNotBlankAndLength(500, "Test Ordered Description"))
                                        .withValidation("TestPerformedCode", Validators.requireNotBlankAndLength(500, "Test Performed Code"))
                                        .withValidation("TestPerformedDescription", Validators.requireNotBlankAndLength(500, "Test Performed Description"))
                                        .withSimpleValidation("AllowResults", Validators.requireNonNullValidator("Allow Results"))
                                        .withSimpleValidation("AllowSources", Validators.requireNonNullValidator("Allow Specimen Sources"))
                                        if(this.state.selectedTest.LowRangeStart){
                                            validator = validator.withSimpleValidation("LowRangeStart", Validators.requireIsInt("Low Range Start"))
                                        }
                                        if(this.state.selectedTest.LowRangeEnd){
                                            validator = validator.withSimpleValidation("LowRangeEnd", Validators.requireIsInt("Low Range End"))
                                        }
                                        if(this.state.selectedTest.ModerateRangeStart){
                                            validator = validator.withSimpleValidation("ModerateRangeStart", Validators.requireIsInt("Moderate Range Start"))
                                        }
                                        if(this.state.selectedTest.ModerateRangeEnd){
                                            validator = validator.withSimpleValidation("ModerateRangeEnd", Validators.requireIsInt("Moderate Range End"))
                                        }
                                        if(this.state.selectedTest.HighRangeStart){
                                            validator = validator.withSimpleValidation("HighRangeStart", Validators.requireIsInt("High Range Start"))
                                        }
                                        if(this.state.selectedTest.HighRangeEnd){
                                            validator = validator.withSimpleValidation("HighRangeEnd", Validators.requireIsInt("High Range End"))
                                        }
                                        if(this.state.selectedTest.PrescriptionRecommendationThreshold){
                                            validator = validator.withSimpleValidation("PrescriptionRecommendationThreshold", Validators.requireIsInt("Prescription Recommendation Threshold"))
                                        }
                                    let validationResponse = validator.validate(formValidation);
                                    if(!validationResponse.success) {
                                        return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
                                    }

                                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedTest.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedTest)

                                    if(invalid){
                                        return;
                                    }

                                    hideModal(TestManagementModal.ID)
                                    this.props.onSubmit(
                                        {
                                            ID: this.state.selectedTest.ID,
                                            Name: this.state.selectedTest.Name,
                                            ShortName: this.state.selectedTest.ShortName,
                                            ReportDescription: this.state.selectedTest.ReportDescription,
                                            IsEnabled: this.state.selectedTest.IsEnabled ? this.state.selectedTest.IsEnabled.value : null,
                                            TestOrderedCode: this.state.selectedTest.TestOrderedCode,
                                            TestOrderedDescription: this.state.selectedTest.TestOrderedDescription,
                                            TestPerformedCode: this.state.selectedTest.TestPerformedCode,
                                            TestPerformedDescription: this.state.selectedTest.TestPerformedDescription,
                                            LowRangeStart: hasNonStandard ? this.state.selectedTest.LowRangeStart : null,
                                            LowRangeEnd: hasNonStandard ?  this.state.selectedTest.LowRangeEnd : null,
                                            ModerateRangeStart: hasNonStandard ?  this.state.selectedTest.ModerateRangeStart : null,
                                            ModerateRangeEnd: hasNonStandard ?  this.state.selectedTest.ModerateRangeEnd : null,
                                            HighRangeStart: hasNonStandard ?  this.state.selectedTest.HighRangeStart : null,
                                            HighRangeEnd: hasNonStandard ? this.state.selectedTest.HighRangeEnd : null,
                                            CustomFieldData: this.state.selectedTest.CustomFieldData,
                                            PrescriptionRecommendationThreshold: this.state.selectedTest.PrescriptionRecommendationThreshold ? this.state.selectedTest.PrescriptionRecommendationThreshold : null,
                                            IncludeInReporting: this.state.selectedTest.IncludeInReporting ? this.state.selectedTest.IncludeInReporting.value : null,
                                            ResultIDs:this.state.allowedResults ? this.state.allowedResults.map(a => a.value) : [],
                                            SpecimenIDs:this.state.allowedSources ? this.state.allowedSources.map(a => a.value) : [],
                                        } as PostedTest)
                                    this.setState({
                                        selectedTest: {
                                            ID: null,
                                            ShortName: '',
                                            Name: '',
                                            IsEnabled: null,
                                            ReportDescription: '',
                                            ExportParseURL: '',
                                            TestOrderedCode: '',
                                            TestOrderedDescription: '',
                                            TestPerformedCode: '',
                                            TestPerformedDescription: '',
                                            LowRangeStart: null,
                                            LowRangeEnd : null,
                                            ModerateRangeStart : null,
                                            ModerateRangeEnd : null,
                                            HighRangeStart : null,
                                            HighRangeEnd : null,
                                            ValidResults: [],
                                            ValidSpecimenSources: [],
                                            CustomFieldData: null,
                                            PrescriptionRecommendationThreshold: null,
                                            IncludeInReporting: null,
                                            ResultIDs: [],
                                            SpecimenIDs: [],
                                            CreatedDate: null
                                        } as PostedTest,
                                        allowedSources:[],
                                        allowedResults:[]
                                    })
                                }}>
                                    Submit
                                </button>
                            </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}