import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import {
    createCustomFieldDataArray,
    getBoolSelectOptions, getBoolSelectVal, getFieldTypeVal, getNumPages, getPageOptions,
    hideModal, showModalNoOutsideClick, slicePages
} from "../../util/FormatUtil";
import {sweetalert} from "../../App";
import Select from "react-select";
import NetworkUtil from "../../network/NetworkUtil";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import {CustomFieldsModal} from "../modals/CustomFieldsModal";
import {
    CustomFieldType,
    CustomFieldTypesObj,
    CustomFieldTypesPages
} from "../../types/CustomFieldType";

const ITEMS_PER_PAGE = 25;

interface CustomFieldsState {
    showLoading:boolean
    selectedCustomField:CustomFieldType
    allCustomFields:CustomFieldType[]
    customFieldQueryResults:CustomFieldType[]
    tableData:{}[]
    filter?:{
        DisplayName
        FieldType
        ValidPages
        IsRequired
        IsPHI
        IsActive
    }
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    displayNames:{label,value}[]
    fieldTypes:{label,value}[]
    validPages:{label,value}[]
}

export class CustomFields extends React.Component<any, CustomFieldsState> {

    constructor(props) {
        super(props);

        this.state = {
            showLoading: false,
            selectedCustomField: {} as CustomFieldType,
            allCustomFields: [],
            tableData: [] as any,
            customFieldQueryResults: [] as any,
            filter: {DisplayName:[], FieldType:[], ValidPages:[], IsRequired:false, IsPHI:false, IsActive: false},
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            displayNames:[],
            fieldTypes:[],
            validPages:[]
        }

    //     this.createOrModifyService = this.createOrModifyService.bind(this);
    }

    componentDidMount() {
        document.title = 'Custom Fields Management Page';
        this.loadCustomFields();
    }

    loadCustomFields(){
        let tempDisplayNameArray = [];
        this.setState({showLoading: true}, async ()=>{
            CustomFieldsAPI.getAllCustomFields().then(data => {
                console.log('data in getAllCustomFields', data.data.customFields)
                this.setState({allCustomFields: data.data.customFields})
                if(data.data.customFields.length > 0){
                    data.data.customFields.map(field => {
                        if(field.DisplayName){
                            tempDisplayNameArray.push({label: field.DisplayName, value: field.DisplayName});
                        }
                    })
                }
                this.setState({displayNames: tempDisplayNameArray, validPages: createCustomFieldDataArray(CustomFieldTypesPages), fieldTypes: createCustomFieldDataArray(CustomFieldTypesObj), showLoading: false})
            })
                .catch(e => {
                    return sweetalert.fire({icon: 'info', title: '', text: "No Services found."})
                })
        });
    }

    queryCustomFieldData(page:number){
        this.setState({showLoading: true}, async () => {
            let results = await CustomFieldsAPI.filterCustomFields({
                filter: {DisplayName: this.state.filter.DisplayName, FieldTypeID: this.state.filter.FieldType, ValidPages: this.state.filter.ValidPages, IsRequired: this.state.filter.IsRequired, IsPHI: this.state.filter.IsPHI, IsActive: this.state.filter.IsActive}
            });

            if(!results.success){
                return sweetalert.fire({title: '', text: 'Unable to filter Custom Field data at this time', icon: 'error'})
            }

            if(results.data.length < 1){
                return sweetalert.fire({icon: 'warning', title: '', text: "No Custom Fields found."}), this.setState({showLoading: false, tableData: [], customFieldQueryResults: [], page_options: [{label: "1", value: "1"}]})
            }

            this.setState({
                tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
                customFieldQueryResults: results.data,
                page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
                showLoading: false,
            });
        });
    }


    handleExportToCSV() {
        this.setState({showLoading: true}, async () => {
            await NetworkUtil.downloadCSV(
                "/api/admin/customFields/csv",
                "Custom Fields Management Data.xlsx",
                {filter: this.state.filter}
            )
            this.setState({showLoading: false});
        })
    }



    changePage(page:number){
        let allCustomFields = this.state.customFieldQueryResults;
        let returnData = slicePages(allCustomFields, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    renderServicesFilterFields(){
        return (
            <FilterCard fields={[
                {
                    label: "Display Name",
                    "key": "DisplayName",
                    type: FIELD_TYPE.SELECT,
                    options: this.state.displayNames,
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    popoverText: 'Name displayed on page',
                    isFilter: true
                },
                {
                    label: "Field Type",
                    "key": "FieldType",
                    type: FIELD_TYPE.SELECT,
                    options: this.state.fieldTypes,
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    popoverText: 'Type of field',
                    isFilter: true
                },
                {
                    label: "Displayed Pages",
                    "key": "ValidPages",
                    type: FIELD_TYPE.SELECT,
                    options: this.state.validPages,
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    popoverText: 'Pages field is displayed on',
                    isFilter: true
                },
                {
                    label: "Is Required",
                    "key": "IsRequired",
                    type: FIELD_TYPE.SELECT,
                    options: getBoolSelectOptions(),
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    popoverText: 'Is field required?',
                    isFilter: true
                },
                {
                    label: "Is PHI",
                    "key": "IsPHI",
                    type: FIELD_TYPE.SELECT,
                    options: getBoolSelectOptions(),
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    popoverText: "Is field Protected Health Information?",
                    isFilter: true
                },
                {
                    label: "Is Active",
                    "key": "IsActive",
                    type: FIELD_TYPE.SELECT,
                    options: getBoolSelectOptions(),
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    popoverText: 'Is field active? (inactive fields are not displayed)',
                    isFilter: true
                }
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()

        this.setState({tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
    }

    render (){

        console.log('CustomFields state', this.state)

        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading} zIndex={100002}/>
                <CustomFieldsModal
                    fieldTypes={this.state.fieldTypes}
                    validPages={this.state.validPages}
                    selectedCustomField={this.state.selectedCustomField}
                    isCustomField={true}
                    onSubmitCustomField={() => {
                        hideModal(CustomFieldsModal.ID);
                        this.loadCustomFields();
                        this.queryCustomFieldData(this.state.selected_page.value);
                    }}
                />

                <div className="container-fluid ">
                    <div className={"row"}>
                        <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
                            <main id="main-content" tabIndex={-1} aria-label="Custom Fields Management">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Custom Field Management</h4>
                                </div>
                                <div className="card-body">
                                    {this.renderServicesFilterFields()}
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline"}
                                            onClick={ () => {
                                                this.setState({
                                                    selected_page: {label: 1, value: 1}}, () => {
                                                    this.queryCustomFieldData(this.state.selected_page.value)
                                                })
                                            }}
                                    >Search</button>
                                    <button className="btn btn-outline-success float-right"
                                            onClick={()=> this.setState({
                                                selectedCustomField: {
                                                    ID: null,
                                                    DisplayName: "",
                                                    FieldTypeID: null,
                                                    ServiceID: null,
                                                    IsPatientField: 0,
                                                    Options: {}
                                                } as CustomFieldType
                                            },()=>{
                                                showModalNoOutsideClick(CustomFieldsModal.ID)
                                            })}
                                    >Create New</button>
                                </div>
                            </div>
                            </main>
                        </div>
   
                        {
                            this.state.tableData &&
                            this.state.tableData.length > 0 &&

                            <div className="col-md-12 pt-2">
                                <div className="card mt-2 mb-5">
                                    <div className="card-header verlag-bold">
                                        <h4 className="text-center text-md-left">Custom Fields
                                        <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                            <h4 className={'float-md-right'} aria-label="Total Records" role="alert">Total: {this.state.customFieldQueryResults.length}</h4>
                                            <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                            <div className=" align-middle float-right pages ">
                                                <Select
                                                    isSearchable={true}
                                                    placeholder={"1"}
                                                    noOptionsMessage={() => "No option"}
                                                    value={this.state.selected_page}
                                                    aria-label="Table Page Number"
                                                    onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                        () => this.changePage(e.value))}
                                                    className={"state_select"}
                                                    options={this.state.page_options}
                                                />
                                            </div>
                                        </section>
                                        <button className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                                onClick={() => this.handleExportToCSV()}
                                        >Export to CSV
                                        </button>
                                        </h4>
                                    </div>
                                    <div className="card-body p-0 m-0 table-responsive">
                                        <SimpleTable table_style='tableFixHead' columns={[
                                            {
                                                label: "Display Name", key: "DisplayName",
                                                rawFormat: (val) => {
                                                    return <a href={"#!"} className={'tableNameLinkColor'} onClick={() => {
                                                        this.setState({ selectedCustomField: val },
                                                            () => showModalNoOutsideClick(CustomFieldsModal.ID))
                                                    }}>{val.DisplayName}</a>
                                                },
                                                popoverText: "Click to sort by Display Name"
                                            },
                                            {
                                                label: "Field Type", key: "FieldTypeID",
                                                popoverText: "Click to sort by Field Type",
                                                rawFormat: (val) => {
                                                    let string = getFieldTypeVal(val.FieldTypeID)
                                                    return <p>{string.label}</p>
                                                }
                                            },
                                            {
                                                label: "Valid Pages", key: "ValidPages",
                                                popoverText: "Click to sort by Valid Pages",
                                                rawFormat: (val) => {
                                                    let vp = val.Options.validPages.map(p => p + ", ");
                                                    let lastInd = vp.length - 1;
                                                    if (lastInd >= 0)
                                                        vp[lastInd] = vp[lastInd].slice(0, vp[lastInd].length - 2); // Remove last space
                                                    return <p>{vp}</p>
                                                }
                                            },
                                            {
                                                label: "Is Required", key: "IsRequired",
                                                popoverText: "Click to sort by Is Required",
                                                rawFormat: (val) => {
                                                    let string = getBoolSelectVal(val.Options.isRequired)
                                                    return <p>{string.label}</p>
                                                }
                                            },
                                            {
                                                label: "Is PHI", key: "IsPHI",
                                                popoverText: "Click to sort by Is PHI",
                                                rawFormat: (val) => {
                                                    let string = getBoolSelectVal(val.Options.isPHI)
                                                    return <p>{string.label}</p>
                                                }
                                            },
                                            {
                                                label: "Is Active", key: "IsActive",
                                                popoverText: "Click to sort by Is Active",
                                                rawFormat: (val) => {
                                                    let string = getBoolSelectVal(val.Options.isActive)
                                                    return <p>{string.label}</p>
                                                }
                                            },

                                        ]} table_data={this.state.tableData} columnClickedCallback={(col =>{
                                            this.useSorter(col);
                                        })} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}