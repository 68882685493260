import NetworkUtil from "./NetworkUtil";
import {TestReport, TestReportAddtlInfo} from "../types/TestReport";
import {ServerResponse} from "../types/ServerResponse";

export default class SubmissionAPI {

    public static async getEvents():Promise<any>{
        return NetworkUtil.makeGet("/api/events");
    }

    public static async patientForm(patientInformation, insuranceInformation, servicesObjectsArray, roiObjectsArray, insuranceCards):Promise<{success:boolean, ReqNums:string, LinkedApptsID:string, timeTaken:boolean, reason:string}>{
        let data = new FormData()
        data.set('insuranceInformation', JSON.stringify(insuranceInformation))
        data.set('patientInformation', JSON.stringify(patientInformation))
        data.set('servicesObjectsArray', JSON.stringify(servicesObjectsArray))
        data.set('roiObjectsArray', JSON.stringify(roiObjectsArray))
        data.set('token', patientInformation.token)
        data.set('release', patientInformation.release)
        if(insuranceCards){
            for(let i = 0; i < insuranceCards.length; i++){
                let ext = insuranceCards[i].name.split('.').pop();
                data.append('insuranceCards', insuranceCards[i], `${i === 0 ? 'front' : 'back'}.${ext}`)
            }
        }
        return NetworkUtil.postForm("/api/submission", data);
    }

    public static async getApptInfoForCancel(appointment):Promise<{apptInfo}>{
        return NetworkUtil.makePost("/api/submission/getApptInfoForCancel", appointment);
    }

    public static async appointmentCancel(appointment):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/submission/cancel", appointment);
    }

    public static async submitResults(filterObj, token):Promise<{success:boolean, reason:string, result:TestReportAddtlInfo}>{
        return NetworkUtil.makePost("/api/submission/results", {filter: filterObj, token: token});
    }

    public static async submitResultsForPDF(filterObj, token):Promise<any>{
        return NetworkUtil.makePostForBuffer("/api/submission/resultsForPDF", {filter: filterObj, token: token});
    }

    public static async release(entity, reqNum, token):Promise<{success:boolean, reason:string, result:TestReportAddtlInfo}>{
        return NetworkUtil.makePost("/api/submission/release", {entity: entity, reqNum: reqNum, token: token});
    }

    public static async revokeConsent(reqNum, token):Promise<{success:boolean, reason:string, result:TestReport}>{
        return NetworkUtil.makePost("/api/submission/revokeConsent", {reqNum: reqNum, token: token});
    }

    //route not complete
    public static async consent(entity):Promise<{success:boolean, reason:string, result:TestReportAddtlInfo}>{
        return NetworkUtil.makePost("/api/submission/consent", entity);
    }

}