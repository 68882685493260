import {superscript} from "numbers-to-superscript";
import { getNameFromID } from "../../../util/FormatUtil";

export const getSuperscripts = (value) => {
  // console.log(value)
    switch (value) {
        case 0.999:
        return '0'
        case 10:
        return '10' + superscript('1');
        case 100:
        return '10' + superscript('2');
        case 1000:
        return '10' + superscript('3');
        case 10000:
        return '10' + superscript('4');
        case 100000:
        return '10' + superscript('5');
        case 1000000:
        return '10' + superscript('6');
        case 10000000:
        return '10' + superscript('7');
        case 100000000:
        return '10' + superscript('8');
        case 1000000000:
        return '10' + superscript('9');
        case 10000000000:
        return '10' + superscript('10');
        case 100000000000:
        return '10' + superscript('11');
        case 1000000000000:
        return '10' + superscript('12');
        case 10000000000000:
        return '10' + superscript('13');
        case 100000000000000:
        return '10' + superscript('14');
        case 1000000000000000:
        return '10' + superscript('15');
        case 10000000000000000:
        return '10' + superscript('16');
        case 100000000000000000:
        return '10' + superscript('17');
    }
}

export const getExponent = (length) => {
    switch (length) {
        case 0:
          return '0';
        case 1:
          return '10' + superscript('1');
        case 2:
          return '10' + superscript('2');
        case 3:
          return '10' + superscript('3');
        case 4:
          return '10' + superscript('4');
        case 5:
          return '10' + superscript('5');
        case 6:
          return '10' + superscript('6');
        case 7:
          return '10' + superscript('7');
        case 8:
          return '10' + superscript('8');
        case 9:
          return '10' + superscript('9');
        case 10:
          return '10' + superscript('10');
        case 11:
          return '10' + superscript('11');
        case 12:
          return '10' + superscript('12');
        case 13:
          return '10' + superscript('13');
        case 14:
          return '10' + superscript('14');
        case 15:
          return '10' + superscript('15');
        case 16:
          return '10' + superscript('16');
        case 17:
          return '10' + superscript('17');
      }
}

export const getConvertedValue  = (value) => {
  if(value === 0){
    return '0';
  }
  let stringValue = String(value);
  let lengthOfStringValue = stringValue.length;
  let exponent;

  if(lengthOfStringValue > 0){
      exponent = getExponent(lengthOfStringValue-1)
  }
  else{
    return null
  }

  let convertedValue = exponent == 0 ? `${stringValue[0]}` : `${stringValue[0]}.${stringValue[1]}x${exponent}`;
  return convertedValue
}

export const dateFormatter = (date) => {
    // let d = date.toLocaleDateString('en-US', {
    //     day: '2-digit',
    //     month: '2-digit',
    //     year: '2-digit'
    // })

    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    let year = String(date.getFullYear());

    month = month[0] === '0' ? month[1] : month;
    day = day[0] === '0' ? day[1] : day;

    return `${month}/${day}/${year}`;
}



export const getColor = (resultID, allResults) => {
  let resultObj = allResults.find(f => f.value === resultID)
  let resultValueString = resultObj.label;
  if(resultValueString === 'Not Detected (non-standard)'){
    return '#949494';
  }
  else if(resultValueString === 'Low'){
    return '#13AC33';
  }
  else if(resultValueString === 'Moderate'){
    return '#FFB400';
  }
  else if(resultValueString === 'High'){
    return '#DF0000';
  }
  else{
    return '#949494';
  }
}

export const getBarBackgroundColor = (context, propsObj, allResults) => {

  let testsAndResultsMapArray;
  if(propsObj.TestsAndResultsMap && typeof propsObj.TestsAndResultsMap === 'string'){
    testsAndResultsMapArray = JSON.parse(propsObj.TestsAndResultsMap);
  }
  else{
    testsAndResultsMapArray = propsObj.result.TestsAndResultsMap;
  }
  let resultIDsArray = [];
  testsAndResultsMapArray.map(t => {
    resultIDsArray.push(t.ResultID)
  })

for (let i = 0; i < resultIDsArray.length; i++) {
  if(i === context.dataIndex){
    let resultObj = allResults.find(f => f.value === resultIDsArray[context.dataIndex])
    let resultValueString = resultObj.label;

    if(resultValueString === 'Not Detected (non-standard)'){
      return '#949494';
    }
    else if(resultValueString === 'Low'){
      return '#13AC33';
    }
    else if(resultValueString === 'Moderate'){
      return '#FFB400';
    }
    else if(resultValueString === 'High'){
      return '#DF0000';
    }
    else{
      return '#949494';
    }
  }
}
}

export const getAbbreviations = (testsArray) => {
  let abbreviations = []
  if(!testsArray || testsArray.length < 1){
    return
  }
  testsArray.map(t => {
    abbreviations.push(t.ShortName)
  })

  return abbreviations
}

export const checkThreshold = (testsArray, testsAndResultsMap) => {
  let testMeetsOrExceedsArray = [];
  testsAndResultsMap.map(t => {
    let test = testsArray.find(f => f.ID === t.TestID)
    if(Number(t.ResultValue) >= test.PrescriptionRecommendationThreshold){
      testMeetsOrExceedsArray.push(test.ShortName)
    }
  })
  return testMeetsOrExceedsArray;
}

export const getAllergies = (customData) => {
  let allergies = [];
  if(customData){
      for(const key in customData){
          if(key.toLowerCase().includes('allergies') || key.toLowerCase().includes('allergy')){
              if(customData[key] == 'Penicillin' || customData[key] == 'Penicillin/Amoxicillin' || customData[key] == 'penicillin' || customData[key] == 'Metronidazole' || customData[key] == 'metronidazole'){
                allergies = allergies.concat(customData[key])
              }
          }
      }
  }
  return allergies
}

export const getRxRecommendation = (testsArray, allergies) => {
  //only get in this function if at least one test meets/exceeds threshold

  if(allergies && allergies.length > 0){

    //PENICILLIN ALLERGY
    if(allergies.includes('Penicillin') || allergies.includes('Penicillin/Amoxicillin') || allergies.includes('penicillin')){
      //GET AZITH
      return 'Azithromycin 500mg loading dose then 250mg/d q 5 d';
    }

    //NO PENICILLIN ALLERGY
    else{

      //METRO ALLERGY
      if(allergies.includes('Metronidazole') || allergies.includes('metronidazole')){

      //ANY OVER THRESHOLD GET AMOX
        if(testsArray && testsArray.length > 0){
          return 'Amoxicillin 500mg tid q 8 d';
        }

        //NO TEST OVER THRESHOLD - SHOULD NOT GET HERE
        else{
          return
        }
      }

      //NO PENICILLIN OR METRO ALLERGY - SHOULDNT GET HERE
      else{
        return 
      }
    }
  }

  //NO ALLERGIES
  else{
    
    //CHECK LEVELS
    //IF Aa LEVEL IS UP AND ALL OTHERS ARE UNDER GET AMOX
    if(testsArray && testsArray.includes('Aa') && testsArray.length === 1){
      return 'Amoxicillin 500mg tid q 8 d';
    }
    //IF ANY OTHERS ARE UP GET METRO AND AMOX
    else if(testsArray && testsArray.length > 0 && !(testsArray.includes('Aa') && testsArray.length === 1)){
      return 'Metronidazole 250mg tid q 7 d and Amoxicillin 500mg tid q 8 d';
    }
    
  }
}