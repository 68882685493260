import NetworkUtil from "./NetworkUtil";
import Facility, {FacilitySearch} from "../types/Facility";
import {ServerResponse} from "../types/ServerResponse";

export class FacilityAPI{

    public static facilitySearch(filter, page):Promise<{num_pages:number, table_data?:FacilitySearch[],  data:FacilitySearch[]}>{
        return NetworkUtil.makePost("/api/admin/searchFacility?page=" + page, filter);
    }

    public static facilityUpsert(facility:Facility):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/facilityUpsert", {facility: facility});
    }

    // after PaymentAPI.createCustomerProfile() we FacilityAPI.addANetProfileToFacility
    public static addANetCustomerProfileIDs({facilityID, aNetIDs}):Promise<{success:boolean,reason:string,data:Facility}>{
        return NetworkUtil.makePost("/api/admin/addANetProfileToFacility", {facilityID: facilityID, aNetIDs: aNetIDs});
    }
}