import React from "react";
import ModalForm from "./ModalForm";

interface InsuranceImageZoomProps{
    image: string;
    handleLoading?;
}

interface InsuranceImageZoomState{
    zoom:boolean
}

export class InsuranceImageZoomModal extends React.Component<InsuranceImageZoomProps, InsuranceImageZoomState>{
    public formRef: React.RefObject<ModalForm>;

    constructor(props) {
        super(props);
        this.state = {zoom: false};
        this.formRef = React.createRef()
    }

    public static readonly ID = "InsuranceImageZoom"
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <div className="modal fade form_modal" id={InsuranceImageZoomModal.ID} tabIndex={-1} style={{zIndex: 100008}} role="dialog"
                     aria-label="Zoomed-in Insurance Image"
                     aria-hidden="true"
                     data-backdrop="static" data-keyboard="false"
                >
                    <div className="modal-dialog modal-xl " role="document" >
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid  min-vh-10">
                                    <div className={"row"}>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 pt-2">
                                            <div className="card mb-2 text-center">
                                                <div className="card-header verlag-bold">
                                                    <h4 style={{fontWeight: 'bold'}}>Insurance Card
                                                        <button style={{outline: 'none'}}  type="button" className="close pr-4" data-dismiss="modal"
                                                                aria-label="Close" onClick={() => this.props.handleLoading()}>
                                                            <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                        </button>
                                                    </h4>
                                                </div>
                                                <div className={"card-body"}>
                                                    <div className="form-group row">
                                                        <button className={"col-12 p-0 m-0 border-0"} onClick={() => this.setState({zoom: !this.state.zoom})} >
                                                            <img className={this.state.zoom ? 'scaleOnePointFive' : ''} style={{width: '100%'}} src={this.props.image} alt={`Insurance Card`}/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

