import React from "react";
import {Column, SimpleTableComponentProps} from "./TableBase";

export default class SimpleHeader extends React.Component<SimpleTableComponentProps, any> {
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <thead className="tableFixHead">
            {this.props.children}
            <tr>
                {this.props.columns.map((col: Column, index) => {
                    return (
                        <th role="columnheader" scope="col" style={this.props.evenWidthHeadersSize ? {width: `${this.props.evenWidthHeadersSize}rem`, position: "sticky", top: 0, backgroundColor: '#fff'} : {}}
                            onClick={() => {
                                if (col.headerOnClick)
                                    col.headerOnClick(col);
                                if(this.props.columnClickedCallback)
                                    this.props.columnClickedCallback(col);
                            }} data-toggle={'popover'} title={col.popoverText} data-content={col.popoverText}>
                            {col.label}
                        </th>)
                })}
            </tr>
            </thead>)
    }
}