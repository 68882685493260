import NetworkUtil from "./NetworkUtil"
import {ServerResponse} from "../types/ServerResponse";
import { UserContext } from "../types/UserContext";

export default class UsersAPI {
    public static async getCustomerGroups():Promise<{success:boolean,reason:string,customerGroups:{label,value,facilityGroupID}[]}>{
        return NetworkUtil.makeGet("/api/admin/auth/customerGroups");
    }

    public static async getValidGroups():Promise<{success:boolean,reason:string,groups:{id,description}[]}>{
        return NetworkUtil.makeGet("/api/admin/auth/validgroups");
    }

    public static async createUserAccount(user:{FirstName,LastName,Email,UUID,AuthFacilityGroup,RoleIds,FacilityIDs,PhoneNumber}):Promise<{success:boolean,reason:string,user:{UUID,FirstName,LastName,Email,Status,FacilityGroups,AuthFacilityGroup,CustomerID,UserRoles,DefaultPassword,PhoneNumber,FacilityIDs}}>{
        return NetworkUtil.makePost("/api/admin/auth/user/create", user);
    }

    public static async updateUserProfileInfo(user:{UUID, FirstName, LastName, PhoneNumber}):Promise<{success:boolean, reason:string, user:{UUID,FirstName,LastName,Email,Status,FacilityGroups,AuthFacilityGroup,CustomerID,UserRoles,PhoneNumber,FacilityIDs } }>{
            return NetworkUtil.makePost("/api/admin/auth/user/update", user);
        }

    public static async disableUserAccount(userid:string):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/auth/user/disable",{id:userid});
    }

    public static async adjustUserPermissions(userid:string,permissions:number[]):Promise<{success:boolean,reason:string}>{
        return NetworkUtil.makePost("/api/admin/auth/user/permissions",{id:userid,permissions:permissions});
    }

    public static async enableUserAccount(userid:string):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/auth/user/enableAccount",{id:userid});
    }

    // when user logs in for the first time, they are asked to reset their password
    public static async resetNewUserPassword(userid:string,password:string):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/auth/user/resetNewUserPassword",{id:userid, password:password});
    }

    // public static async getAllUsers():Promise<{users: [], success:boolean, reason:string}>{
    //     return NetworkUtil.makeGet("/api/admin/auth/user/getAllUsers");
    // }

    public static async getUsersForUserMgmt():Promise<{users: [], success:boolean, reason:string}>{
        return NetworkUtil.makeGet("/api/admin/auth/user/getUsersForUserMgmt");
    }


    

    public static async filterUsers(filter:{filter:{FirstName?,LastName?,Email?,Permissions?,FacilityGroup?,Status?}}):Promise<{
        success:boolean,
        num_pages:number,
        users:{DisplayName,CustomerName,Email,AuthFacilityGroup,UUID,FirstName,LastName,Permissions,Status,DefaultPassword,PhoneNumber,FacilityIDs}[]
    }>{
        return NetworkUtil.makePost("/api/admin/auth/group/users", filter);
    }

    // send ResetPasswordEmail
    public static async sendResetPasswordEmail(user, email, branding):Promise<{success:boolean,reason:string}>{
        return NetworkUtil.makePost("/api/system/resetPasswordEmail",{user:user, email:email, branding:branding});
    }

    public static async setUserEnrolledMFA(user,phoneNumber):Promise<{success:boolean,reason:string}>{
        return NetworkUtil.makePost("/api/admin/auth/user/enrollMFA", {user:user, phoneNumber:phoneNumber})
    }
    public static async sendEnrollMFAEmail(user:UserContext,phoneNumber):Promise<{success:boolean,reason:string}>{
        return NetworkUtil.makePost("/api/admin/auth/user/enrollMFAEmail", {user:user,phoneNumber:phoneNumber});
    }
}