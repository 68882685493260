import React from "react";
import {buildRow, parseDate} from "../util/FormatUtil";
import ReactToPrint from "react-to-print";
import Overlay from "./Overlay";
import moment from "moment";
import ConsentingModal from "./modals/ConsentingModal";
import {getToken} from "../util/CaptchaUtil";
import {Sample} from "../types/Sample";
import ReleaseForm from "./Release";
import {load} from "recaptcha-v3";
import {CAPTCHA, sweetalert} from "../App";
import SystemAPI from "../network/SystemAPI";
import SubmissionAPI from "../network/SubmissionAPI";
import Validator from "../validation/Validator";
import {Validators} from "../validation/Validators";
import BasicReport from "./resultReports/BasicReport";
import PeriodontalReport from "./resultReports/PeriodontalReport";
import InBetweenOverlay from "./InBetweenOverlay";

interface ResultsState {
    hasResult: boolean
    showLoading: boolean
    result: any | Sample
    FName?: string
    LName?: string
    DOB?: Date
    RNum?: string
    token?: string
    NoRecord?: string
    release?
    consenting: {ID:number,Name:string,FacilityID:number,AccessCode:string}[]
    gender?
    specimen_source?
    logo
    test?
    results?
    reportTypes?
    buttonColorHexValue:string
    branding?
    productName?
}

export default class ResultsForm extends React.Component<any, ResultsState> {
    private componentRef: any;

    constructor(props) {
        super(props);
        this.state = {result: {}, showLoading: false, hasResult: false, consenting: [], logo:"", buttonColorHexValue: ''}
        this.submit = this.submit.bind(this);
        this.openConsent = this.openConsent.bind(this);
        this.redirectToPortal = this.redirectToPortal.bind(this);
    }

    componentDidMount() {
        document.title = 'Results Form';
        let currentURL = window.location.href;
        this.setState({showLoading: true}, () => {
            document.body.style.backgroundColor = 'black';

            SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
                let backgroundImageURL = data.ProductBackgroundImageURL;
                //@ts-ignore
                document.body.style.backgroundImage = `url(${backgroundImageURL}`;
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";

                this.setState({logo: data.ProductAltLogoURL, productName: data.Name, buttonColorHexValue: data.ProductButtonColorHexValue, showLoading: false});

            })
            SystemAPI.getAllGenders().then(data => {
                this.setState({gender: data})
            })
            SystemAPI.getAllSpecimenSources().then(data => {
                this.setState({specimen_source: data})
            })
            SystemAPI.getConsentEntities().then(data => {
                this.setState({consenting: data})
            })
            SystemAPI.getAllGenders().then(data => {
                this.setState({gender: data})
            })
            SystemAPI.getAllSpecimenSources().then(data => {
                this.setState({specimen_source: data})
            })
            SystemAPI.getBranding().then(data => {
                this.setState({branding: data.branding})
            })
            SystemAPI.getAllResultTypes().then((data) => {
                this.setState({ results: data });
            });
            SystemAPI.getReportTypes().then((data) => {
                this.setState({reportTypes: data})
            })
            

        })

        const query = new URLSearchParams(window.location.search);

        if (query.get('fname') && query.get('fname') !== "") {
            let parsedDate = moment(query.get("dob"), ["MM/DD/YYYY", "MM-DD-YYYY", "YYYY-MM-DD"], false)
            this.setState({
                FName: query.get('fname'),
                LName: query.get("lname"),
                DOB: parsedDate.toDate(),
                RNum: query.get("conf"),
                NoRecord: query.get("norecord")
            }, () => this.submit())
        }
        else{
            this.setState({showLoading: false})
        }
    }

    openConsent() {
        ConsentingModal.display();
    }

    private async submit() {
        let formValidation = {
            FirstName: this.state.FName,
            LastName: this.state.LName,
            ReqNum: this.state.RNum,
            DOB: this.state.DOB
        }

        let validator = new Validator<any>()
            .withSimpleValidation("FirstName", Validators.requireNotBlankValidator("First Name"))
            .withSimpleValidation("LastName", Validators.requireNotBlankValidator("Last Name"))
            .withSimpleValidation("DOB", Validators.requireNonNullValidator("Date of Birth"))
            .withSimpleValidation("ReqNum", Validators.requireNotBlankValidator("Confirmation Number"))
        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }
        const filterObj = {
            FirstName: this.state.FName.trim(),
            LastName: this.state.LName.trim(),
            ReqNum: this.state.RNum.trim(),
            DOB:  parseDate(this.state.DOB)
        }
        
        let token:string = await getToken();
        
        this.setState({showLoading: true}, async () => {
            let result;
            try {
                result = await SubmissionAPI.submitResults(filterObj, token);
            } catch (error) {
                this.setState({showLoading: false})
                return sweetalert.fire({icon: 'error', title: '', text: 'Could not generate results report at this time'}); 
            }


            if(result.success){
                if(result.result.Service.ReportType < 3){
                    this.setState({hasResult: true, result: result.result, showLoading: false})
                }
                else if(result.result.Service.ReportType >= 3){
                    let reason;
                    try {
                        let sixPlexToken = await getToken();
                        const response = await SubmissionAPI.submitResultsForPDF(filterObj, sixPlexToken);
                        reason = response && response.reason ? response.reason : '';
                        let data = await response.arrayBuffer();
                        const blob = new Blob([data], {type: 'application/pdf'});
                        const url = URL.createObjectURL(blob)
                        window.open(url, '_blank')
                        this.setState({showLoading: false})
                    } catch (e) {
                        this.setState({showLoading: false})
                        return sweetalert.fire({icon: 'error', title: '', text: reason})
                    }
                }
                else{
                    this.setState({showLoading: false})
                    return sweetalert.fire({icon: 'error', title: '', text: result.reason});  
                }
            }
            else{
                this.setState({showLoading: false})
                return sweetalert.fire({icon: 'error', title: '', text: 'Could not generate results report at this time'});  
            }
        })
    }


    getForm() {
        return (
            <React.Fragment>
                    <Overlay show_loading={this.state.showLoading}/>
                    <InBetweenOverlay showInBetween={true} zIndex={-10}/>
                    <div className={"container min-vh-100"}>
                        <div className="row justify-content-center mt-5">
                            <div className="col-12 col-md-9 col-xl-6">
                                <div className="card shadow">
                                    <div className="card-title text-center border-bottom">
                                        <a href='/'>
                                            <img src={this.state.logo}
                                                style={{ width: '200px', margin: '20px' }}
                                                className={'nav-logo'} alt={"logo"}
                                            />
                                        </a>
                                        
                                    </div>
                                    <div className="card-body">
                                        {buildRow("First Name",
                                            <input 
                                                className={"form-control"}
                                                autoComplete={"off"}
                                                type={"search"}
                                                name={"FirstName"}
                                                onChange={(e) => {
                                                    this.setState({
                                                        FName: e.target.value
                                                    })
                                                }}
                                                value={this.state.FName}
                                            />
                                        )}
                                        {buildRow("Last Name",
                                            <input 
                                                className={"form-control"}
                                                autoComplete={"off"}
                                                type={"search"}
                                                name={"LastName"}
                                                onChange={(e) => {
                                                    this.setState({
                                                        LName: e.target.value
                                                    })
                                                }}
                                                value={this.state.LName}
                                            />
                                        )}
                                        {buildRow("Date of Birth",
                                            <input 
                                                className={"form-control"}
                                                autoComplete={"off"}
                                                type={"date"}
                                                name={"DOB"}
                                                onChange={(e) => {
                                                    this.setState({
                                                        DOB: new Date(e.target.value)
                                                    })
                                                }}
                                            />
                                        )}
                                        {buildRow("Confirmation Code",
                                            <input 
                                                className={"form-control"}
                                                autoComplete={"off"}
                                                type={"search"}
                                                name={"ConfirmationCode"}
                                                onChange={(e) => {
                                                    this.setState({
                                                        RNum: e.target.value
                                                    })
                                                }}
                                                value={this.state.RNum}
                                            />
                                        )}
                                        <div className="text-center mb-3 text-primary">Please ensure pop-ups are disabled to view report</div>
                                        <div className="d-flex justify-content-center">
                                            <button
                                                onClick={this.submit}
                                                id="results-button"
                                                name="results-button"
                                                className="btn btn-lg text-light width-100" style={{backgroundColor: this.state.buttonColorHexValue}}>
                                                    Get My Results
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        {/* keep */}
                                {/* <div className="d-grid mb-3">
                                    <p className="d-fles justify-center text-center">
                                        Access all of your results in one convenient location with Streamline Patient Portal.
                                    </p>
                                    <div className="d-flex justify-center">
                                        <button
                                            className="btn btn-primary"
                                            onClick={this.redirectToPortal}>
                                            <img src="/images/FullLogoWhite.png"
                                                alt="streamlineLogo"
                                                style={{ "width": "300px", "margin": "10px" }}
                                            />
                                            <br />Register Here
                                        </button>
                                    </div>
                                </div> */}
    
                            {/* </div>
                        </div>
                    </div> */}
                </React.Fragment>
        )
    }

    getReport(result){
        let reportToReturn;
        let reportTypeString = this.state.reportTypes ? this.state.reportTypes.find(f => f.value === result.Service.ReportType).label : '';
        let branding = this.state.branding.find(f => f.BrandingID === result.Service.BrandingID);
        switch (reportTypeString) {
            //basic report
            case 'basic':
                reportToReturn = <BasicReport result={result} branding={branding} />
                break;

            //periodontal report
            case 'periodontal5Tests':
                reportToReturn = <PeriodontalReport result={result} allResults={this.state.results} test={result.test} branding={branding} />
                break; 
            default:
                break;
        }
        return reportToReturn
    }

    getPCRResult(result) {
            return (
                <React.Fragment>
                    <div className="container  min-vh-100 ">
                        <div className={"row d-print-none"}>                            

                            <div className={"col-12 text-center pt-2 verlag-bold responsive-p"}> 
                                <p>
                                    For further questions regarding your result, please consult your doctor or primary care
                                    physician. You can also learn more at the <a target="_blank"
                                                                                href={"https://www.cdc.gov/coronavirus/2019-ncov/testing/diagnostic-testing.html"}>Centers
                                    for Disease Control and Prevention website</a>.
                                </p>
                                <ReactToPrint
                                    trigger={() => <button className={"btn btn-outline-primary mb-5"}
                                    >Print Results</button>}
                                    // @ts-ignore
                                    content={() => this.componentRef}
                                />
                            </div>
                        </div>
                        <div ref={(el => this.componentRef = el)}>
                            {this.getReport(result)}
                        </div>

                    </div>
                </React.Fragment>
            )
    }



    async sendConsent(){
        this.setState({showLoading: true})
        if(!this.state.release  || !this.state.RNum){
            this.setState({showLoading: false})
            return;
        }
        let recaptcha = await load(CAPTCHA);
        let token = await recaptcha.execute('login');

        let result = await SubmissionAPI.release(this.state.release, this.state.RNum, token);
        try{
            if(result.success){
                // sweetalert.fire("Consent saved.")
                this.setState({showLoading: false})
                return
            }  
            if(!result.success)
                sweetalert.fire("Could not save this consent. ")
                this.setState({showLoading: false})
                return
        }catch (e) {
            console.error(e)
            this.setState({showLoading: false})
        }
    }

    async revokeConsent(){
        this.setState({showLoading: true})
        if(!this.state.RNum){
            this.setState({showLoading: false})
            return;
        }
        let recaptcha = await load(CAPTCHA);
        let token = await recaptcha.execute('login');

        let result = await SubmissionAPI.revokeConsent(this.state.RNum, token);
        try{
            if(result.success){ 
                // sweetalert.fire("Consent revoked.")
                this.setState({showLoading: false})
                return
            }
            if(!result.success){
                sweetalert.fire("Could not revoke this consent. ")
                this.setState({showLoading: false})
                return
            }
                
        }catch (e) {
            this.setState({showLoading: false})
            console.log(e)
        }
    
    }

    redirectToPortal = () => {
        sweetalert.fire({
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Continue",
            title: "Attention",
            html: "<p>You will be redirected to Streamline Patient Portal.</>",
            icon: "info"
        }).then((result) => {
            if (result.isConfirmed) {
                //TODO: After testing, this needs to be a production url
                window.open("https://dev.portal.immytech.com/register", "_blank"); // open in new window
            }
        });
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (!this.state.hasResult)
        return this.getForm();
        let result = this.state.result;
        return this.getPCRResult(result);
    }
       

}