import React, {useCallback, useState, useLayoutEffect} from "react";
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import Overlay from "../Overlay";
import {sweetalert} from "../../App";
import { isEmptyObject } from "jquery";
import AdminAPI from "../../network/AdminAPI";
import { buildRow, hideModal } from "../../util/FormatUtil";
import { isBlank, isOnlyWhitespace } from "../../util/ValidationUtil";


type DocumentUploadProps = {
    selectedDocument
    requery
    documents
};

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: rgba(236, 236, 236, 0.93);
  outline: none;
  transition: border .24s ease-in-out;
`;

export const DocumentUploadModal = ({documents, selectedDocument, requery}: DocumentUploadProps) => { 
    const ID = "documentupload";
    const [documentName, setDocumentName] = useState('')
    const [URL, setURL] = useState('')
    const [fileToUpload, setFileToUpload] = useState<Blob | {}>({})
    const [fileDroppedBool, setFileDroppedBool] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    useLayoutEffect(() => {
        $(`#${ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });

        if(!isEmptyObject(selectedDocument) && selectedDocument.ID){
            setDocumentName(selectedDocument.DocumentName)
            setURL(selectedDocument.URL)
        }
        else{
            setDocumentName('')
            setURL('')
        }

    }, [selectedDocument])


    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(async (file) => {
            setFileToUpload(file)
        })
        setFileDroppedBool(true)
    }, [])


    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        fileRejections,
    } = useDropzone({
        onDrop,
        maxFiles: 1
    });

    const fileRejection = fileRejections.map(({file, errors}) => (
        <div key={(file as any).path}>
            {(file as any).path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </div>
    ));
    
    const filepath = acceptedFiles.map(file => (
        <div key={(file as any).path}>
            {(file as any).path} - {file.size} bytes
        </div>
    ));

    function getFileComponent() {
        if (fileDroppedBool) {
            if(filepath.length < 1){
                setFileDroppedBool(false)
                return sweetalert.fire({
                    title: 'Only one file allowed per submission',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
            return (
                <div className="form-group row">
                    <label htmlFor={'Selected File'} className="col-12 col-sm-4 col-form-label text-center text-md-left">Selected File</label>
                    <div className="col-11 col-sm-7 p-0 m-0 text-center text-md-left" id={'Selected File'}>
                            {filepath}
                    </div>
                    <div className="col-1">
                        <button  style={{outline: 'none'}} type="button" className="close" aria-label="Close" onClick={() => {
                            setFileDroppedBool(false)
                            setFileToUpload(null)
                        }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
                </div>
            )
        } else {
            return (
                <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                    <input aria-label="FileDrop" {...getInputProps()} />
                    <p>Drag and drop a file here or click to select file</p>
                </Container>)
        }
    }

    function clear(){
        setFileToUpload({})
        setFileDroppedBool(false)
        // setDocumentName('')
        // setURL('')
    }


    return (
        <>
        <Overlay show_loading={showLoading} zIndex={100003}/>
        <div className="modal fade form_modal" id={ID} tabIndex={-1} role="dialog" aria-labelledby="result_modal_label" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-xl" role="document">
                <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">File Management</h5>
                            <button  style={{outline: 'none'}} type="button" className="close" aria-label="Close" onClick={() =>{
                                clear()
                                
                                hideModal("documentupload")
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {buildRow("File Name",
                                <input className={"form-control"}
                                    id="FileName"
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={"DocumentName"}
                                    aria-label="File Name"
                                    onChange={(e)=>{
                                        setDocumentName(e.target.value)
                                    }}
                                    value={documentName ? documentName : ''}
                                />
                            , 'File Name')}
                            
                            {getFileComponent()}
                        </div>  
                        <div className="modal-footer">
                            <button type="button" className="btn immySubmitButtonOutline" style={{float: 'right'}} onClick={ async() => {
                                if(isBlank(documentName) || isOnlyWhitespace(documentName)){
                                    return sweetalert.fire("","File Name cannot be empty.", "error")
                                }
                                if((!URL || URL.length < 1) && !fileDroppedBool){
                                    return sweetalert.fire("","Please select a file to upload and try again.", "error")
                                }
                                if(documents && documents.length > 0){
                                    for (let i = 0; i < documents.length; i++) {
                                        if(documents[i].DocumentName.toLowerCase() === documentName.toLowerCase()){
                                            if(documents[i].ID !== selectedDocument.ID){
                                                return sweetalert.fire("","File Names cannot be duplicated.", "error")
                                            }
                                        }
                                        if(documents[i].URL && documents[i].ID !== selectedDocument.ID && filepath && filepath.length > 0){
                                            let urlSplit = documents[i].URL.split('/');
                                            let urlFileName = urlSplit[urlSplit.length -1];
                                            if(filepath[0].key === urlFileName){
                                                return sweetalert.fire("", `Selected File <b>${filepath[0].key}</b> already exists.`, "error")
                                            }
                                        }
                                    }
                                }
                                setShowLoading(true)
                                let response = fileDroppedBool && filepath.length > 0 && documentName ? await AdminAPI.documentUpload(selectedDocument.ID, URL, documentName, fileToUpload as Blob) : await AdminAPI.documentUpload(selectedDocument.ID, URL, documentName);
                                setShowLoading(false);
                                if (!response.success) {
                                    setFileDroppedBool(false)
                                    sweetalert.fire({icon: 'error', title: '', text: response.reason});
                                } else {
                                    sweetalert.fire({icon: 'success', title: '', text: 'File saved'});
                                    hideModal("documentupload")
                                    clear()
                                    requery()
                                }
                            }}
                            >
                            Submit
                            </button>
                        </div>
                    </div>           
                </div>
            </div>
        </div>
    </>
    );
}