import React from "react";
import ReactQuill, {Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";
import {getBrandingImage} from "../util/FormatUtil";
import {ImageType} from "../types/Branding";
import AdminAPI from "../network/AdminAPI";

function insertName(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Name}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertResultLink(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{ResultLink}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertProduct(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Product}";
    this.quill.insertText(cursorPosition,name)
    this.quill.setSelection(cursorPosition + name.length)
}

function insertReqNum(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{ReqNum}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertLocation(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Location}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertAddress(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Address}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertDate(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Date}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertTime(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Time}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertDefaultPassword(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{DefaultPassword}";
    this.quill.insertText(cursorPosition,name)
    this.quill.setSelection(cursorPosition + name.length)
}
function insertLoginLink(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{LoginLink}";
    this.quill.insertText(cursorPosition, name)
    this.quill.setSelection(cursorPosition + name.length)
}

function insertLast4Phone(){
    const cursorPosition = this.quill.getSelection().index;
    let phone = "#{PhoneNumber}"
    this.quill.insertText(cursorPosition, phone)
    this.quill.setSelection(cursorPosition + 4)
}

// ensure logo inserts with width: 400px;
let Image = Quill.import('formats/image');
Image.className = "customtoolbar-logo-width";
Quill.register(Image, true);

function insertText(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Text}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}
function insertServiceName(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{ServiceName}";
    this.quill.insertText(cursorPosition,name)
    this.quill.setSelection(cursorPosition + name.length)
}
function insertLinkedApptsID(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{LinkedApptsID}"
    this.quill.insertText(cursorPosition, name)
    this.quill.setSelection(cursorPosition + name.length)
}
function insertResetLink(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{ResetLink}"
    this.quill.insertText(cursorPosition, name)
    this.quill.setSelection(cursorPosition + name.length)
}
function insertEmail(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Email}"
    this.quill.insertText(cursorPosition, name)
    this.quill.setSelection(cursorPosition + name.length)
}
function insertLink(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Link}"
    this.quill.insertText(cursorPosition, name)
    this.quill.setSelection(cursorPosition + name.length)
}
function insertCheckinLink(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{CheckinLink}"
    this.quill.insertText(cursorPosition, name)
    this.quill.setSelection(cursorPosition + name.length)
}
const CustomToolbar = (props) => (
    <div id="toolbar" className={"ql-toolbar ql-snow"}
         style={{border: props.formats ? '1px solid #ccc' : 'none'}}
    >
        <div id={"toolbar-buttons"} style={{
            visibility: props.formats ? 'visible' : 'hidden',
            display: props.formats ? 'block' : 'none'
        }}>
            <span className={"ql-formats"}>
                <select className="ql-header">
                    <option value="1"/>
                    <option value="2"/>
                    <option value="3"/>
                    <option selected/>
                </select>
            </span>
            <span className={"ql-formats"}>
                <button className="ql-bold"/>
                <button className="ql-italic"/>
                <button className="ql-underline"/>
                <button className="ql-link"/>
            </span>
                    <span className={"ql-formats"}>
                <button type="button" className="ql-list" value="ordered"/>
                <button type="button" className="ql-list" value="bullet"/>
            </span>
                    <span className={"ql-formats"}>
                <button className={"ql-clean"}/>
            </span>
        </div>

        <div id={"customtoolbar"}>
            {   props.templateFields &&
                props.templateFields[props.templateName].map(f => {
                    let Field = () => <span>{f}</span>;
                    let className = "ql-insert"+f
                    return (<button className={className}><Field /></button>)
                })
            }
        </div>
    </div>
)

interface EditorProps {
    value:string,
    onChange?: (value:string) => void
    templateName:string
    templateFields?:{}
    formats:boolean
}

interface EditorState {
    editorHtml:string
}

export class CustomQuillEditor extends React.Component<EditorProps, EditorState> {
    constructor (props) {
        super(props);

        this.state = {
            editorHtml: props.value ? props.value : ""
        }
    }

    componentWillReceiveProps(nextProps: Readonly<EditorProps>, nextContext: any) {
        if(nextProps.value){
            this.setState({editorHtml: nextProps.value})
        }
    }

    static emailModules = {
        toolbar: {
            container: "#toolbar",
            handlers: {
                insertName: insertName,
                insertReqNum: insertReqNum,
                insertLocation: insertLocation,
                insertAddress: insertAddress,
                insertDate: insertDate,
                insertTime: insertTime,
                insertText: insertText,
                insertDefaultPassword: insertDefaultPassword,
                insertLoginLink: insertLoginLink,
                insertLinkedApptsID: insertLinkedApptsID,
                insertServiceName: insertServiceName,
                insertResetLink: insertResetLink,
                insertPhoneNumber: insertLast4Phone,
                insertProduct: insertProduct,
                insertResultLink: insertResultLink,
                insertEmail:insertEmail,
                insertLink:insertLink,
                insertCheckinLink:insertCheckinLink
            }
        },
        // keyboard: {
        //     bindings: {
        //       tab: {
        //         key: 9,
        //         handler: function (range, context) {
        //           return true;
        //         },
        //       },
        //     },
        //   }
    };

    static emailFormats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        'alt', 'width', 'height', 'style' // for logo img
    ];

    render (){
        return (
            <div className={"text-editor"}>
                <div>
                    <CustomToolbar
                        templateName={this.props.templateName}
                        templateFields={this.props.templateFields}
                        formats={this.props.formats}
                    />
                    <ReactQuill
                        theme={"snow"}
                        defaultValue={this.state.editorHtml}
                        onChange={(val) => this.props.onChange(val) }
                        modules={CustomQuillEditor.emailModules}
                        formats={CustomQuillEditor.emailFormats}
                    />
                </div>
            </div>
        )
    }
}