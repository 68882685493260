import React from 'react';
import {
  BarcodeFormat, 
  BrowserMultiFormatReader, 
  DecodeHintType, NotFoundException
} from '@zxing/library';

let audioSuccess = new Audio('/sound/Success.wav');

interface BarcodeScannerState{
  loading:boolean
  showButtons:boolean
  isRunning:boolean
  source:string
  devices:[]
  scanResult:string,
  formats?
  codeReader?
  result?
}
export default class BarcodeScannerComponent extends React.Component<any, BarcodeScannerState> {
  constructor(props){
    super(props);

    const hints = new Map();
    const formats = [
        BarcodeFormat.CODE_39,
        BarcodeFormat.CODE_128,
        BarcodeFormat.CODE_93,
        BarcodeFormat.CODABAR,
        BarcodeFormat.ITF,
        BarcodeFormat.RSS_14,
        BarcodeFormat.RSS_EXPANDED,
        BarcodeFormat.UPC_A,
        BarcodeFormat.UPC_E,
        BarcodeFormat.UPC_EAN_EXTENSION,
        BarcodeFormat.EAN_8,
        BarcodeFormat.EAN_13,
        //2D barcodes // TODO build into settings config 1D/2D/Both
        BarcodeFormat.AZTEC,
        BarcodeFormat.DATA_MATRIX,
        BarcodeFormat.MAXICODE,
        BarcodeFormat.QR_CODE
      ]
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    
    this.state = {
      loading: props.loading || false,
      showButtons: props.showButtons === true,
      isRunning: false,
      source: "" || null,
      devices: [],
      scanResult: "",
      codeReader: new BrowserMultiFormatReader(hints),
    }
    // // used to 'crop' the scan area to improve performance and reliability
    // const targetWidth = Math.ceil(900 / 2); //900
    // const targetHeight = Math.ceil(450 / 2); //450
    //
    // this.state.codeReader.createCaptureCanvas = function (element)
    // {
    //   const canvasElement = document.createElement("canvas");
    //
    //   canvasElement.style.width = targetWidth + "px";
    //   canvasElement.style.height = targetHeight + "px";
    //   canvasElement.width = targetWidth;
    //   canvasElement.height = targetHeight;
    //
    //   return canvasElement;
    // };
    //
    // this.state.codeReader.drawImageOnCanvas = function (canvasElementContext, srcElement)
    // {
    //   const videoWidth = Math.ceil(srcElement.videoWidth)
    //   const videoHeight = Math.ceil(srcElement.videoHeight)
    //   const targetLeft = (videoWidth - targetWidth) / 2;
    //   const targetTop = (videoHeight - targetHeight) / 2;
    //
    //   // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
    //   return canvasElementContext.drawImage(srcElement, targetLeft, targetTop, targetWidth, targetHeight, 0, 0, targetWidth, targetHeight)
    // };

  }

  componentDidMount(): void {
    this.captureStart = this.captureStart.bind(this);
    this.captureStop = this.captureStop.bind(this);

    if (this.props.started === true) {
      if (this.state.isRunning === false) {
        this.captureStart(this.props.onResult);
      }
    } else if (this.props.started === false) {
      if (this.state.isRunning === true) {
        this.captureStop();
      }
    } else {
    }
  }

  captureStart(callback = (result, err) => {}, stopOnCapture = true){
    this.setState({isRunning: true});

    this.state.codeReader.decodeFromVideoDevice(
        this.state.source,
        "zxing-video-scan",
        (result, err) => {
          if (result) {
            audioSuccess.play();
            this.setState({scanResult: result.getText()})
            if (stopOnCapture === true) {
              this.captureStop();
            }
            callback(result, err);
          }
        });
  };

  captureStop(){
    this.setState({isRunning: false}, ()=> {
      this.state.codeReader.reset();
      this.props.onResult()
    });
    // this.
  }

  render(){

    return (

        <div className={"form-group row"}>
          {this.props.showButtons && (
              <div className={"col-sm-4 scan-btns"}>
                <button
                    className={"btn btn-primary m-1"}
                    style={{width: "5em"}}
                    onClick={() => this.captureStart(this.props.onResult)}
                >
                  {/*<BsPlay />*/}
                  Scan
                </button>
                <button className={"btn btn-danger m-1"}
                        style={{width: "5em"}}
                        onClick={()=> this.captureStop()}>
                  {/*<BsXOctagon />*/}
                  Stop
                </button>
                <ul id={"barcode-tips"}>
                  <li>Move barcode slowly around in camera frame if you are having trouble capturing a scan</li>
                  <li>Barcode must be horizontal to scan</li>
                </ul>
              </div>
          )}
          <div className={"col-sm-8"} id={"zxing-scanner-box"}>
            <div id={"zxing-video-wrapper"}>
              <video id={"zxing-video-scan"} autoPlay playsInline muted/>
              <div id={"zxing-frame"}>
                <img id={"zxing-image-debug"} src={""} alt={""} />
                <canvas id={"zxing-canvas"}/>
                <img id={"zxing-image"} src={""} alt={""} />
              </div>
            </div>
          </div>
          {/*<Select value={source} onChange={(e) => setSource(e.label) }*/}
          {/*        noOptionsMessage={()=> cameraOptions.length && cameraOptions.length > 0 ? "" : "Failed to load camera options." }*/}
          {/*        placeholder={"Select Camera..."} options={cameraOptions}*/}
          {/*/>*/}
        </div>
    )
  }
}

// NOTE: Shown below is the same functionality as above only written as a functional component instead of a Class
//
// import React from "react";
// import Select from "react-select";
// import Option from "react-select";
//
// import {
//   BrowserMultiFormatReader,
//   BarcodeFormat,
//   DecodeHintType,
//   NotFoundException
// } from "@zxing/library";
// import {BsXOctagon, BsPlay } from "react-icons/all";
//
// export default function BarcodeScannerComponent(props) {
//   // Note: useState is same as declaring new state variables
//   const [loading, setLoading] = React.useState(props.loading || false);
//   const [showButtons, setShowButtons] = React.useState(
//     props.showButtons === true ? true : false
//   );
//   const [isRunning, setIsRunning] = React.useState(false);
//   const [source, setSource] = React.useState("" || null);
//   const [devices, setDevices] = React.useState([]);
//   const [scanResult, setScanResult] = React.useState("");
//   const [codeReader, setCodeReader] = React.useState(
//     new BrowserMultiFormatReader()
//   );
//
//   const [formats, setFormats] = React.useState([
//     BarcodeFormat.QR_CODE,
//     BarcodeFormat.DATA_MATRIX,
//     BarcodeFormat.CODE_39,
//     BarcodeFormat.CODE_128,
//   ]);
//   const [hints, setHints] = React.useState(new Map());
//
//   // Note: think of useEffect as componentDidMount, componentDidUpdate and componentWillUnmount combined
//   React.useEffect(() => {
//     hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
//
//     // codeReader.listVideoInputDevices().then(videoInputDevices => {
//     //   setSource(videoInputDevices[0].deviceId);
//     //   if (videoInputDevices.length >= 1) {
//     //     videoInputDevices.forEach(element => {
//     //       setDevices([...devices, element]);
//     //     });
//     //   }
//     // });
//   }, []);
//
//   React.useEffect(() => {
//     setLoading(props.loading);
//     setShowButtons(props.showButtons);
//   }, [props.loading, props.showButtons]);
//
//   React.useEffect(() => {
//     if (props.started === true) {
//       if (isRunning === false) {
//         captureStart(props.onResult);
//       }
//     } else if (props.started === false) {
//       if (isRunning === true) {
//         captureStop();
//       }
//     } else {
//     }
//   }, [props.started]);
//
//   const captureStart = (callback = (result, err) => {}, stopOnCapture = true) => {
//     setIsRunning(true);
//     codeReader.decodeFromVideoDevice(source, "video", (result, err) => {
//       if (result) {
//         setScanResult(result.getText());
//         if (stopOnCapture === true) {
//           captureStop();
//         }
//         callback(result, err);
//       }
//       if (err && !(err instanceof NotFoundException)) {
//         console.log("error", err);
//       }
//     });
//   };
//
//   const captureStop = () => {
//     setIsRunning(false);
//     codeReader.reset();
//   };
//
//   // const cameraOptions = devices.map((device, index)=>{ return {label: device.label, value: device.deviceId, key: index} });
//   // console.log(' cameraOptions? ', cameraOptions);
//
//   return (
//     <div id={"scannerComponent"} className={"form-group row"}>
//       {showButtons && (
//         <div className={"col-sm-4 btn-group-vertical"} style={{paddingLeft: "15px"}}>
//           <button
//               className={"btn btn-primary"}
//               style={{width: "10em"}}
//               onClick={() => captureStart(props.onResult)}
//           >
//             <BsPlay /> Start Scan
//           </button>{" "}
//           <button className={"btn btn-primary"}
//                   style={{width: "10em"}}
//                   onClick={captureStop}>
//             <BsXOctagon /> Stop Scan
//           </button>
//         </div>
//       )}
//
//       <div className={"col-sm-8"}>
//         <video
//           id="video"
//           width="150px"
//           height="200px"
//           // style={{ border: "1px solid gray" }}
//         ></video>
//       </div>
//       {/*<Select value={source} onChange={(e) => setSource(e.label) }*/}
//       {/*        noOptionsMessage={()=> cameraOptions.length && cameraOptions.length > 0 ? "" : "Failed to load camera options." }*/}
//       {/*        placeholder={"Select Camera..."} options={cameraOptions}*/}
//       {/*/>*/}
//     </div>
//   );
// }