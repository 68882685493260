import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";

export default class WalkinAPI {

    public static async walkinForm(patientInformation, insuranceInformation, servicesObjectsArray, insuranceCards, currentURL, paymentInfo, submissionFiles):Promise<ServerResponse>{
        let data = new FormData()
        data.set('insuranceInformation', JSON.stringify(insuranceInformation))
        data.set('patientInformation', JSON.stringify(patientInformation))
        data.set('servicesObjectsArray', JSON.stringify(servicesObjectsArray))
        // data.set('roiObjectsArray', JSON.stringify(roiObjectsArray))
        data.set('currentURL', JSON.stringify(currentURL))
        data.set('paymentInfo', JSON.stringify(paymentInfo))
        if(insuranceCards){
            for(let i = 0; i < insuranceCards.length; i++){
                let ext = insuranceCards[i].name.split('.').pop();
                data.append('insuranceCards', insuranceCards[i], `${i === 0 ? 'front' : 'back'}.${ext}`)
            }
        }
        if(submissionFiles){
            for(let i = 0; i < submissionFiles.length; i++){
                data.append('submissionFiles', submissionFiles[i])
            }
        }

        return NetworkUtil.postForm("/api/admin/form", data);
    }

    public static async getEnabledComms(facilityID, serviceID):Promise<{success:boolean, reason?:string, emailIsEnabled?:boolean, smsIsEnabled?:boolean}>{
        return NetworkUtil.makePost("/api/admin/getEnabledComms", {facilityID: facilityID, serviceID: serviceID})
    }

}