import {BinType} from "../types/BinType";
import NetworkUtil from "./NetworkUtil";

export class BinAPI{

    public static async getAllBins():Promise<BinType[]>{
        return await NetworkUtil.makePost("/api/admin/lab/bins", {});
    }

    public static async getBinsForSamples():Promise<{label:string, value:object}[]>{
        return NetworkUtil.makeGet('/api/admin/getBinIDs')
    }

    public static async insertBinRec(body):Promise<{success:boolean, response:{DroppedBy:string, FacilityID:number, NumberSamples:number, ReceivedBy:string}}>{
        return await NetworkUtil.makePost("/api/admin/insertBinRec", body);
    }

    public static async searchReceivingLabels(page, body):Promise<{success: boolean, num_pages:number, table_data: {BinID:string, DroppedBy:string, DroppedDate:Date, NumberSamples:number, ReceivedBy:string, FacilityName:string}[], labels: {BinID:string, DroppedBy:string, DroppedDate:Date, NumberSamples:number, ReceivedBy:string, FacilityName:string}[]}>{
        return await NetworkUtil.makePost("/api/admin/searchReceivingLabels?page=" + page, body);
    }
}