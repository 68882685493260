import React from "react";
import {sweetalert} from "../../App";
import { handleSpecimenIDsOrLots, handleTestsForWalkinAndCheckin, hideModal } from "../../util/FormatUtil";
import Overlay from "../Overlay";
import { CustomFieldBuilder } from "../../customfields/CustomFieldBuilder"
import { isEmptyObject } from "jquery";
import Select from "react-select";
import { AiOutlineConsoleSql, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { FaCameraRetro } from "react-icons/fa";
import BarcodeScannerComponent from "../BarcodeScannerComponent";
import FilterCard, { FilterPart } from "../FilterCard";
import GetField from "../../customfields/GetField";
import TestType from "../../types/TestType";



interface ServiceFormModalProps{
    submit:(e) => void,
    attachClearStateFunc?:(func) => void
    customQuestions?
    service?
    associatedTests?
    sources?
    reviewInfo?
    showSampleInfo?
    tests?
    prices?
    associatedPrices?
}

interface ServiceFormModalState{
    showLoading:boolean
    answeredQuestions?:any
    sampleInfo?:{
        lot:string
        specimenID:string
        serviceID
        isLabService:boolean
        memo:string
        selectedTests:TestType[]
        specimenSourceID:number,
    }
    walkinSampleInfoArrowDown:boolean
    validSources
    showScanner
    serviceSpecificTests
    serviceArrowDown:boolean
    answeredQuestionsForGetField?
    validPrices
    showPricingInfo?:boolean
    walkinPricingInfoArrowDown:boolean
}

export class ServiceFormModal extends React.Component<ServiceFormModalProps, ServiceFormModalState>{
    public static ID = "serviceform";
    constructor(props) {
        super(props);
        this.state ={
            showLoading: false,
            walkinSampleInfoArrowDown:false,
            validSources: [],
            showScanner: false,
            serviceSpecificTests: [],
            serviceArrowDown:false,
            sampleInfo: {lot: '', specimenID: '', serviceID:null, isLabService: false, memo: '', selectedTests: [], specimenSourceID: null}, 
            validPrices: [],
            showPricingInfo: false,
            walkinPricingInfoArrowDown: false,
        }
        this.handleScan = this.handleScan.bind(this);
        this.getServiceSpecificTests = this.getServiceSpecificPrices.bind(this)
    }

    componentDidMount(): void {
        $(`#${ServiceFormModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    componentWillReceiveProps(nextProps: Readonly<ServiceFormModalProps>, nextContext: any): void {

        // console.log('ServiceFormModal nextProps', nextProps)

        if(nextProps.reviewInfo){
            if(!isEmptyObject(nextProps.reviewInfo)){
                let selectedTestIDs = nextProps.reviewInfo.sampleInfo ? nextProps.reviewInfo.sampleInfo.selectedTests.map(t => {return t.ID} ) : null

                let validSources = this.getAssociatedSources(selectedTestIDs)
                const uniqueLabelsMap = new Map();
                validSources.forEach(obj => {
                    if(!uniqueLabelsMap.has(obj.label)){
                        uniqueLabelsMap.set(obj.label, obj);
                    }
                })

                const nonRepeatingSpecimenSources = [...uniqueLabelsMap.values()]

                let customQuestionAnswers = {CustomFieldData: nextProps.reviewInfo.submittedAnswers}

                this.setState({
                    validSources: nonRepeatingSpecimenSources,
                    answeredQuestions: nextProps.reviewInfo.submittedAnswers,
                    sampleInfo: nextProps.reviewInfo.sampleInfo,
                    answeredQuestionsForGetField: customQuestionAnswers,
                    validPrices: nextProps.associatedPrices,
                    showPricingInfo: nextProps.service && nextProps.service.PricingIDs && nextProps.service.PricingIDs.length > 0 ? true : false
                })
            } 
        }
        else{
            if(nextProps.service && this.props.tests){
                    let selectedTests = []
                    for (let i = 0; i < this.props.tests.length; i++) {
                        const testElement = this.props.tests[i];
                        for (let j = 0; j < nextProps.service.Tests.length; j++) {
                            const testID = nextProps.service.Tests[j];
                            if(testElement.ID === testID){
                                selectedTests.push(testElement)
                            }   
                        }
                    }

                    let shortestObj = selectedTests.reduce((min_obj, obj) => min_obj.ValidSpecimenSources.length < obj.ValidSpecimenSources.length ? min_obj : obj)

                    let validSources = [];
                    shortestObj.ValidSpecimenSources.map(vss => {
                        validSources.push({label: vss.Name, value: vss.ID})
                    })

                    this.setState({
                        validSources: validSources,
                        answeredQuestions: {},
                        answeredQuestionsForGetField: {},
                        sampleInfo: {
                            serviceID: nextProps.service ? nextProps.service.ID : null,
                            isLabService: nextProps.service ? nextProps.service.IsLabService : null,
                            lot: '',
                            memo: '',
                            specimenID: '',
                            selectedTests: selectedTests,
                            specimenSourceID: null,
                        },
                        validPrices: nextProps.associatedPrices,
                        showPricingInfo: nextProps.service && nextProps.service.PricingIDs && nextProps.service.PricingIDs.length > 0 ? true : false
                    })
            }
            else{
                this.setState({
                    answeredQuestions: {},
                    answeredQuestionsForGetField: {},
                    sampleInfo: {
                        serviceID: nextProps.service ? nextProps.service.ID : null,
                        isLabService: nextProps.service ? nextProps.service.IsLabService : null,
                        lot: '',
                        memo: '',
                        specimenID: '',
                        selectedTests: [],
                        specimenSourceID: null,
                    },
                    validPrices: nextProps.associatedPrices,
                    showPricingInfo: nextProps.service && nextProps.service.PricingIDs && nextProps.service.PricingIDs.length > 0 ? true : false
                })
            }
        }
    }

    handleSave(){
        if(!this.state.sampleInfo || !this.state.sampleInfo.specimenID || this.state.sampleInfo.specimenID.length < 8){
            return sweetalert.fire({icon: 'error', title: '', text: 'Specimen ID must be at least 8 characters.'})
        }
        
        this.setState({showLoading: true})  
        
        let newAnswerQuestionsObj = {}

        if(!isEmptyObject(this.state.answeredQuestionsForGetField)){
            let answeredQuestionsArrays = Object.entries(this.state.answeredQuestionsForGetField.CustomFieldData)
            answeredQuestionsArrays.map(a => {
                return newAnswerQuestionsObj[a[0]] = a[1]
            })
        }

        let allCustomQuestions = this.props.customQuestions ? JSON.parse(JSON.stringify(this.props.customQuestions)) : [];

        let tempAnsweredQuestions = {}
        if(allCustomQuestions?.length > 0){
            //remove questions that dont belong to this service
            allCustomQuestions.map((cq, i) => {
                if(!isEmptyObject(newAnswerQuestionsObj)){
                    for(const [key, value] of Object.entries(newAnswerQuestionsObj)){
                        if(cq.DisplayName === key){
                            tempAnsweredQuestions[key] = value
                            break
                        }
                    }
                }
            })

            //validate questions
            if(isEmptyObject(tempAnsweredQuestions)){
                this.setState({showLoading: false})
                return sweetalert.fire({icon: 'error', title: '', text: `Service Specific Questions must be answered`})
            }

            let invalidCF = CustomFieldBuilder.validateCustomFields(tempAnsweredQuestions, allCustomQuestions)

            if(invalidCF){
                this.setState({showLoading: false})
                return
            }
        }

        this.props.submit({answeredCustomQuestions: tempAnsweredQuestions, sampleInfo: this.state.sampleInfo})

        hideModal(ServiceFormModal.ID)
        this.setState({showLoading: false})
    }

    getServiceSpecificTests(){
        let serviceSpecificTests = []
        if(this.props.associatedTests && this.props.associatedTests.length > 0 && !isEmptyObject(this.props.service)){
            this.props.associatedTests.map(a => {
                if(this.props.service.Tests && this.props.service.Tests.length > 0 && this.props.service.Tests.includes(a.ID)){
                    serviceSpecificTests.push({label: a.Name, value: a.ID})
                }
                return serviceSpecificTests; 
                
            })
        }
        return serviceSpecificTests
    }

    getServiceSpecificPrices(){
        let serviceSpecificPrices = [];
        if( this.props.associatedPrices && 
            this.props.associatedPrices.length > 0 && 
            !isEmptyObject(this.props.service)
        ){
            if (this.props.service.PricingIDs && this.props.service.PricingIDs.length > 0) {
                return this.props.associatedPrices.map((p) => {
                    if (this.props.service.PricingIDs.includes(p.ID)) {
                        return (
                            <div className="row mr-0">
                                <div className="col-sm-7">{p.Name}</div>
                                <div className={"col-sm-5 text-right"}>
                                    ${p.Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </div>
                            </div>
                        )
                    }
                }); // end filter
            }
        }
        return serviceSpecificPrices;
    }

    handleTestChange(event){
        let selectedTestIDs = []
        let tempSelectedTestsArray = []
        if(event){
            if(Array.isArray(event)){
                event.map(e => {
                    selectedTestIDs.push(e.value)
                    tempSelectedTestsArray.push(this.props.associatedTests.find(q => q.ID === e.value))
                })
            }
            else{
                selectedTestIDs.push(event.value)
                tempSelectedTestsArray.push(this.props.associatedTests.find(q => q.ID === event.value))
            }

            let shortestObj
            if(tempSelectedTestsArray.length > 0){
                shortestObj = tempSelectedTestsArray.reduce((min_obj, obj) => min_obj.ValidSpecimenSources.length < obj.ValidSpecimenSources.length ? min_obj : obj)
            }


            let validSources = [];
            shortestObj.ValidSpecimenSources.map(vss => {
                validSources.push({label: vss.Name, value: vss.ID})
            })

            this.setState((prevState) => ({
                validSources: validSources,
                sampleInfo: {
                    ...prevState.sampleInfo,
                    selectedTests: tempSelectedTestsArray,
                    specimenSourceID: null,
                    isLabService: this.props.service.IsLabService,
                    serviceID: this.props.service.ID,
                }
            }))
            
        }
        else{
            this.setState((prevState)=>({
                validSources: null,
                sampleInfo: {
                ...prevState.sampleInfo,
                selectedTests: [],
                specimenSourceID: null,
                }
            }))
        }
    }

    getAssociatedSources(testIDs){
        let temp = []
        if(testIDs && testIDs.length > 0){
            testIDs.map(t => {
                temp = temp.concat(this.props.sources.filter(s => s.value.TestID === t))
            })
        }
        let mappedTemp = temp.map(t => {
            return {
                label: t.label,
                value: t.value.SpecimenSourceID
            }
        })
        return mappedTemp;
    }

    showBarcodeScanner = (e) => {
        this.setState({showScanner: true})
    }

    handleScan(scan, selectedTest) {
        if (!scan) {
            this.setState({showScanner: false});
            return;
        }
        sweetalert.fire({
            title: "Scan Captured",
            html: "Please verify <b> " + scan.text + " </b>  matches the barcode.",
            icon: "info",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                    this.setState((prevState)=>({
                        showScanner: false,
                        sampleInfo: {
                        ...prevState.sampleInfo,
                        specimenID: scan.text
                        }
                    })
                )
            }
            if (result.isDismissed) {
                //keep scanner div open so user can hit Scan
                this.setState({showScanner: true})
            }
        });
    }

    getSpecimenSourceLabel(id){
        let specimenSourceLabel;
        this.state.validSources.map(vs => {
            if(vs.value === id){
                specimenSourceLabel = {label: vs.label, value: id}
            }
        })
        return specimenSourceLabel
    }

    render() {

        // console.log('state in ServiceForm', this.state)
        // console.log('props in ServiceForm', this.props)
        let specIDString = this.props.service && this.props.service.IsLabService ? "Specimen ID" : "Specimen ID (optional)"

            return  (
                <React.Fragment>
                    <Overlay show_loading={this.state.showLoading} zIndex={100005}/>
                    <div className="modal fade form_modal" id={ServiceFormModal.ID} tabIndex={-1} role="dialog"
                        aria-label="Service Related Questions"
                        aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-body p-0 m-0">
                                    <div className="container-fluid">
                                        <div className={"row"}>
                                            <div className="col-12 pt-2">
                                                <div className="card mb-2">
                                                    <div className="card-header verlag-bold">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <h4>
                                                                    {this.props.service && this.props.service.Name}
                                                                </h4>
                                                            </div>
                                                            <div className="col-2">
                                                                <button style={{outline: 'none'}} type="button" className="close pr-4"
                                                                    aria-label="Close" onClick={() => hideModal(ServiceFormModal.ID)}>
                                                                    <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        {this.props.customQuestions?.length > 0 ?
                                                            <div className="card mb-2">
                                                                <button className="card-header verlag-bold accordionButton w-100"  data-toggle="collapse" data-target="#serviceCollapse" onClick={() => this.setState({serviceArrowDown: !this.state.serviceArrowDown})} aria-expanded="false" aria-controls="serviceCollapse">
                                                                    <div className="row d-flex justify-content-between">
                                                                        <div className="">
                                                                            <h4>Questionnaire</h4>
                                                                        </div>
                                                                        <div className="col-2">
                                                                        {this.state.serviceArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#serviceCollapse"  role="button" aria-expanded="false" aria-controls="serviceCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#serviceCollapse" role="button" aria-expanded="false" aria-controls="serviceCollapse" />)}
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <div className="card-body collapse show" id={'serviceCollapse'}>
                                                                    <div className="row">
                                                                        {this.props.customQuestions.map((val, index) => {
                                                                            if(val.Options.isActive){
                                                                                return (
                                                                                    <div className="col-12">
                                                                                        <div className="form-group row">
                                                                                            <GetField 
                                                                                            val={val} 
                                                                                            selectedItem={this.state.answeredQuestionsForGetField ? this.state.answeredQuestionsForGetField : null} 
                                                                                            handleChange={(e) => {
                                                                                                this.setState({answeredQuestionsForGetField: e})
                                                                                            }}  
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='d-none'></div>

                                                        }
                                                        {this.props.showSampleInfo &&
                                                        <div className="card mb-2">
                                                            <button className="card-header verlag-bold accordionButton w-100"  data-toggle="collapse" data-target="#walkinSampleInfoCollapse" onClick={() => this.setState({walkinSampleInfoArrowDown: !this.state.walkinSampleInfoArrowDown})} aria-expanded="false" aria-controls="walkinSampleInfoCollapse">
                                                                <div className="row d-flex justify-content-between">
                                                                    <div className="">
                                                                        <h4>Sample Information<span className={"ml-3 text-danger"} style={{fontSize: '1rem'}}>*to be completed by provider</span></h4>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        {this.state.walkinSampleInfoArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#walkinSampleInfoCollapse"  role="button" aria-expanded="false" aria-controls="walkinSampleInfoCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#walkinSampleInfoCollapse" role="button" aria-expanded="false" aria-controls="walkinSampleInfoCollapse" />)}
                                                                    </div>
                                                                </div>
                                                            </button>
                                                            <div className="card-body collapse show"  id={'walkinSampleInfoCollapse'}>
                                                                <div className="form-group row" >
                                                                    <label id="testTypeLabel" htmlFor="testType"  className="col-sm-4 col-form-label">Test Type</label>
                                                                    <div className="col-sm-8  p-0 m-0" tabIndex={0} aria-labelledby="TestType testTypeLabel">
                                                                        <Select
                                                                            id="TestType"
                                                                            name="testType"
                                                                            aria-label={"Test Type"}
                                                                            isMulti={this.props.service && this.props.service.AllowMultipleTests}
                                                                            isDisabled={true}
                                                                            isSearchable={true}
                                                                            placeholder={<div className="accessibilityText">Please Select...</div>}
                                                                            noOptionsMessage={()=>  "No option"}
                                                                            className={"state_select"}
                                                                            options={this.getServiceSpecificTests()}
                                                                            onChange={(e) => this.handleTestChange(e)}
                                                                            value={this.state.sampleInfo && this.state.sampleInfo.selectedTests?.map(s => ({label:s.Name, value:s.ID}))}
                                                                        />
                                                                    </div>
                                                                </div>
                                                               
                                                                <div className="form-group row">
                                                                    <label htmlFor={"SpecimenID"} className={"col-sm-4 col-form-label"}>{specIDString}</label>
                                                                    <div className={"col-sm-8 p-0 m-0 input-group"}>
                                                                        <button className={"btn btn-primary btn-sm"}
                                                                                onClick={this.showBarcodeScanner}
                                                                                aria-label="Scan Specimen ID"
                                                                                disabled={!this.state.sampleInfo?.selectedTests || this.state.sampleInfo?.selectedTests?.length < 1}
                                                                        ><FaCameraRetro/></button>
                                                                        <input type={"search"} className="form-control" id={"SpecimenID"}
                                                                            value={this.state.sampleInfo?.specimenID}
                                                                            autoComplete="off"
                                                                            onChange={(e) => {
                                                                                this.setState((prevState) => ({
                                                                                    sampleInfo: {
                                                                                        ...prevState.sampleInfo,
                                                                                        specimenID: e.target.value,
                                                                                    }
                                                                                }))
                                                                            }}
                                                                            disabled={!this.state.sampleInfo?.selectedTests || this.state.sampleInfo?.selectedTests?.length < 1}
                                                                            maxLength={50}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                { this.state.showScanner ?
                                                                    <BarcodeScannerComponent
                                                                        onResult={this.handleScan}
                                                                        showButtons={true}
                                                                        started={false}
                                                                    />
                                                                    : null
                                                                }
                                                                <div className="form-group row">
                                                                    <label htmlFor={"Lot"} className={"col-sm-4 col-form-label"}>Lot (optional)</label>
                                                                    <div className={"col-sm-8 p-0 m-0 "}>
                                                                        <input type={"search"} className="form-control" id={"Lot"}
                                                                            value={this.state.sampleInfo?.lot}
                                                                            autoComplete="off"
                                                                            onChange={(e) => {
                                                                                this.setState((prevState) => ({
                                                                                    sampleInfo: {
                                                                                        ...prevState.sampleInfo,
                                                                                        lot: e.target.value,
                                                                                    }
                                                                                }))
                                                                            }}
                                                                            maxLength={45}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label htmlFor={'Specimen Source'} className="col-sm-4 col-form-label">Specimen Source</label>
                                                                    <div className="col-sm-8  p-0 m-0" id={'Specimen Source'}>
                                                                        <Select
                                                                            isSearchable={ true}
                                                                            aria-label={"Specimen Source"}
                                                                            placeholder={<div className="accessibilityText">Please Select...</div>}
                                                                            noOptionsMessage={()=>  "No option"}
                                                                            className={"state_select"}
                                                                            isDisabled={!this.state.sampleInfo?.selectedTests || this.state.sampleInfo?.selectedTests?.length < 1}
                                                                            options={this.state.validSources}
                                                                            onChange={(e) => {
                                                                                this.setState((prevState)=>({
                                                                                    sampleInfo: {
                                                                                    ...prevState.sampleInfo,
                                                                                    specimenSourceID: e.value
                                                                                    }
                                                                                })
                                                                            )}
                                                                            }
                                                                            
                                                                            value={this.state.sampleInfo && this.state.sampleInfo.specimenSourceID ? this.getSpecimenSourceLabel(this.state.sampleInfo.specimenSourceID): null}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label htmlFor={"Memo"} className={"col-sm-4 col-form-label pb-0"}>
                                                                        <p className="mb-0">Memo (optional)</p>
                                                                    </label>
                                                                    <div className={"col-sm-8 p-0 m-0 "}>
                                                                        <textarea
                                                                            id={"Memo"}
                                                                            rows={3}
                                                                            maxLength={1000}
                                                                            className={'form-control'}
                                                                            value={this.state.sampleInfo?.memo}
                                                                            autoComplete="off"
                                                                            onChange={(e) => {
                                                                                this.setState((prevState)=>({
                                                                                    sampleInfo: {
                                                                                    ...prevState.sampleInfo,
                                                                                    memo: e.target.value
                                                                                    }
                                                                                })
                                                                            )}
                                                                            }

                                                                        />
                                                                        <div className={this.state.sampleInfo?.memo && this.state.sampleInfo?.memo.length > 0 ? 'visible float-right' : 'invisible'}>
                                                                            <div style={{fontSize: '0.8em'}}>{this.state.sampleInfo?.memo && this.state.sampleInfo.memo?.length}/1000</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        {this.state.showPricingInfo && 
                                                            <div className="card mb-2">
                                                                <div className="card-header verlag-bold" data-toggle="collapse"
                                                                    data-target="#walkinPricingInfoCollapse"
                                                                    onClick={() => this.setState({ walkinPricingInfoArrowDown: !this.state.walkinPricingInfoArrowDown })}
                                                                    role="button"
                                                                    aria-expanded="false"
                                                                    aria-controls="walkinPricingInfoCollapse"
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h4>Pricing Information</h4>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            {this.state.walkinPricingInfoArrowDown ?
                                                                                (<AiOutlineUp className={'float-right'}
                                                                                    size={30} data-toggle="collapse"
                                                                                    data-target="#walkinPricingInfoCollapse"
                                                                                    role="button" aria-expanded="false"
                                                                                    aria-controls="walkinPricingInfoCollapse" />) :
                                                                                (<AiOutlineDown className={'float-right'}
                                                                                    size={30} data-toggle="collapse"
                                                                                    data-target="#walkinPricingInfoCollapse"
                                                                                    role="button" aria-expanded="false"
                                                                                    aria-controls="walkinPricingInfoCollapse" />)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body collapse show" id={'walkinPricingInfoCollapse'}>
                                                                    {this.getServiceSpecificPrices()}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                            <button className={"btn btn-primary float-right mb-3"} onClick={() => this.handleSave()}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
 
    }
}

