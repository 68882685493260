import NetworkUtil from "./NetworkUtil"
import Discount from "../types/Discount"
import Price from "../types/Price";

export default class DiscountAPI {
    public static async getAllDiscountsForSelect():Promise<{success:boolean,reason?:string,data:{label:string,value:number}[]}>{
        return NetworkUtil.makeGet("/api/admin/discount/allForSelect");
    }
    public static async getAllDiscounts():Promise<{success:boolean,reason?:string,data:Discount[]}>{
        return NetworkUtil.makeGet("/api/admin/discount/all");
    }
    public static async newDiscount(body):Promise<{success:boolean,reason?:string,discount:Discount}>{
        return NetworkUtil.makePost("/api/admin/discount/new",body);
    }
    public static async editDiscount(body):Promise<{success:boolean,reason?:string,discount:Discount}>{
        return NetworkUtil.makePost("/api/admin/discount/edit",body);
    }
    public static async queryDiscounts(body):Promise<{success:boolean,reason?:string,discount:Discount}>{
        return NetworkUtil.makePost("/api/admin/discount/details",body);
    }
    public static async filterDiscounts(filter:{filter:{SelectedDiscount}}):Promise<{success:boolean,reason?:string,data:Discount[]}>{
        return NetworkUtil.makePost("/api/admin/discount/filter",filter);
    }
    public static async validateDiscountCode(body):Promise<{success:boolean,reason:string,data:Discount}>{
        return NetworkUtil.makePost("/api/admin/discount/validateDiscount", body);
    }
}