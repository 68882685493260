import React from "react";

export class AppointmentSelectionModal extends React.Component<{}, {}> {
    constructor(props) {
        super(props);
        this.state = {};

    }

    public static display() {
        window["$"]("#" + AppointmentSelectionModal.ID).modal('show');
    }
    public static hide() {
        window["$"]("#" + AppointmentSelectionModal.ID).modal('hide');
    }
    public static readonly ID = "apptselectio n"

    render() {
        return (<React.Fragment>
            <div className="modal fade form_modal" id={AppointmentSelectionModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        {/*<Modal_Header title={title}/>*/}
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">IMMYLab's Services</h5>
                            <button style={{outline: 'none'}}  type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            <div className={"container"}>
                                <div className={"row"}>
                                <div className={"col-12 col-md-6 text-center"}>
                                    <h1>Vaccination Appointment</h1>
                                    <p>If you qualify for the Covid-19 Vaccination, please click below.</p>
                                    <a href={"/vaccine"}><button className={"btn btn-primary"}>Make Vaccination Appointment</button></a>
                                </div>

                                <div className={"col-12 col-md-6 text-center"}>
                                    <h1>Testing Appointment</h1>
                                <p>For the Covid-19 Swab Test, please click below.</p>
                                    <a href={"/appointment"}><button className={"btn btn-success"}>Make Testing Appointment</button></a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>)
    }
}