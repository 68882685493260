import * as React from 'react'
import Overlay from '../Overlay';
import {buildRow} from '../../util/FormatUtil';
import SettingsAPI from '../../network/SettingsAPI';
import LabInformation from '../../types/LabInfo';
import { sweetalert } from '../../App';
import { isBlank, isOnlyWhitespace, isValidEmail} from '../../util/ValidationUtil';

interface ReportSettingsState {
    showLoading:boolean
    labinfo:LabInformation
    ReportInfoArrowDown?
}
interface ReportSettingsProps {}
export class ReportSettings extends React.Component<ReportSettingsProps, ReportSettingsState> {

    constructor(props){
        super(props);

        this.state = {
            ReportInfoArrowDown: true,
            showLoading: false,
            labinfo: {} as LabInformation
        }
        this.validateLabInfo = this.validateLabInfo.bind(this);
        this.updateDefaultLabInfo = this.updateDefaultLabInfo.bind(this);
        this.loadDefaultLabInfo = this.loadDefaultLabInfo.bind(this);
    }

    componentDidMount(){
        document.title = 'Report Information Page';
        this.setState({ showLoading: true }, async () => {
            //Load our Default LabInfo
            this.loadDefaultLabInfo();
        });
    }

    loadDefaultLabInfo(){
        SettingsAPI.getDefaultLabInfo()
            .then((result: { success: boolean, reason: string, data?: LabInformation }) => {
                if (result && result.data) {
                    this.setState({ labinfo: result.data, showLoading: false })
                } else {
                    sweetalert.fire({ icon: 'info', text: 'Report Information is not set' })
                        .then(() => { this.setState({ showLoading: false }) });
                }
            })
            .catch((e: Error) => {
                console.error(e);
                sweetalert.fire({ text: 'Something went wrong. ' + e, icon: 'error' });
            });
    }

    validateLabInfo(e){
        e.preventDefault();

        // add required field as a property on the formErrors object
        let formErrors = {
            CLIA: true,        // required
            Director: true,    // required 
            EntityName: true,  // required
            HelpEmail: false,  // optional, but should be valid email format if present
        }

        if (isBlank(this.state.labinfo.CLIA) ||
            isOnlyWhitespace(this.state.labinfo.CLIA)) {
                sweetalert.fire({ icon: 'error', title: '', text: 'CLIA is required.' })
        } else { formErrors.CLIA = false; }
        if (isBlank(this.state.labinfo.Director) ||
            isOnlyWhitespace(this.state.labinfo.Director)) {
                sweetalert.fire({ icon: 'error', title: '', text: 'Director is required.' })
        } else { formErrors.Director = false; }
        if (isBlank(this.state.labinfo.EntityName) ||
            isOnlyWhitespace(this.state.labinfo.EntityName)) {
                sweetalert.fire({ icon: 'error', title: '', text: 'Entity Name is required.' })
        } else { formErrors.EntityName = false; }

        // HelpEmail is not required, but if filled out, should be isValidEmail
        if (!isBlank(this.state.labinfo.HelpEmail) && 
            !isOnlyWhitespace(this.state.labinfo.HelpEmail)){
                if(!isValidEmail(this.state.labinfo.HelpEmail)){
                    formErrors.HelpEmail = true;
                    sweetalert.fire({ icon: 'error', text: 'Verify Help Email format.'});
                }
        }

        var errors = Object.keys(formErrors)
            .some(function (field) {
                return formErrors[field] === true;
            });


        if(!errors){ this.updateDefaultLabInfo(); }
    }

    updateDefaultLabInfo() {
        this.setState({ showLoading: true }, async () => {
            SettingsAPI.updateDefaultLabInfo(this.state.labinfo)
            .then((response: { success: boolean, reason: string, data?: LabInformation }) => {
                this.setState({ labinfo: response.data, showLoading: false });
                sweetalert.fire({ text: "Report Information updated.", icon: "success" })
                    .then(() => {
                        this.loadDefaultLabInfo();
                    })
            })
            .catch((err: Error) => {
                console.error(err);
                sweetalert.fire({ text: "Something went wrong. " + err, icon: 'error' });
            });
        });
    }

    render (){
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading} />
                <div className="container-fluid  min-vh-100 ">
                    <div className={"row"}>
                        <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
                            <main id="main-content" tabIndex={-1} aria-label="Report Information">
                                <div className="card mb-2">
                                    <div className="card-header verlag-bold">
                                        <h4>Report Information</h4>
                                    </div>
                                    <div className="card-body">
                                                <form id="LabInfoForm" name="LabInfoForm" noValidate>
                                                        <div className="form-group row">
                                                            <label htmlFor={"clia"} className="col-sm-4 col-form-label"
                                                                >CLIA<span style={{ color: "#ec0000", fontSize: "1.2rem" }}>*</span></label>
                                                            <div className="col-sm-8  p-0 m-0" id={"clia"}>
                                                                <input className="form-control"
                                                                    maxLength={45}
                                                                    autoComplete="off"
                                                                    type={"text"}
                                                                    aria-label='CLIA'
                                                                    name={"clia"} id={"clia"}
                                                                    onChange={(e) => {
                                                                        this.setState((prevState) => ({
                                                                            labinfo: {
                                                                                ...prevState.labinfo,
                                                                                CLIA: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.labinfo && this.state.labinfo.CLIA ? this.state.labinfo.CLIA : ""}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        {buildRow("Unique OID",
                                                            <input className="form-control"
                                                                id='UniqueOID'
                                                                maxLength={45}
                                                                autoComplete="off"
                                                                type={"text"}
                                                                aria-label='Unique OID'
                                                                name={"labID"}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        labinfo: {
                                                                            ...prevState.labinfo,
                                                                            LabID: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.labinfo && this.state.labinfo.LabID ? this.state.labinfo.LabID : ""}
                                                            />)}
                                                        <div className="form-group row">
                                                            <label htmlFor={"director"} className="col-sm-4 col-form-label"
                                                                >Director <span style={{ color: "#ec0000", fontSize: "1.2rem" }}>*</span></label>
                                                            <div className="col-sm-8  p-0 m-0" id={"director"}>
                                                                <input className="form-control"
                                                                    id='director'
                                                                    maxLength={45}
                                                                    autoComplete="off"
                                                                    type={"text"}
                                                                    aria-label='Director'
                                                                    name={"director"}
                                                                    onChange={(e) => {
                                                                        this.setState((prevState) => ({
                                                                            labinfo: {
                                                                                ...prevState.labinfo,
                                                                                Director: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.labinfo && this.state.labinfo.Director ? this.state.labinfo.Director : ""}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label htmlFor={"entityName"} className="col-sm-4 col-form-label"
                                                                >Entity Name <span style={{ color: "#ec0000", fontSize: "1.2rem" }}>*</span></label>
                                                            <div className="col-sm-8  p-0 m-0" id={"entityName"}>
                                                                <input className="form-control"
                                                                    id='entityName'
                                                                    maxLength={45}
                                                                    autoComplete="off"
                                                                    type={"text"}
                                                                    aria-label='Entity Name'
                                                                    name={"entityName"}
                                                                    onChange={(e) => {
                                                                        this.setState((prevState) => ({
                                                                            labinfo: {
                                                                                ...prevState.labinfo,
                                                                                EntityName: e.target.value
                                                                            }
                                                                        }))
                                                                    }}
                                                                    value={this.state.labinfo && this.state.labinfo.EntityName ? this.state.labinfo.EntityName : ""}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        {buildRow("Street Address",
                                                            <input className="form-control"
                                                                id='StreetAddress'
                                                                maxLength={45}
                                                                autoComplete="off"
                                                                type={"text"}
                                                                aria-label='Street Address'
                                                                name={"streetAddress"}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        labinfo: {
                                                                            ...prevState.labinfo,
                                                                            StreetAddressOne: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.labinfo && this.state.labinfo.StreetAddressOne ? this.state.labinfo.StreetAddressOne : ""}
                                                            />)}
                                                        {buildRow("Street Address cont.",
                                                            <input className="form-control"
                                                                id='StreetAddresscont.'
                                                                maxLength={45}
                                                                autoComplete="off"
                                                                type={"text"}
                                                                aria-label='Continued Street Address'
                                                                name={"streetAddress2"}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        labinfo: {
                                                                            ...prevState.labinfo,
                                                                            StreetAddressTwo: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.labinfo && this.state.labinfo.StreetAddressTwo ? this.state.labinfo.StreetAddressTwo : ""}
                                                            />)}
                                                        {buildRow("City",
                                                            <input className="form-control"
                                                                id='City'
                                                                maxLength={45}
                                                                autoComplete="off"
                                                                type={"text"}
                                                                aria-label='City'
                                                                name={"city"}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        labinfo: {
                                                                            ...prevState.labinfo,
                                                                            City: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.labinfo && this.state.labinfo.City ? this.state.labinfo.City : ""}
                                                            />)}
                                                        {buildRow("State",
                                                            <input className="form-control"
                                                                id='State'
                                                                maxLength={45}
                                                                autoComplete="off"
                                                                type={"text"}
                                                                aria-label='State'
                                                                name={"state"}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        labinfo: {
                                                                            ...prevState.labinfo,
                                                                            State: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.labinfo && this.state.labinfo.State ? this.state.labinfo.State : ""}
                                                            />)}
                                                        {buildRow("Zipcode",
                                                            <input className="form-control"
                                                                id='Zipcode'
                                                                maxLength={45}
                                                                autoComplete="off"
                                                                type={"text"}
                                                                aria-label='Postal Code'
                                                                name={"zipcode"}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        labinfo: {
                                                                            ...prevState.labinfo,
                                                                            Zipcode: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.labinfo && this.state.labinfo.Zipcode ? this.state.labinfo.Zipcode : ""}
                                                            />)}
                                                        {buildRow("County",
                                                            <input className="form-control"
                                                                id='County'
                                                                maxLength={45}
                                                                autoComplete="off"
                                                                type={"text"}
                                                                aria-label='County'
                                                                name={"county"}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        labinfo: {
                                                                            ...prevState.labinfo,
                                                                            County: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.labinfo && this.state.labinfo.County ? this.state.labinfo.County : ""}
                                                            />)}
                                                        {buildRow("Help Email",
                                                            <input className="form-control"
                                                                id='HelpEmail'
                                                                maxLength={100}
                                                                autoComplete="off"
                                                                type={"email"}
                                                                aria-label='Help Email'
                                                                name={"helpEmail"}
                                                                onChange={(e) => {
                                                                    this.setState((prevState) => ({
                                                                        labinfo: {
                                                                            ...prevState.labinfo,
                                                                            HelpEmail: e.target.value
                                                                        }
                                                                    }))
                                                                }}
                                                                value={this.state.labinfo && this.state.labinfo.HelpEmail ? this.state.labinfo.HelpEmail : ""}
                                                            />)}
                                                        {buildRow("Last Updated By",
                                                            <input className="form-control"
                                                                id='LastUpdatedBy'
                                                                type={"text"}
                                                                name={"lastUpdatedBy"}
                                                                aria-label='Last Updated By'
                                                                value={this.state.labinfo && this.state.labinfo.LastUpdatedBy ? this.state.labinfo.LastUpdatedBy : ""}
                                                                readOnly
                                                            />)}
                                                        {buildRow("Last Updated Date",
                                                            <input className="form-control"
                                                                id='LastUpdatedDate'
                                                                type={"text"}
                                                                name={"lastUpdatedDate"}
                                                                aria-label='Last Updated Date'
                                                                value={this.state.labinfo && this.state.labinfo.LastUpdatedDate ? this.state.labinfo.LastUpdatedDate : ""}
                                                                readOnly
                                                            />)}
                                
                                                </form>
                                
                                    </div>
                                    <div className='card-footer'>
                                        <button className={this.state.labinfo && this.state.labinfo.ID ? 'btn immySubmitButtonOutline float-right' : 'btn btn-outline-success float-right'} type={"submit"}
                                            onClick={(e) => { this.validateLabInfo(e) }}>{this.state.labinfo && this.state.labinfo.ID ? 'Save' : 'Create'}</button>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
