import domain from "../Domain";
import { AuthContext } from "../context/AuthContext";
import {ServerResponse} from "../types/ServerResponse";
import React from "react";

export default class NetworkUtil extends React.Component {
    static contextType = AuthContext;
    static UserContext;
    
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        NetworkUtil.UserContext = this.context;
    }

    public static async downloadCSV(route:string, fileName:string, body:any):Promise<boolean>{
        return new Promise((resolve, reject) =>{
            let xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // make downloadable link
                    let a = document.createElement('a')
                    a.href = window.URL.createObjectURL(xhttp.response)
                    a.download = fileName; // give the download a filename
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    resolve(true);
                }
            };
            xhttp.open("POST",  route)
            if(NetworkUtil.UserContext){
                xhttp.setRequestHeader("Authorization", `Bearer ${NetworkUtil.UserContext.accessToken}`)
            }
            xhttp.setRequestHeader("Content-Type", "application/json");
            xhttp.responseType = 'blob';
            xhttp.send(JSON.stringify(body));
        })
    }

    public static buildURI(route: string): string {
        if(route[0] !== "/") {
            route = "/" + route;
        }
        return domain + route;
    }

    public static async makePostForBuffer<T>(route:string, body:any): Promise<Blob | ServerResponse> {
        let headers = NetworkUtil.UserContext && NetworkUtil.UserContext.accessToken ?
            { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${NetworkUtil.UserContext.accessToken}` 
            } :
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };
        let res = await fetch(NetworkUtil.buildURI(route), {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });

        if(res.status === 404){
            let json = await res.json();
            return json as ServerResponse;
        }
        else{
            return res.blob();
        }
    
    }

    public static async makeGet<T>(route:string):Promise<T>{
        let headers = NetworkUtil.UserContext && NetworkUtil.UserContext.accessToken ?
            { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${NetworkUtil.UserContext.accessToken}` } :
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        let res = await fetch(NetworkUtil.buildURI(route),{
                method: 'GET',
                headers
            })

        let json = await res.json();
        return json as T;
    }
    
    public static async makePost<T>(route: string, body: any): Promise<T> {
        let headers = NetworkUtil.UserContext && NetworkUtil.UserContext.accessToken ? 
            { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${NetworkUtil.UserContext.accessToken}` } :
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        let res = await fetch(NetworkUtil.buildURI(route), {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        let json = await res.json();
        return json as T;
    }

    //used to get Insurance Images for Samples Modal
    public static async makePostForBlob<T>(route:string, body:any): Promise<Blob | ServerResponse> {
        let headers = NetworkUtil.UserContext && NetworkUtil.UserContext.accessToken ? 
            { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${NetworkUtil.UserContext.accessToken}` } :
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        let res = await fetch(NetworkUtil.buildURI(route), {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });


        if(res.status === 404 || res.status === 500){
            let json = await res.json();
            return json as ServerResponse;
        }
        else{
            let blob = await res.blob();
            blob = URL.createObjectURL(blob) as any
            return blob as Blob;
        }
    }

    /**
     * Does not stringify the body or sent application/json headers.
     * Should only be used when posting FORM data, not data serialized as JSON.
     */
    public static async postForm<T>(route: string, body: any): Promise<T> {
        let res;
        if(NetworkUtil.UserContext && NetworkUtil.UserContext.accessToken){
            res = await fetch(NetworkUtil.buildURI(route),{
                method: 'POST',
                headers: {'Authorization': `Bearer ${NetworkUtil.UserContext.accessToken}`},
                body: body
            })
        } else {
            res = await fetch(NetworkUtil.buildURI(route), {
                method: 'POST',
                body: body
            });
        } 
        let json = await res.json();
        return json as T;
    }

    // render nothing on screen
    render() {
        return (
            <>
            </>
        )
    }
}
