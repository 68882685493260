import React from "react";
import 'react-quill/dist/quill.snow.css';
import Select from "react-select";
import TestType from "../../types/TestType";
import Overlay from "../Overlay";
import {
    getBoolSelectOptions,
    getBoolSelectVal, getNumPages, getPageOptions, hideModal,
    showModalNoOutsideClick,
    slicePages
} from "../../util/FormatUtil";
import {TestManagementModal} from "../modals/TestManagementModal";
import {sweetalert} from '../../App';
import TestsAPI from "../../network/TestsAPI";
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";
import {AdminPages} from "./AdminNav";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import Parse from "html-react-parser";
import {SampleTestReportModal} from "../modals/SampleTestReportModal";
import InBetweenOverlay from "../InBetweenOverlay";
import ResultsAPI from "../../network/ResultsAPI";


const ITEMS_PER_PAGE = 25;

interface TestManagementState {
    selectedTest?;
    tests: TestType[]
    testDescription?
    name?
    shortName?
    isEnabled?:{label, value}
    showLoading?
    showInBetween?
    resultTypes:{label, value}[]
    allResults
    specimenSources:{label, value}[]
    pageCustomQuestions?
    filter?:{
        SelectedTest
    }
    tableData:{}[]
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    testQueryResults:TestType[]
}
export interface PostedTest extends TestType{
    ResultIDs:number[]
    SpecimenIDs:number[]
}

export class TestManagement extends React.Component<any, TestManagementState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            selectedTest: {} as TestType,
            tests: [], 
            resultTypes:[],
            allResults: [],
            specimenSources:[],
            pageCustomQuestions: [],
            filter: {SelectedTest:[],
            },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            testQueryResults: [] as any,
        }
        this.createOrModifyTest = this.createOrModifyTest.bind(this);
    }


    componentDidMount() {
        document.title = 'Test Management Page';
        this.setState({showLoading: true}, () => {
            SystemAPI.getCustomQuestions(AdminPages.Test).then(res =>{
                this.setState({pageCustomQuestions:res.questions})
            })
            SystemAPI.getAllTests().then(data => {
                this.setState({tests: data})
            })
            AdminAPI.getAllEnabledResultTypes().then(data => {
                this.setState({resultTypes: data} as any)
            })
            SystemAPI.getAllSpecimenSources().then(data => {
                this.setState({specimenSources: data})
            })
            ResultsAPI.getAllResultsData().then(data => {
                this.setState({allResults: data.data.results})
            })
            this.queryTest(1)
        })
    }

    queryTest(page:number) {
        this.setState({showLoading:true}, async () => {
            try {
                let results = await TestsAPI.filterTest({
                    filter: {SelectedTest: this.state.filter.SelectedTest,
                        }
                })
                if(results.data.length < 1){
                    this.setState({showLoading: false, tableData: [], testQueryResults: null})
                    return sweetalert.fire({title: '', text: 'No Tests returned', icon: 'info'})
                }

                if(!results.success){
                    return sweetalert.fire({title: '', text: 'Unable to filter Test data at this time', icon: 'error'})
                }
                this.setState({
                    tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
                    testQueryResults: results.data,
                    page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
                    showLoading: false,
                })
            } catch (e) {
                console.log(e)
                this.setState({showLoading: false})
            }
        })
    }


    createOrModifyTest(test:TestType){


        this.setState({showLoading:true}, async() => {
            if(test.ID === null){
                try {
                    let result = await TestsAPI.newTest(test);
                    if(result.success){
                        sweetalert.fire({icon: 'success', title: '', text: 'Test saved'}).then(()=>{
                            this.setState({
                                selectedTest: {
                                    Name: '',
                                    ShortName: '',
                                    IsEnabled: null,
                                    ReportDescription: '',
                                    TestOrderedCode: '',
                                    TestOrderedDescription: '',
                                    TestPerformedCode: '',
                                    TestPerformedDescription: '',
                                    LowRangeStart: null,
                                    LowRangeEnd : null,
                                    ModerateRangeStart : null,
                                    ModerateRangeEnd : null,
                                    HighRangeStart : null,
                                    HighRangeEnd : null,
                                    CustomFieldData: null,
                                    PrescriptionRecommendationThreshold: null,
                                    IncludeInReporting: null,
                                } as TestType
                            }, () => {
                                SystemAPI.getAllTests().then(data => {
                                    this.setState({tests: data, showLoading: false})
                                })
                                this.queryTest(this.state.selected_page.value);
                            });
                        });
                    }
                    else{
                        sweetalert.fire({icon: 'error', title: '', text: result.reason});
                        this.setState({showLoading: false})
                    }
                }catch (e) {
                    console.log(e)
                    this.setState({showLoading: false})
                }
            } else {
                try{
                    let result = await TestsAPI.editTest(test)
                    if(result.success){
                        sweetalert.fire({icon: 'success', title: '', text: 'Test saved'}).then(()=>{
                            this.setState({
                                selectedTest: {
                                    ID: null,
                                    Name: '',
                                    ShortName: '',
                                    IsEnabled: null,
                                    ReportDescription: '',
                                    TestOrderedCode: '',
                                    TestOrderedDescription: '',
                                    TestPerformedCode: '',
                                    TestPerformedDescription: '',
                                    LowRangeStart: null,
                                    LowRangeEnd : null,
                                    ModerateRangeStart : null,
                                    ModerateRangeEnd : null,
                                    HighRangeStart : null,
                                    HighRangeEnd : null,
                                    CustomFieldData: null,
                                    PrescriptionRecommendationThreshold: null,
                                    IncludeInReporting: null,
                                } as TestType
                            }, () => {
                                SystemAPI.getAllTests().then(data => {
                                    this.setState({tests: data, showLoading: false})
                                })
                                this.queryTest(this.state.selected_page.value);
                            });
                        });
                    }else{
                        sweetalert.fire({icon: 'error', title: '', text: result.reason});
                        this.setState({showLoading:false})
                    }
                }
                catch (e) {
                    console.log(e)
                    this.setState({showLoading:false})
                }
            }
        });
    }

    handleExportToCSV(){
        this.setState({showLoading: true}, async () =>{
            await NetworkUtil.downloadCSV(
                "/api/admin/tests/csv",
                "Test Management Data.xlsx",
                {filter: this.state.filter}
            )
            this.setState({showLoading: false})
        });
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()

        this.setState({tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
    }

    changePage(page:number){
        let allTests = this.state.testQueryResults;
        let returnData = slicePages(allTests, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    renderTestMgmtFilterFields(){
        return (
            <FilterCard fields={[
                {
                    label: "Test Name",
                    "key": "SelectedTest",
                    type: FIELD_TYPE.SELECT,
                    options: this.state.tests,
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    isFilter: true
                }, 
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    getTestDetails(val){

        this.setState({showLoading: true}, async () => {
            try {
                let result = await TestsAPI.queryTest({ID: val.ID})

                if (result.success) {
                    let test = result.test
                    this.setState({selectedTest: {
                        ID: test.ID ? test.ID : null,
                        CreatedDate: test.CreatedDate ? test.CreatedDate : null,
                        Name: test.Name ? test.Name : "",
                        ShortName: test.ShortName ? test.ShortName : "",
                        ReportDescription: test.ReportDescription ? test.ReportDescription : "",
                        IsEnabled: test.IsEnabled !== null ? test.IsEnabled : null,
                        TestPerformedCode: test.TestPerformedCode ? test.TestPerformedCode : "",
                        TestPerformedDescription: test.TestPerformedDescription ? test.TestPerformedDescription : "",
                        TestOrderedCode: test.TestOrderedCode ? test.TestOrderedCode : "",
                        TestOrderedDescription: test.TestOrderedDescription ? test.TestOrderedDescription : "",
                        LowRangeStart: test.LowRangeStart ? test.LowRangeStart : null,
                        LowRangeEnd: test.LowRangeEnd ? test.LowRangeEnd : null,
                        ModerateRangeStart: test.ModerateRangeStart ? test.ModerateRangeStart : null,
                        ModerateRangeEnd: test.ModerateRangeEnd ? test.ModerateRangeEnd : null,
                        HighRangeStart: test.HighRangeStart ? test.HighRangeStart : null,
                        HighRangeEnd: test.HighRangeEnd ? test.HighRangeEnd : null,
                        CustomFieldData: test.CustomFieldData ? test.CustomFieldData : null,
                        PrescriptionRecommendationThreshold: test.PrescriptionRecommendationThreshold ? test.PrescriptionRecommendationThreshold : null,
                        IncludeInReporting: test.IncludeInReporting !==null ? test.IncludeInReporting : null,
                        allowedSources: test.ValidSpecimenSources ? this.state.specimenSources.filter(s => test.ValidSpecimenSources.map(validSource => validSource.ID).includes(s.value)) : [],
                        allowedResults: test.ValidResults ? this.state.resultTypes.filter(s => test.ValidResults.map(validSource => validSource.ID).includes(s.value)) : [],
                    },
                    showLoading: false}, () => showModalNoOutsideClick(TestManagementModal.ID))
                }
            } catch (e) {
                console.error(e)
                this.setState({showLoading: false})
            }
        })

    }

    render() {

        // console.log('TestMgmt state', this.state)

        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <TestManagementModal
                selectedTest={this.state.selectedTest}
                resultTypes={this.state.resultTypes}
                allResults={this.state.allResults}
                specimenSources={this.state.specimenSources}
                onSubmit={(test:TestType) => {
                    hideModal(TestManagementModal.ID);
                    this.createOrModifyTest(test);
                }}
                pageCustomQuestions={this.state.pageCustomQuestions}
            />
            <SampleTestReportModal selectedTest={this.state.selectedTest} handleShowInBetween={() => this.setState({showInBetween: false})} />
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-12 col-lg-8 col-xl-5 pt-2">
                        <main id="main-content" tabIndex={-1} aria-label="Test Management">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Test Management</h4>
                                </div>
                                <div className="card-body">
                                    {this.renderTestMgmtFilterFields()}
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline"}
                                            onClick={ () => {
                                                this.setState({
                                                    selected_page: {label: 1, value: 1}}, () => {
                                                    this.queryTest(this.state.selected_page.value)
                                                })
                                            }}
                                    >Search</button>
                                    <button className="btn btn-outline-success float-right"
                                            onClick={()=> this.setState({
                                                selectedTest: {
                                                    ID: null,
                                                    Name: '',
                                                    ShortName: '',
                                                    IsEnabled: null,
                                                    ReportDescription: '',
                                                    TestOrderedCode: '',
                                                    TestOrderedDescription: '',
                                                    TestPerformedCode: '',
                                                    TestPerformedDescription: '',
                                                    LowRangeStart: null,
                                                    LowRangeEnd: null,
                                                    ModerateRangeStart: null,
                                                    ModerateRangeEnd: null,
                                                    HighRangeStart: null,
                                                    HighRangeEnd: null,
                                                    CustomFieldData: null,
                                                    PrescriptionRecommendationThreshold: null,
                                                    IncludeInReporting: null
                                                } as TestType
                                            },()=>{
                                                showModalNoOutsideClick(TestManagementModal.ID)
                                            })}
                                    >Create New</button>
                                </div>
                            </div>
                        </main>
                    </div>
                    
                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-md-12 pt-2">
                            <div className="card mt-2 mb-5">
                                <div className="card-header verlag-bold">
                                    <h4 className="text-center text-md-left">Tests
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                        <h4 className={'float-md-right'} aria-label="Total Records" role="alert">Total: {this.state.testQueryResults.length}</h4>
                                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                        <div className=" align-middle float-right pages ">
                                            <Select
                                                isSearchable={true}
                                                placeholder={"1"}
                                                noOptionsMessage={() => "No option"}
                                                value={this.state.selected_page}
                                                aria-label="Table Page Number"
                                                onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                    () => this.changePage(e.value))}
                                                className={"state_select"}
                                                options={this.state.page_options}
                                            />
                                        </div>
                                    </section>
                                    <button className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                            onClick={() => this.handleExportToCSV()}
                                    >Export to CSV
                                    </button>
                                    </h4>
                                </div>
                                <div className="card-body p-0 m-0 table-responsive">
                                    <SimpleTable table_style='tableFixHead' columns={[
                                        {
                                            label: "Name", key: "Name",
                                            rawFormat: (val) => {
                                                return <a href={"#!"} className={'tableNameLinkColor'} onClick={() => {
                                                    this.getTestDetails(val)
                                                }}>{val.Name}</a>
                                            },
                                            popoverText: "Click to sort by Name"
                                        },
                                        {
                                            label: "Short Name", key: "ShortName",
                                            popoverText: "Click to sort by Short Name"
                                        },
                                        {
                                            label: "Is Enabled?", key: "IsEnabled",
                                            popoverText: "Click to sort by Is Enabled",
                                            rawFormat: (val) => {
                                                let string = getBoolSelectVal(val.IsEnabled)
                                                return <p>{string.label}</p>
                                            }
                                        },
                                        {
                                            label: "Sample Report", key: "",
                                            rawFormat: (val) => {
                                                return <a  href={"#top"} className={'btn btn-success'} style={{color: "white"}} onClick={() => {
                                                    this.setState({ selectedTest: val },
                                                        () => showModalNoOutsideClick(SampleTestReportModal.ID))
                                                }}>View Sample Report</a>
                                            },
                                        },
                                    ]} table_data={this.state.tableData} columnClickedCallback={(col =>{
                                        this.useSorter(col);
                                    })} />
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>)
    }
}