import React from "react";
import Select from 'react-select'
import { buildRow, getLabel, hideModal, showModalNoOutsideClick } from "../../util/FormatUtil"
import { sweetalert } from "../../App";
import Validator from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import { isEmptyObject } from "jquery";
import Price from "../../types/Price";
import { HintModal } from "./HintModal";
import { FaInfoCircle } from "react-icons/fa";
import { BsFillRecord2Fill } from "react-icons/bs";

interface PricingManagementModalState{
    selectedPrice:Price
    hintHeader
    hintContent
}

interface PricingManagementModalProps{
    selectedPrice:Price
    prices:Price[]
    priceTypes?
    onSubmit?:(price:Price)=>void
}
export class PricingManagementModal extends React.Component<PricingManagementModalProps,PricingManagementModalState>{
    public static ID = "pricemanagement"
    constructor(props){
        super(props);
        this.state = {
            selectedPrice: {
                ID: null,
                Name: "",
                Description: "",
                Cost: 0,
                CreatedBy: "",
                CreatedDate: null,
                UpdatedBy: "",
                UpdatedDate:null,
                PricingTypeID: null
            } as Price ,
            hintHeader: "Pricing Hints",
            hintContent: [
              <div style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Name: short name for the price</div>,
              <div style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Description: what the price is for etc</div>,
              <div style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Type: Standard price is displayed on Payment Information modal of Submission Form.</div>,
              <div style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Type: Upgrade price is displayed on Payment Modal from Samples table "Upgrade Sample" button.</div>,
              <div style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Cost: amount to be charged to the customer.</div>,
            ],
        }
        this.clearFields = this.clearFields.bind(this);
    }

    componentDidMount(): void {
        $(`#${PricingManagementModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    componentWillReceiveProps(nextProps:Readonly<PricingManagementModalProps>,nextContext:any){
        if(!isEmptyObject(nextProps.selectedPrice)){
            let price = nextProps.selectedPrice;
            this.setState({
                selectedPrice: {
                    ID: price.ID,
                    Name: price.Name ? price.Name : "",
                    Description: price.Description ? price.Description : "",
                    Cost: price.Cost ? price.Cost : 0,
                    PricingTypeID: price.PricingTypeID ? price.PricingTypeID : null,
                    CreatedBy: price.CreatedBy ? price.CreatedBy : "",
                    CreatedDate : price.CreatedDate ? price.CreatedDate : new Date(),
                    UpdatedBy: price.UpdatedBy ? price.UpdatedBy : "",
                    UpdatedDate: price.UpdatedDate ? price.UpdatedDate : null,
                } as Price 
            })
        }
    }

    clearFields(){
        this.setState({
            selectedPrice: {
                ID: null,
                Name: "",
                Description: "",
                Cost: 0,
                PricingTypeID: null,
                CreatedBy: "",
                CreatedDate: null,
                UpdatedBy: "",
                UpdatedDate:null,
            } as Price
        })
    }

    render(){
        return(
            <div className="modal fade form_modal" 
                id={PricingManagementModal.ID} tabIndex={-1} role="dialog"
                aria-labelledby="result_modal_label"
                aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <HintModal
                          handleInBetweenOverlay={() => {}}
                          label={"Pricing Hints"}
                          header={this.state.hintHeader}
                          content={this.state.hintContent}
                        />
                        <div className="container-fluid">
                            <div className="modal-header">
                                <h4 className="modal-title" id="result_modal_label">Price Management
                                    <FaInfoCircle
                                      tabIndex={0}
                                      aria-label="Pricing Hints"
                                      role="button"
                                      className={"ml-1"}
                                      size={25}
                                      color={"#21212E"}
                                      onClick={() => showModalNoOutsideClick(HintModal.ID)}
                                    />
                                </h4>
                                <button  style={{outline: 'none'}} type="button" 
                                    onClick={() => this.clearFields()} 
                                    className="close" 
                                    data-dismiss="modal" 
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body m-3">
                                {buildRow("Name",
                                    <input 
                                        id="Name"
                                        className="form-control"
                                        maxLength={50}
                                        autoComplete="off"
                                        type={"search"}
                                        name={"Name"}
                                        aria-label="Name"
                                        onChange={(e) => {
                                            this.setState((prevState) => ({
                                                selectedPrice: {
                                                    ...prevState.selectedPrice,
                                                    Name: e.target.value
                                                }
                                            }))
                                        }}
                                        value={this.state.selectedPrice.Name}
                                    />)}
                                {buildRow("Description",
                                    <input 
                                        id="Description"
                                        className="form-control"
                                        maxLength={50}
                                        autoComplete="off"
                                        type={"search"}
                                        name={"Description"}
                                        aria-label="Description"
                                        onChange={(e) => {
                                            this.setState((prevState) => ({
                                                selectedPrice: {
                                                    ...prevState.selectedPrice,
                                                    Description: e.target.value
                                                }
                                            }))
                                        }}
                                        value={this.state.selectedPrice.Description}
                                    />)}
                                    
                                {buildRow("Type",
                                    <Select
                                        aria-label="Price Type"
                                        isSearchable={true}
                                        placeholder={<div className="accessibilityText">Please Select...</div>}
                                        noOptionsMessage={() => "No option"}
                                        value={this.state.selectedPrice.PricingTypeID ? getLabel(this.state.selectedPrice.PricingTypeID, this.props.priceTypes) : null}
                                        onChange={(e) => this.setState((prevState) => ({
                                            selectedPrice: {
                                                ...prevState.selectedPrice,
                                                PricingTypeID: e.value
                                            }
                                        }))}
                                        className={"state_select"}
                                        options={this.props.priceTypes}
                                    />
                                    )}
                                {buildRow("Cost",
                                    <div className="form-group input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text text-dark"
                                            style={{ "paddingLeft": "17.5px", "paddingRight": "17.5px" }}
                                        >$</span>
                                    </div>
                                    <input 
                                        id="Cost"
                                        className="form-control"
                                        autoComplete="off"
                                        type={"number"}
                                        min={"0"}
                                        name={"Cost"}
                                        aria-label="Cost"
                                        aria-invalid={false}
                                        aria-required={true}
                                        onChange={(e) => {
                                            this.setState((prevState) => ({
                                                selectedPrice: {
                                                    ...prevState.selectedPrice,
                                                    Cost: e.target.value as any
                                                }
                                            }))
                                        }}
                                        value={this.state.selectedPrice.Cost}
                                    />
                                    </div>
                                    )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" 
                                    onClick={() => {
                                        let formValidation = {
                                            Name: this.state.selectedPrice.Name,
                                            Description: this.state.selectedPrice.Description,
                                            Cost: this.state.selectedPrice.Cost,
                                            PricingTypeID: this.state.selectedPrice.PricingTypeID
                                        }
                                        let validator = new Validator<any>()
                                            .withValidation("Name", Validators.requireNotBlankAndLengthAndUnique(50, this.props.prices.filter(p=> p.ID !== this.state.selectedPrice.ID).map(p=>p.Name),"Name"))
                                            .withValidation("Description", Validators.requireNotBlankAndLength(50, "Description"))
                                            .withComposedValidation("Cost", Validators.requirePositveNumberWithTwoDecimalPlaces("Cost"))
                                            .withSimpleValidation("PricingTypeID", Validators.requireNonNullValidator("Type"))
                                        let validationResponse = validator.validate(formValidation);
                                        if(!validationResponse.success){
                                            return sweetalert.fire({
                                                icon: 'error',
                                                title: '',
                                                text: validationResponse.error
                                            });
                                        }
                                        hideModal(PricingManagementModal.ID)
                                        this.props.onSubmit({
                                            ID: this.state.selectedPrice.ID,
                                            Name: this.state.selectedPrice.Name,
                                            Description: this.state.selectedPrice.Description,
                                            Cost: this.state.selectedPrice.Cost,
                                            PricingTypeID: this.state.selectedPrice.PricingTypeID,
                                            CreatedBy: this.state.selectedPrice.CreatedBy,
                                            CreatedDate: this.state.selectedPrice.CreatedDate,
                                            UpdatedBy: this.state.selectedPrice.UpdatedBy, 
                                            UpdatedDate: this.state.selectedPrice.UpdatedDate
                                        } as Price );
                                        this.setState({
                                            selectedPrice:{
                                                ID: null,
                                                Name: "",
                                                Description: "",
                                                Cost: null,
                                                PricingTypeID: null,
                                                CreatedBy: "",
                                                CreatedDate: null,
                                                UpdatedBy: "",
                                                UpdatedDate: null,
                                            }
                                        });
                                    }}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}