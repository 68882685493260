import React from "react";
import Select from "react-select";
import Overlay from "../Overlay";
import {
  getLabel,
    getNumPages, getPageOptions, hideModal,
    showModalNoOutsideClick,
    slicePages
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";

import Price from "../../types/Price";
import {PricingManagementModal} from "../modals/PricingManagementModal";
import PricingAPI from "../../network/PricingAPI";

const ITEMS_PER_PAGE = 25;

interface PricingManagementState {
    selectedPrice?;
    prices: {label:string,value:number}[]
    priceDescription?
    name?
    description?
    showLoading?
    showInBetween?
    filter?:{
        SelectedPrice:Price[]
    }
    tableData:{}[]
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    priceQueryResults:Price[]
    priceTypes?
}

export class PricingManagement extends React.Component<any, PricingManagementState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            selectedPrice: {} as Price,
            prices: [], 
            filter: {
                SelectedPrice: [],
            },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            priceQueryResults: [] as any,
            priceTypes: [] as any
        }
        this.createOrModifyPrice = this.createOrModifyPrice.bind(this);
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            PricingAPI.getAllPricingForSelect().then(response=> {
                this.setState({ prices: response.data })
            })
            PricingAPI.getAllPricingTypesForSelect().then(response=>{
              this.setState({priceTypes: response.data})
            })

            this.queryPrice(1); // calls setState showLoading: false
        });
    }

    queryPrice(page:number) {
        this.setState({showLoading:true}, async () => {
            try {
                let results = await PricingAPI.filterPricing({
                    filter: {
                        SelectedPrice: this.state.filter.SelectedPrice
                    }
                });

                if(!results.success){
                    return sweetalert.fire({title: '', text: 'Unable to filter Pricing data at this time', icon: 'error'})
                }

                let parsedResults = results.data.map(result => {
                    result.Cost = result.Cost.toFixed(2) as any
                    return result;
                })

                this.setState({
                    tableData: slicePages(parsedResults, page, ITEMS_PER_PAGE),
                    priceQueryResults: parsedResults,
                    page_options: getPageOptions(getNumPages(parsedResults, ITEMS_PER_PAGE)),
                    showLoading: false,
                });
            } catch (e) {
                console.log(e)
                this.setState({showLoading: false})
            }
        });
    }


    createOrModifyPrice(price:Price){
        this.setState({showLoading:true}, async() => {
            if(price.ID === null){
                try {
                    let result = await PricingAPI.newPricing(price);
                    if(result.success){
                        sweetalert.fire({ 
                            icon: 'success', 
                            title: '', 
                            text: 'Price created'
                        }).then(()=>{
                            this.setState({
                                selectedPrice: {
                                    Name: '',
                                    Description: '',
                                    Cost: null,
                                    CreatedBy: "",
                                    CreatedDate: null,
                                    UpdatedBy: "",
                                    UpdatedDate: null,
                                    PricingTypeID: null
                                } as Price 
                            }, () => {
                                PricingAPI.getAllPricingForSelect().then(response => {
                                    this.setState({
                                        prices: response.data, 
                                        showLoading: false
                                    })
                                })
                                this.queryPrice(this.state.selected_page.value);
                            });
                        });
                    }
                    else{
                        sweetalert.fire({
                            icon: 'error', 
                            title: '', 
                            text: result.reason
                        });
                        this.setState({showLoading: false});
                    }
                }catch (e) {
                    console.log(e);
                    this.setState({showLoading: false});
                }
            } else {
                try{
                    let result = await PricingAPI.editPricing(price)
                    if(result.success){
                        sweetalert.fire({
                            icon: 'success', 
                            title: '', 
                            text: 'Price saved'
                        }).then(()=>{
                            this.setState({
                                selectedPrice: {
                                    ID: null,
                                    Name: '',
                                    Description: '',
                                    Cost: null,
                                    PricingTypeID: null,
                                    CreatedBy: "",
                                    CreatedDate: null,
                                    UpdatedBy: "",
                                    UpdatedDate: null,
                                } as Price 
                            }, () => {
                                PricingAPI.getAllPricingForSelect().then(response=> {
                                    this.setState({
                                        prices: response.data, 
                                        showLoading: false
                                    });
                                });
                                this.queryPrice(this.state.selected_page.value);
                            });
                        });
                    }else{
                        sweetalert.fire({
                            icon: 'error', 
                            title: '', 
                            text: result.reason
                        });
                        this.setState({showLoading:false});
                    }
                }
                catch (e) {
                    console.log(e);
                    this.setState({showLoading:false});
                }
            }
        });
    }

    handleExportToCSV(){
        this.setState({showLoading: true}, async () =>{
            await NetworkUtil.downloadCSV(
                "/api/admin/pricing/csv",
                "PricingData.xlsx",
                {filter: this.state.filter}
            )
            this.setState({showLoading: false});
        });
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()
        this.setState({
            tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any, this.state.direction), 
            direction: this.state.direction === 'asc' ? 'desc' : 'asc'
        });
    }

    changePage(page:number){
        let allPrices = this.state.priceQueryResults;
        let returnData = slicePages(allPrices, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    renderPricingMgmtFilterFields(){
        return (
            <FilterCard fields={[
                {
                    label: "Price Name",
                    "key": "SelectedPrice",
                    type: FIELD_TYPE.SELECT,
                    options: this.state.prices,
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    isFilter: true
                }, 
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    getPriceDetails(val){
        this.setState({showLoading: true}, async () => {
            try {
                let result = await PricingAPI.queryPricing({ID: val.ID});

                if (result.success) {
                    let price = result.price;

                    this.setState({
                        selectedPrice: {
                            ID: price.ID ? price.ID : null,
                            Name: price.Name ? price.Name : "",
                            Description: price.Description ? price.Description : "",
                            Cost: price.Cost ? price.Cost.toFixed(2) : null,
                            PricingTypeID: price.PricingTypeID ? price.PricingTypeID : null,
                        },
                        showLoading: false
                    }, () => showModalNoOutsideClick(PricingManagementModal.ID))
                }
            } catch (e) {
                console.error(e);
                this.setState({showLoading: false});
            }
        })

    }

    render() {
        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <PricingManagementModal
                selectedPrice={this.state.selectedPrice}
                prices={this.state.priceQueryResults}
                priceTypes={this.state.priceTypes}
                onSubmit={(price:Price) => {
                    hideModal(PricingManagementModal.ID);
                    this.createOrModifyPrice(price);
                }}
            />
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-12 col-lg-8 col-xl-5 pt-2">
                        <main id="main-content" tabIndex={-1} aria-label="Pricing Management">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Price Management</h4>
                                </div>
                                <div className="card-body">
                                    {this.renderPricingMgmtFilterFields()}
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline"}
                                            onClick={ () => {
                                                this.setState({
                                                    selected_page: {label: 1, value: 1}}, () => {
                                                    this.queryPrice(this.state.selected_page.value)
                                                })
                                            }}
                                    >Search</button>
                                    <button className="btn btn-outline-success float-right"
                                            onClick={()=> this.setState({
                                                selectedPrice: {
                                                    ID: null,
                                                    Name: '',
                                                    Description: '',
                                                    Cost: null,
                                                    PricingTypeID: null
                                                } as Price
                                            },()=>{
                                                showModalNoOutsideClick(PricingManagementModal.ID)
                                            })}
                                    >Create New</button>
                                </div>
                            </div>
                        </main>
                    </div>
                    
                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-md-12 pt-2">
                            <div className="card mt-2 mb-5">
                                <div className="card-header verlag-bold">
                                    <h4 className="text-center text-md-left">Pricing
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                        <h4 className={'float-md-right'} aria-label="Total Records" role="alert">Total: {this.state.priceQueryResults.length}</h4>
                                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                        <div className=" align-middle float-right pages ">
                                            <Select
                                                isSearchable={true}
                                                placeholder={"1"}
                                                noOptionsMessage={() => "No option"}
                                                value={this.state.selected_page}
                                                aria-label="Table Page Number"
                                                onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                    () => this.changePage(e.value))}
                                                className={"state_select"}
                                                options={this.state.page_options}
                                            />
                                        </div>
                                    </section>
                                    <button className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                            onClick={() => this.handleExportToCSV()}
                                    >Export to CSV
                                    </button>
                                    </h4>
                                </div>
                                <div className="card-body p-0 m-0 table-responsive">
                                        <SimpleTable table_style='tableFixHead' columns={[
                                            {
                                                label: "Name", key: "Name",
                                                rawFormat: (val) => {
                                                    return <a href={"#"} className={'tableNameLinkColor'} onClick={() => {
                                                        this.getPriceDetails(val)
                                                    }}>{val.Name}</a>
                                                },
                                                popoverText: "Click to sort by Name"
                                            },
                                            {
                                                label: "Description", key: "Description",
                                                popoverText: "Click to sort by Description"
                                            },
                                            {
                                                label: "Cost", key: "Cost",
                                                rawFormat: (val) => {
                                                  return <>${val.Cost}</>
                                                },
                                                popoverText: "Click to sort by Cost",
                                            },
                                            {   label: "Type", key: "PricingTypeID",
                                                rawFormat: (val) => {
                                                  let type =  val.PricingTypeID ? getLabel(val.PricingTypeID, this.state.priceTypes) : {label: "", value: null}
                                                  return <>{type?.label}</>
                                                },
                                                popoverText: "Click to sort by Type"
                                            }
                                        ]}
                                        table_data={this.state.tableData}
                                        columnClickedCallback={(col => {
                                            this.useSorter(col);
                                        })} 
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>)
    }
}