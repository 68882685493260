import React from "react";
import Select from "react-select";
import { buildRow, getBoolSelectVal, hideModal, getBoolSelectOptions } from "../../util/FormatUtil"
import { sweetalert } from "../../App";
import Validator from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import { isEmptyObject } from "jquery";
import Discount from "../../types/Discount";

interface DiscountManagementModalState{
    selectedDiscount:Discount
}

interface DiscountManagementModalProps{
    selectedDiscount:Discount
    discounts?:Discount[]
    onSubmit?:(discount:Discount)=>void
}
export class DiscountManagementModal extends React.Component<DiscountManagementModalProps,DiscountManagementModalState>{
    public static ID = "discountmanagement"
    constructor(props){
        super(props);
        this.state = {
            selectedDiscount: {
                ID: null,
                Name: "",
                DiscountCode: "",
                DiscountAmount: 0,
                CreatedBy: "",
                CreatedDate: null,
                UpdatedBy: "",
                UpdatedDate:null,
                IsEnabled: null
            } as Discount 
        }
        this.clearFields = this.clearFields.bind(this);
    }

    componentDidMount(): void {
        $(`#${DiscountManagementModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }
    componentWillReceiveProps(nextProps:Readonly<DiscountManagementModalProps>,nextContext:any){
        if(!isEmptyObject(nextProps.selectedDiscount)){
            let discount = nextProps.selectedDiscount;
            this.setState({
                selectedDiscount: {
                    ID: discount.ID ? discount.ID : null,
                    Name: discount.Name ? discount.Name : "",
                    DiscountCode: discount.DiscountCode ? discount.DiscountCode : "",
                    DiscountAmount: discount.DiscountAmount ? discount.DiscountAmount : 0,
                    CreatedBy: discount.CreatedBy ? discount.CreatedBy : "",
                    CreatedDate : discount.CreatedDate ? discount.CreatedDate : new Date(),
                    UpdatedBy: discount.UpdatedBy ? discount.UpdatedBy : "",
                    UpdatedDate: discount.UpdatedDate ? discount.UpdatedDate : null,
                    IsEnabled: discount.IsEnabled !== null ? getBoolSelectVal(discount.IsEnabled) : null,
                } as Discount
            })
        }
    }

    clearFields(){
        this.setState({
            selectedDiscount: {
                ID: null,
                Name: "",
                DiscountCode: "",
                DiscountAmount: 0,
                CreatedBy: "",
                CreatedDate: null,
                UpdatedBy: "",
                UpdatedDate:null,
                IsEnabled:null
            } as Discount
        })
    }

    render(){
        console.log(" selected", this.state.selectedDiscount)
        return(
            <div className="modal fade form_modal" 
                id={DiscountManagementModal.ID} tabIndex={-1} role="dialog"
                aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="container-fluid">
                            <div className="modal-header">
                                <h5 className="modal-title" id="result_modal_label">Discount Management</h5>
                                <button  style={{outline: 'none'}} type="button" 
                                    onClick={() => this.clearFields()} 
                                    className="close" 
                                    data-dismiss="modal" 
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body m-3">
                                {buildRow("Name",
                                    <input 
                                        id="Name"
                                        className="form-control"
                                        maxLength={500}
                                        autoComplete="off"
                                        type={"search"}
                                        name={"Name"}
                                        aria-label="Name"
                                        onChange={(e) => {
                                            this.setState((prevState) => ({
                                                selectedDiscount: {
                                                    ...prevState.selectedDiscount,
                                                    Name: e.target.value
                                                }
                                            }))
                                        }}
                                        value={this.state.selectedDiscount.Name}
                                    />)}
                                {buildRow("Discount Code",
                                    <input 
                                        id="DiscountCode"
                                        className="form-control"
                                        maxLength={10}
                                        autoComplete="off"
                                        type={"search"}
                                        name={"DiscountCode"}
                                        aria-label="Discount Code"
                                        onChange={(e) => {
                                            this.setState((prevState) => ({
                                                selectedDiscount: {
                                                    ...prevState.selectedDiscount,
                                                    DiscountCode: e.target.value
                                                }
                                            }))
                                        }}
                                        value={this.state.selectedDiscount.DiscountCode}
                                    />)}
                                {buildRow("Discount Amount",
                                    <div className="form-group input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text text-dark"
                                                style={{ "paddingLeft": "17.5px", "paddingRight": "17.5px" }}
                                            >$</span>
                                        </div>
                                        <input 
                                            id="DiscountAmount"
                                            className="form-control"
                                            min={"0"}
                                            autoComplete="off"
                                            aria-label="Discount Amount"
                                            type={"number"}
                                            name={"Amount"}
                                            onChange={(e) => {
                                                this.setState((prevState) => ({
                                                    selectedDiscount: {
                                                        ...prevState.selectedDiscount,
                                                        DiscountAmount: e.target.value
                                                    }
                                                }))
                                            }}
                                            value={this.state.selectedDiscount.DiscountAmount}
                                        />
                                    </div>)}
                                {buildRow("Is Enabled?",
                                    <Select
                                        aria-label="Is Enabled"
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        value={this.state.selectedDiscount.IsEnabled}
                                        onChange={(e) => this.setState((prevState) => ({
                                            selectedDiscount: {
                                                ...prevState.selectedDiscount,
                                                IsEnabled: e
                                            }
                                        }))}
                                        className={"state_select"}
                                        options={[{label: "Yes", value: 1}, {label: "No", value: 0}]}
                                    />
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" 
                                    onClick={() => {
                                        let formValidation = {
                                            Name: this.state.selectedDiscount.Name,
                                            DiscountCode: this.state.selectedDiscount.DiscountCode,
                                            Amount: this.state.selectedDiscount.DiscountAmount,
                                            IsEnabled: this.state.selectedDiscount.IsEnabled
                                        }

                                        let validator = new Validator<any>()
                                            .withValidation("Name", Validators.requireNotBlankAndLengthAndUnique(50, this.props.discounts.filter(d=>d.ID !== this.state.selectedDiscount.ID).map(d => d.Name), "Discount Name"))
                                            .withValidation("DiscountCode", Validators.requireNotBlankAndLengthAndUnique(10, this.props.discounts.filter(d=> d.ID !== this.state.selectedDiscount.ID).map(d => d.DiscountCode), "Discount Code"))
                                            .withComposedValidation("Amount", Validators.requirePositveNumberWithTwoDecimalPlaces("Discount Amount"))
                                            .withSimpleValidation("IsEnabled", Validators.requireNonNullValidator("Is Enabled"))

                                        let validationResponse = validator.validate(formValidation);

                                        if(!validationResponse.success ) {
                                            return sweetalert.fire({
                                                icon: 'error',
                                                title: '',
                                                text: validationResponse.error
                                            });
                                        }
                                        hideModal(DiscountManagementModal.ID)
                                        this.props.onSubmit({
                                            ID: this.state.selectedDiscount.ID,
                                            Name: this.state.selectedDiscount.Name,
                                            DiscountCode:this.state.selectedDiscount.DiscountCode, 
                                            DiscountAmount: this.state.selectedDiscount.DiscountAmount,
                                            CreatedBy: this.state.selectedDiscount.CreatedBy,
                                            CreatedDate: this.state.selectedDiscount.CreatedDate,
                                            UpdatedBy: this.state.selectedDiscount.UpdatedBy, 
                                            UpdatedDate: this.state.selectedDiscount.UpdatedDate,
                                            IsEnabled: this.state.selectedDiscount.IsEnabled ? this.state.selectedDiscount.IsEnabled.value : null,
                                        } as Discount );
                                        this.setState({
                                            selectedDiscount:{
                                                ID: null,
                                                Name: "",
                                                DiscountCode: "",
                                                DiscountAmount: null,
                                                CreatedBy: "",
                                                CreatedDate: null,
                                                UpdatedBy: "",
                                                UpdatedDate: null,
                                                IsEnabled: null
                                            }
                                        });
                                    }}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}