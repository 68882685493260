import React, { useState } from 'react'

const CustomTextArea = (props) => {

  return (
    <div className="form-group row">
    <div
      className={"col-12 col-md-4"}
      data-toggle={"tooltip"}
      data-placement={"top"}
      title={props.tooltip}
    >
      <label
        id={props.label}
        htmlFor={props.label}
        style={{
          fontWeight: "bold",
          paddingTop: "0.5em",
          fontSize: "0.8em",
        }}
      >
        {props.label}
      </label>
    </div>
    <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={props.label}>
      <div className="row">
        <div className={"col-12"}>
            <textarea
                id={props.label}
                rows={3}
                maxLength={props.maxLength ? props.maxLength : 5000}
                readOnly={props.readOnly ? props.readOnly : false}
                disabled={props.disabled ? props.disabled : false}
                className={"form-control"}
                aria-labelledby={props.label}
                value={props.value}
                autoComplete="off"
                onChange={(e) => {
                props.handleChange(e)
                }}
            />
        </div>
      </div>
      <div className="row pt-1 pr-3 justify-content-end">
      <div className={props.value?.length > 0 ? "visible" : "invisible"}>
            <div style={{ fontSize: "0.8em" }}>
                {props.value?.length}/{props.maxLength ? props.maxLength : 5000}
            </div>
            </div>
        </div>
    </div>
  </div>
  )
}

export default CustomTextArea