import React from "react"
import FilterCard, {FIELD_TYPE, VALIDATOR_TYPE} from "../FilterCard";
import {getToken} from "../../util/CaptchaUtil";
import Overlay from "../Overlay";
import {sweetalert} from "../../App";
import SubmissionAPI from "../../network/SubmissionAPI";
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import { getTwelveHourAMPMTime, hideModal, parseDate } from "../../util/FormatUtil";

interface CancelAppointmentState{
    showLoading:boolean,
    token:string,
    ReqNum:string,
    LastName:string
}

class CancelAppointment extends React.Component<{}, CancelAppointmentState>{
    // private filterRef: React.RefObject<FilterCard>;

    constructor(props) {
        super(props);
        this.state = {ReqNum: "", showLoading: false, token: "", LastName:""};
        this.onSubmit = this.onSubmit.bind(this);
        this.assignClearState = this.assignClearState.bind(this);
        // this.filterRef = React.createRef();
    }

    public static ID = "cancel_appointment"

    clearFilterState:() => void = null;

    assignClearState(func){
        this.clearFilterState = func;
    }

    clearState = () =>{
        this.clearFilterState();
    }

    componentDidMount(): void {
        $(`#${CancelAppointment.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    async onSubmit(e){
        let formValidation = {
            ReqNum: this.state.ReqNum,
            LastName: this.state.LastName
        }

        let validator = new Validator<any>()
            .withSimpleValidation("ReqNum", Validators.requireNotBlankValidator("Confirmation Code"))
            .withSimpleValidation("LastName", Validators.requireNotBlankValidator("Last Name"))
        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }
        let token = await getToken();
        let body = JSON.parse(JSON.stringify(this.state));
        body.token = token;
        this.setState({showLoading:true})

        try{
            let result = await SubmissionAPI.getApptInfoForCancel(body)

            if(result.apptInfo.length < 1){
                sweetalert.fire({icon: 'error', title: '', text: 'We could not find a record matching this information.'});
                this.setState({showLoading: false})
                return
            }
            sweetalert.fire({
                title: 'Confirm cancellation',
                html: `<div class='row'>  
                        <div class='col-6 pl-5 font-weight-bold text-left'>First Name: </div>
                        <div class='col-6 text-left'> ${result.apptInfo[0].PatientFirstName}</div>
                       </div>
                       <div class='row'>
                        <div class='col-6 pl-5 font-weight-bold text-left'>Last Name: </div>
                        <div class='col-6 text-left'> ${result.apptInfo[0].PatientLastName}</div>
                       </div>
                       <div class='row'>
                        <div class='col-6 pl-5 font-weight-bold text-left'>Date of Birth: </div>
                        <div class='col-6 text-left'> ${parseDate(result.apptInfo[0].PatientDOB)}</div>
                       </div>
                       <div class='row'>
                        <div class='col-6 pl-5 font-weight-bold text-left'>Email: </div>
                        <div class='col-6 text-left'> ${result.apptInfo[0].PatientEmail}</div>
                       </div>
                       <div class='row'>
                        <div class='col-6 pl-5 font-weight-bold text-left'>Phone #: </div>
                        <div class='col-6 text-left'> ${result.apptInfo[0].PatientPhone}</div>
                       </div>
                       <div class='row'>
                        <div class='col-6 pl-5 font-weight-bold text-left'>Appt Date: </div>
                        <div class='col-6 text-left'> ${parseDate(result.apptInfo[0].TestDate)}</div>
                       </div>
                       <div class='row'> 
                        <div class='col-6 pl-5 font-weight-bold text-left'>Appt Time: </div>
                        <div class='col-6 text-left'> ${getTwelveHourAMPMTime(result.apptInfo[0].ApptTime)}</div> 
                       </div>`,
                showCloseButton: false,
                showDenyButton: true,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'Confirm Cancel',
                denyButtonText: `Go Back`,
            }).then(async(result) => {
                if (result.isConfirmed){
                    try {
                        let newToken = await getToken();
                        body.token = newToken;
                        let result = await SubmissionAPI.appointmentCancel(body)
                        if (result.success) {
                            sweetalert.fire({icon: 'success', title: '', html: `
                                <p>We have successfully canceled your appointment for <b>Confirmation# ${this.state.ReqNum}</b>.</p>
                                <p><b>NOTE:</b> If you have other Confirmation#'s under this Appointment#, they are <b>NOT</b> canceled. They must be canceled individually.</p>`});
                            this.clearState()
                            this.setState({ReqNum:"", showLoading:false})
                            hideModal(CancelAppointment.ID)
                        }else{
                            sweetalert.fire({icon: 'error', title: '', text: result.reason});
                            this.setState({showLoading: false})
                        }
                    }
                    catch (e) {
                        console.error(e)
                        sweetalert.fire({icon: 'error', title: '', text: "We cannot cancel your appointment at this time"});
                        this.setState({showLoading:false})
                    }
                }
                else if(result.isDenied) {
                    this.setState({showLoading: false})
                   return
                }
            })
        }
        catch (e) {
            console.error(e)
            sweetalert.fire({icon: 'error', title: '', text: "We cannot cancel your appointment at this time"});
            this.setState({showLoading:false})
        }
    }


    render() {
        return (
            <React.Fragment>

            <div className="modal fade form_modal" id={CancelAppointment.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <Overlay show_loading={this.state.showLoading}/>
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Cancel Appointment</h5>
                            <button style={{outline: 'none'}}  type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mr-3">
                           <p>We will send an email confirming your appointment has been cancelled.
                               If you would like to schedule for a different time, please cancel your appointment and sign up again for one of the remaining times. <b>Note: We cannot promise another spot will be available if you cancel.</b>
                           </p>
                            <FilterCard passClearStateFunc={this.assignClearState} fields={[
                                {label:"Confirmation Code", key:"ReqNum", type:FIELD_TYPE.TEXT, onInput:(e) => e.target.value = e.target.value.toUpperCase(), required:true, validator: VALIDATOR_TYPE.NotBlankAndLength},
                                {label:"Last Name", key:"LastName", type:FIELD_TYPE.TEXT, required:true, validator: VALIDATOR_TYPE.NotBlankAndLength}
                                ]}
                                        filterChanged={(e) => this.setState(e)}/>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() =>
                                // @ts-ignore
                                window.$("#" + CancelAppointment.ID).modal('hide')}>Go Back</button>
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

export default CancelAppointment;