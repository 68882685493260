import React from "react";
import Select from "react-select";
import Overlay from "../Overlay";
import {
    getBoolSelectOptions,
    getBoolSelectVal, getNumPages, getPageOptions, hideModal,
    parseDate,
    showModalNoOutsideClick,
    slicePages,
    getSelectLabels,
    prepareMultiSelect,
    getNameFromID,
    toLocalDateTime,
    buildRow,
    isEighteenOrOlder,
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import { isEmptyObject } from "jquery";
import { Sample } from "../../types/Sample";
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";
import moment from "moment";
import {AdminPages} from "./AdminNav";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";
import { Patient } from "../../types/Patient";
import PatientAPI from "../../network/PatientAPI";
import { PatientManagementModal } from "../modals/PatientManagementModal";
import { PatientInfoModal } from "../modals/PatientInfoModal";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import SamplesAPI from "../../network/SamplesAPI";
import { PodSchedForSelect } from "../../types/PodSched";
import {CustomFields, CustomFieldType, FieldOptions} from "../../types/CustomFieldType";
import ServicesAPI from "../../network/ServicesAPI";
import ViewTestInfoModal from "../modals/ViewTestInfoModal";
import TestsAPI from "../../network/TestsAPI";
import BatchesAPI from "../../network/BatchesAPI";
import { BinAPI } from "../../network/BinAPI";
import { standardPatientFields } from "../../types/CustomFieldType";
import { PatientRecordModal } from "../modals/PatientRecordModal";
import GetField from "../../customfields/GetField";
import PaginationTool from "../PaginationTool";

const ITEMS_PER_PAGE = 25;

interface PatientRecordState {
    patientInformation?;
    patients: Patient[]
    showLoading?
    showInBetween?
    filter?:{
        facility?
        testDate?
        test?
        reqNum?
        service?
        ResultIDs?
    }
    tableData?
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    patientQueryResults:Patient[]
    race
    gender
    ethnicity
    states
    countries
    facilities
    customPatientFields?
    portalPatients
    tests
    queryResults?
    services?
    getResultsButtonEnabled?
    selectedSample?
    patient?
    specimen_source?
    isGlobalAdminorLab?
    isAdmin?
    isGlobal?
    validSpecimenSources?
    batchesForModal?
    binIDsForModal?
    testDetails?
    resultTypes?
    validResultTypes?
    facilityGroups?
    facilitiesForSampleModal?
    podsForModal?
    customFields?
    customQuestions?
    patientInfo?
    searched?
    totalSamples?

}

export class PatientRecord extends React.Component<any, PatientRecordState> {
    private patientInfoRef: any;
    private componentRef: any;
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            patients: [],
            patientInformation: {} as Patient,
            filter: {
              facility:null,
              testDate:null,
              test:null,
              reqNum: null,
              service: null
            },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            patientQueryResults: [] as any,
            race: [],
            gender: [],
            ethnicity: [],
            states: [],
            countries: [],
            facilities: [],
            customPatientFields: [],
            portalPatients: [],
            tests: [],
            services: []
        }
        // this.modifyPatient = this.modifyPatient.bind(this);
        this.patientInfoRef = React.createRef();
    }

    getFilteredObj(){
      return {
        PatientPortalUID: this.props.user,
        PodID: null,
        Facilities: this.state.filter?.facility ? this.state.filter.facility : null,
        FacilityGroup: null,
        PatientFirstName: null,
        PatientMiddleName: null,
        PatientLastName: null,
        PatientDOB: null,
        NullPatientDOB: null,
        PatientEthnicityID: null,
        PatientRaceID: null,
        PatientGenderID: null,
        PatientEmail: null,    
        PatientPhone: null,
        NullPatientPhone: null,
        PatientCity: null,
        PatientZip: null,
        PatientState: null,
        PatientCounty: null,
        PatientCountry: null,
        LinkedApptsID: null,
        ReqNum: this.state.filter?.reqNum ? this.state.filter?.reqNum : null,
        BatchNum: null,
        SpecimenID: null,
        InternalSpecimenID: null,
        BinID: null,
        Lot: null,    
        BeginCreatedDate: null,
        EndCreatedDate: null,
        NullCreatedDate: null,    
        BeginCollectionDate: this.state.filter.testDate ? this.state.filter.testDate : null,
        EndCollectionDate: this.state.filter.testDate ? this.state.filter.testDate : null,
        NullCollectionDate: null,    
        BeginReceivedDate: null,
        EndReceivedDate: null,
        NullReceivedDate: null,    
        BeginResultDate: null,
        EndResultDate: null,          
        NullResultDate: null,    
        BeginResultsApprovedDate: null,
        EndResultsApprovedDate: null,
        NullResultsApprovedDate: null,
        BeginResultsAccessedDate: null,
        EndResultsAccessedDate: null,
        NullResultsAccessedDate: null,    
        BeginReportedDate: null,
        EndReportedDate: null,
        NullReportedDate: null,    
        ResultIDs: this.state.filter.ResultIDs ? this.state.filter.ResultIDs : null,
        SpecimenSourceID: null,
        TestIDs: this.state.filter?.test ? prepareMultiSelect(this.state.filter.test) : null,
        ServiceID: this.state.filter?.service ? this.state.filter.service : null,    
        //passing empty array if greater than 1 because if both 1 and null are selected then that is all the records.  same for ApptCanceled
        RerunFlag: null,
        ApptCanceled: null,
        ProviderFirstName: null,
        ProviderLastName: null,
      };
    }


    async componentDidMount() {
        document.title = 'Patient Management Page';
        this.setState({showLoading: true}, () => {
            AdminAPI.getPermissions().then((data) => {
              this.setState({
                isGlobalAdminorLab: data.isGlobalAdminorLab,
                isGlobal: data.isGlobal,
                isAdmin: data.isAdmin,
              });
            });
            SystemAPI.getAllRaces().then((data) => {
                this.setState({ race: data });
            });
            SystemAPI.getAllGenders().then((data) => {
                this.setState({ gender: data });
            });
            SystemAPI.getAllEthnicities().then((data) => {
                this.setState({ ethnicity: data });
            });
            
            SystemAPI.getAllStates().then((data) => {
                this.setState({ states: data });
            });
            TestsAPI.getAllTestDetails().then((data) => {
              this.setState({ testDetails: data.tests });
            });
            SystemAPI.getAllTests().then((data) => {
                this.setState({ tests: data });
            });
            AdminAPI.getFacilitiesForSubmissionForm().then((data) => {
                this.setState({facilities: data.authorizedFacilities});
            });
            TestsAPI.getValidSpecimenSources().then((data) => {
              this.setState({ validSpecimenSources: data });
            });
            ServicesAPI.getAllServices().then((data) => {
              this.setState({services: data.data})
            })
            CustomFieldsAPI.getAllCustomFields().then((data) => {
              this.setState({
                customFields: data.data.customFields.filter(f => f.Options.validPages.includes("Submission Form") || f.Options.validPages.includes("Submission")  || f.Options.validPages.includes(AdminPages.Samples)),
                customQuestions: data.data.customQuestions,
                customPatientFields: data.data.customPatientFields.sort((a,b) => a.Order - b.Order)
              });
            });
            BinAPI.getBinsForSamples().then((data) => {
              this.setState({ binIDsForModal: data });
            });
            AdminAPI.getAllPods(true).then((response) => {
              const today = moment().startOf('day');
              let pods = response.data as {label: string; value: PodSchedForSelect}[];
      
              const beforeToday = pods.filter((pods)=>{
                let aDate = moment(pods.value.TestDate, 'YYYY-MM-DD').startOf('day');
                return aDate.isBefore(today)
              }).reverse();
      
              const afterToday = pods.filter((pods)=>{
                let bDate = moment(pods.value.TestDate, 'YYYY-MM-DD').startOf('day');
                return bDate.isSameOrAfter(today)
              })
              let groupedPods = [
                { label: "Upcoming", options: afterToday},
                { label: "Past", options: beforeToday}
              ]
              this.setState({ podsForModal: groupedPods });
            });
            AdminAPI.getFacilitiesForSubmissionForm().then((data) => {
              this.setState({facilities: data.authorizedFacilities});
          });
            SystemAPI.getAllCountries().then((data) => {
                this.setState({ countries: data});
            });
            CustomFieldsAPI.getAllCustomFields().then(data =>{
                this.setState({
                    customPatientFields: data.data.customPatientFields,
                  });
            })
            SystemAPI.getAllSpecimenSources().then((data) => {
              this.setState({ specimen_source: data });
            });
            PatientAPI.filterPatients({uid: this.props.user}).then(async data => {
              this.setState({patient: data.data[0]}, () => {
                PatientAPI.getPatientsFromPatientPortal().then(data => {
                  this.setState({portalPatients: data.patients}, () => {
                    if(!isEmptyObject(this.state.patient) && this.props.fromPatientMgmt){
                      this.updateRows({label: this.state.patient, value: this.state.patient.id})
                     } 
                  })
                })
                
              })
            })
            TestsAPI.getValidResultTypes().then((data) => {
              this.setState({ validResultTypes: data });
            });
            AdminAPI.getAllResultTypes().then((data) => {
              this.setState({ resultTypes: this.addNullSelection(data) });
            });
            BatchesAPI.getBatchesForSamples().then((data) => {
              this.setState({ batchesForModal: data });
            });
            SamplesAPI.getFilteredSamples(1, {filter: this.getFilteredObj()}).then(data =>{
              
              this.setState({
                totalSamples: data.totalSamples,
                tableData: slicePages(data.table_data, 1, ITEMS_PER_PAGE),
                showLoading: false,
                queryResults: data.table_data,
                searched: this.getFilteredObj(),
                page_options: getPageOptions(getNumPages(data.table_data, ITEMS_PER_PAGE)),
              })
            })
            
            // this.queryPatients(1)
        })
    }

    // componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
    //   if(!isEmptyObject(nextProps.selectedPatient) && nextProps.fromPatientMgmt){
    //     this.updateRows({label: nextProps.selectedPatient, value: nextProps.selectedPatient.id})
    //   } 
    // }

    // async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<PatientRecordState>, snapshot?: any){
    //   if(!isEmptyObject(this.state.patient) && this.props.fromPatientMgmt && prevState.portalPatients != this.state.portalPatients){
    //     await this.updateRows({label: this.state.patient, value: this.state.patient.id})
    //    } 
    // }

    queryPatients(page:number) {
        this.setState({showLoading:true}, async () => {
            try {
                let results = await PatientAPI.filterPatients(this.state.filter);
                if (results.data.length === 0) {
                    sweetalert.fire({
                      icon: "info",
                      title: "",
                      text: "No patients were returned",
                    });
                  }

                if(!results.success){
                    return sweetalert.fire({title: '', text: 'Unable to filter Patient data at this time', icon: 'error'})
                }
                this.setState({
                    tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
                    patientQueryResults: results.data,
                    page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
                    showLoading: false,
                    portalPatients: results.data
                })
            } catch (e) {
                console.log(e)
                this.setState({showLoading: false})
            }
        })
    }

    addNullSelection(data) {
      return data.concat([
        { label: "No Data", value: "*null", color: '#C31230', fontWeight: 'bold' },
        { label: "Has Data", value: "<>null", color: '#21212E', fontWeight: 'bold' },
      ]);
    }
  

    modifyPatient(patient){
        if(!patient || JSON.stringify(patient) === '{}' || !patient.patientInfo || JSON.stringify(patient.patientInfo) === '{}' || !patient.patientInfo.CustomFieldData || JSON.stringify(patient.patientInfo.CustomFieldData) === '{}'){
            return sweetalert.fire({icon: 'error', title: '', text: `Patient Information must be complete`})
        }

        let patientInformationCopy = JSON.parse(JSON.stringify(patient.patientInfo)); 

        patientInformationCopy.uid = patientInformationCopy.CustomFieldData.uid ? patientInformationCopy.CustomFieldData.uid : null;
        patientInformationCopy.firstName = patientInformationCopy.CustomFieldData['First Name'] ? patientInformationCopy.CustomFieldData['First Name'] : null;
        patientInformationCopy.middleName = patientInformationCopy.CustomFieldData['Middle Name'] ? patientInformationCopy.CustomFieldData['Middle Name'] : null;
        patientInformationCopy.lastName = patientInformationCopy.CustomFieldData['Last Name'] ? patientInformationCopy.CustomFieldData['Last Name'] : null;
        patientInformationCopy.email = patientInformationCopy.CustomFieldData.Email ? patientInformationCopy.CustomFieldData.Email : null;
        patientInformationCopy.phoneNumber = patientInformationCopy.CustomFieldData.Phone ? patientInformationCopy.CustomFieldData.Phone : null;
        patientInformationCopy.dateOfBirth = patientInformationCopy.CustomFieldData['Date of Birth'] ? patientInformationCopy.CustomFieldData['Date of Birth'] : null;
        patientInformationCopy.streetAddress = patientInformationCopy.CustomFieldData.Address ? patientInformationCopy.CustomFieldData.Address : null;
        patientInformationCopy.streetAddress2 = patientInformationCopy.CustomFieldData['Address Cont.'] ? patientInformationCopy.CustomFieldData['Address Cont.'] : null;
        patientInformationCopy.city = patientInformationCopy.CustomFieldData.City ? patientInformationCopy.CustomFieldData.City : null;
        patientInformationCopy.state = patientInformationCopy.CustomFieldData.State ? this.state.states.find(f => f.label === patientInformationCopy.CustomFieldData.State).value : null;
        patientInformationCopy.county = patientInformationCopy.CustomFieldData.County ? patientInformationCopy.CustomFieldData.County : null;
        patientInformationCopy.zipcode = patientInformationCopy.CustomFieldData.Zipcode ? patientInformationCopy.CustomFieldData.Zipcode : null;
        patientInformationCopy.country = patientInformationCopy.CustomFieldData.Country ? this.state.countries.find(f => f.label === patientInformationCopy.CustomFieldData.Country).value : null;
        patientInformationCopy.genderID = patientInformationCopy.CustomFieldData.Gender ? this.state.gender.find(f => f.label === patientInformationCopy.CustomFieldData.Gender).value : null;
        patientInformationCopy.ethnicityID = patientInformationCopy.CustomFieldData.Ethnicity ? this.state.ethnicity.find(f => f.label === patientInformationCopy.CustomFieldData.Ethnicity).value : null;
        patientInformationCopy.raceID = patientInformationCopy.CustomFieldData.Race ? this.state.race.find(f => f.label === patientInformationCopy.CustomFieldData.Race).value : null;
        patientInformationCopy.guardianFirstName = patientInformationCopy.CustomFieldData['Guardian First Name'] ? patientInformationCopy.CustomFieldData['Guardian First Name'] : null;
        patientInformationCopy.guardianLastName = patientInformationCopy.CustomFieldData['Guardian Last Name'] ? patientInformationCopy.CustomFieldData['Guardian Last Name'] : null;
        patientInformationCopy.FacilityIDs = patientInformationCopy.CustomFieldData.FacilityIDs ? patientInformationCopy.CustomFieldData.FacilityIDs : null;

        delete patientInformationCopy.CustomFieldData.Address;
        delete patientInformationCopy.CustomFieldData.City;
        delete patientInformationCopy.CustomFieldData.Country;
        delete patientInformationCopy.CustomFieldData.County;
        delete patientInformationCopy.CustomFieldData['Date of Birth'];
        delete patientInformationCopy.CustomFieldData.Email;
        delete patientInformationCopy.CustomFieldData.Ethnicity;
        delete patientInformationCopy.CustomFieldData['First Name'];
        delete patientInformationCopy.CustomFieldData.Gender;
        delete patientInformationCopy.CustomFieldData['Last Name'];
        delete patientInformationCopy.CustomFieldData['Middle Name'];
        delete patientInformationCopy.CustomFieldData.Phone;
        delete patientInformationCopy.CustomFieldData.Race;
        delete patientInformationCopy.CustomFieldData.State;
        delete patientInformationCopy.CustomFieldData.Zipcode;
        delete patientInformationCopy.CustomFieldData.uid;
        delete patientInformationCopy.CustomFieldData['Guardian First Name'];
        delete patientInformationCopy.CustomFieldData['Guardian Last Name'];
        delete patientInformationCopy.CustomFieldData.FacilityIDs;
      
        this.setState({showLoading:true}, async() => {
            try{
                let result = await PatientAPI.editPatient(patientInformationCopy)
                if(result.success){
                    sweetalert.fire({icon: 'success', title: '', text: 'Patient saved'}).then(()=>{
                        window.location.reload()
                    });
                }else{
                    sweetalert.fire({icon: 'error', title: '', text: result.reason});
                    this.setState({showLoading:false})
                }
            }
            catch (e) {
                console.log(e)
                this.setState({showLoading:false})
            }
        })
    }

    handleExportToCSV(){
      let filter = this.state.searched;
      let modifiedfilter = Object.assign(filter);
        this.setState({showLoading: true}, async () =>{
            await NetworkUtil.downloadCSV(
                "/api/admin/samples/results/csv",
                "Sample Results.xlsx",
                {filter: modifiedfilter}
            )
            this.setState({showLoading: false})
        });
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()

        this.setState({tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
    }

    changePage(page:number){
        let allRecords = this.state.queryResults;
        let returnData = slicePages(allRecords, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    async updateRows(e){
      if(!e){
          this.setState({patientInfo: {}, patient: null})
          return;
      }
      this.setState({patient: e, patientInfo: {}})
      let patientPortalInfo = e ? await this.state.portalPatients?.find(p => p.id === e.value) : null;
      let tempObj = {};
      let over18 = true;

      // Define a mapping configuration object
      const fieldMapping = {
          firstName: 'First Name',
          lastName: 'Last Name',
          dateOfBirth: 'Date of Birth',
          middleName: 'Middle Name',
          email: 'Email',
          streetAddress: 'Address',
          streetAddress2: 'Address Cont.',
          city: 'City',
          state: 'State',
          zipcode: 'Zipcode',
          country: 'Country',
          county: 'County',
          ethnicityID: 'Ethnicity',
          genderID: 'Gender',
          raceID: 'Race',
          guardianFirstName: 'Guardian First Name',
          guardianLastName: 'Guardian Last Name',
          phoneNumber: 'Phone',
          FacilityIDs: 'FacilityIDs'
      };

      const otherKeys = ['uid']

      try {
        if(patientPortalInfo){
          for (const key of Object.keys(patientPortalInfo)) {
              if(this.props.fromPatientMgmt && key === 'FacilityIDs'){
                  tempObj[key] =  JSON.parse(patientPortalInfo[key])
              }
              if(otherKeys.includes(key)){
                  tempObj[key] = patientPortalInfo[key];
                  continue;
              }
              if (key === 'firstName' || key === 'lastName') {
                  tempObj[fieldMapping[key]] = patientPortalInfo[key];
                  continue;
              }
              if (key === 'email') {
                tempObj[fieldMapping[key]] = patientPortalInfo[key];
                continue;
              }
              if (key === 'phoneNumber') {
                tempObj[fieldMapping[key]] = patientPortalInfo[key];
                continue;
              }
              if (key === 'dateOfBirth') {
                  tempObj[fieldMapping[key]] = patientPortalInfo[key];
                  over18 = isEighteenOrOlder(patientPortalInfo[key])
                  continue;
              }
              if(key !== 'firstName' && key !== 'lastName' && key !== 'dateOfBirth' && !otherKeys.includes(key)){
                  if(key.toLowerCase().includes('guardian') && over18){
                      continue;
                  }
                  let matchingElement = this.state.customPatientFields.find(f => f.DisplayName === fieldMapping[key]);
                  if(matchingElement && !isEmptyObject(matchingElement) && matchingElement.Options.isActive){
                      if(key === 'ethnicityID'){
                          tempObj[fieldMapping[key]] = patientPortalInfo[key] ? this.state.ethnicity.find(f => f.value === patientPortalInfo[key]).label : null;
                          continue;
                      }
                      if(key === 'raceID'){
                          tempObj[fieldMapping[key]] = patientPortalInfo[key] ? this.state.race.find(f => f.value === patientPortalInfo[key]).label : null;
                          continue;
                      }
                      if(key === 'genderID'){
                          tempObj[fieldMapping[key]] = patientPortalInfo[key] ? this.state.gender.find(f => f.value === patientPortalInfo[key]).label : null;
                          continue;
                      }
                      if(key === 'state'){
                          tempObj[fieldMapping[key]] = patientPortalInfo[key] ? this.state.states.find(f => f.value === patientPortalInfo[key]).label : null;
                          continue;
                      }
                      if(key === 'country'){
                          tempObj[fieldMapping[key]] = patientPortalInfo[key] ? this.state.countries.find(f => f.value === patientPortalInfo[key]).label : null;
                          continue;
                      }
                      tempObj[fieldMapping[key]] = patientPortalInfo[key];
  
                  }
              }
          }
          if(patientPortalInfo.CustomFieldData && !isEmptyObject(JSON.parse(patientPortalInfo.CustomFieldData))){
              let parsedCustomFieldData = JSON.parse(patientPortalInfo.CustomFieldData)
              for (const key of Object.keys(parsedCustomFieldData)) {
                  tempObj[key] = parsedCustomFieldData[key]
              }
          }
        }
      } catch (error) {
        console.error(error)
          return sweetalert.fire({icon: 'error', title: '', text: 'Could not update rows at this time'})
      }
      
      this.setState((prevState) => {
          const updatedPatientInfo = { ...prevState.patientInfo };
          if(isEmptyObject(updatedPatientInfo)){
              updatedPatientInfo.CustomFieldData = {};
          }
          for (const key in tempObj) {
              if (tempObj.hasOwnProperty(key)) {
                  updatedPatientInfo.CustomFieldData[key] = tempObj[key];
              }
          }
                  return {
              patientInfo: updatedPatientInfo,
          };
      })
  }

    renderPatientRecordFilterFields(){
        return (
            <FilterCard fields={[
                {
                  label: "Confirmation #",
                    "key": "reqNum",
                    type: FIELD_TYPE.TEXT,
                },
                {
                    label: "Service",
                    "key": "service",
                    type: FIELD_TYPE.SELECT,
                    options: getSelectLabels(this.state.services),
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    isFilter: true
                },
                {
                    label: "Test Date",
                    "key": "testDate",
                    type: FIELD_TYPE.DATE,
                }, 
                {
                    label: "Facility",
                    "key": "facility",
                    type: FIELD_TYPE.SELECT,
                    options: getSelectLabels(this.state.facilities),
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    isFilter: true
                },
                {
                  label: "Result",
                  "key": "ResultIDs",
                  type: FIELD_TYPE.SELECT,
                  options: this.state.resultTypes,
                  isMapped: true,
                  isMulti: true,
                  textType: 'text',
                  isFilter: true
                }, 
                // {
                //     label: "Gender",
                //     "key": "genderID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.gender,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
                // {
                //     label: "Ethnicity",
                //     "key": "ethnicityID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.ethnicity,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
                // {
                //     label: "Race",
                //     "key": "raceID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.race,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    removeStandardPatientFields(){
      let customPatientFieldCopy = this.state.customPatientFields ? JSON.parse(JSON.stringify(this.state.customPatientFields)) : [];
      customPatientFieldCopy = customPatientFieldCopy.filter(f => !standardPatientFields.includes(f.DisplayName))
      return customPatientFieldCopy;
    }

    needsPhysicianReview(selectedSample){
      //check service for EmailReportTo === 1
      let emailReportTo = this.state.services && selectedSample && selectedSample.ServiceID && (new Date('2023-05-18') < new Date(selectedSample.CreatedDate)) ? this.state.services.find(s => s.ID === selectedSample.ServiceID).EmailReportTo : null
      //check sample for HasPhysicianReviewed
      if(emailReportTo === 1){
        return true
      }
      return false
    }

    render() {

      let over18 = this.state.patient && this.state.patient.CustomFieldData && this.state.patient.CustomFieldData['Date of Birth'] ? isEighteenOrOlder(this.state.patient.CustomFieldData['Date of Birth']) : true;

        // console.log('PatientMgmt state', this.state)

        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <PaginationTool />
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <PatientRecordModal
              onSubmit={() => {}}
              isAdmin={this.state.isAdmin}
              isGlobal={this.state.isGlobal}
              getResultsButtonBoolean={this.state.getResultsButtonEnabled}
              getPhysicianReviewButtonBoolean={this.needsPhysicianReview(this.state.selectedSample)}
              selectedSample={
                this.state.selectedSample ? this.state.selectedSample : {}
              }
              states={this.state.states}
              countries={this.state.countries}
              ethnicity={this.state.ethnicity}
              race={this.state.race}
              gender={this.state.gender}
              specimen_source={this.state.specimen_source}
              validSpecimenSources={this.state.validSpecimenSources}
              batches={this.state.batchesForModal}
              binIDs={this.state.binIDsForModal}
              testDetails={this.state.testDetails}
              tests={this.state.tests}
              result_types={this.state.resultTypes}
              validResultTypes={this.state.validResultTypes}
              facilities={this.state.facilitiesForSampleModal}
              pods={this.state.podsForModal}
              customFields={this.state.customFields}
              customQuestions={this.state.customQuestions}
              services={this.state.services}
              customPatientFields={this.removeStandardPatientFields()}
            />
            <PatientInfoModal 
                ref={this.patientInfoRef}
                fromPatientMgmt={true}
                submit={(e) => {
                    this.modifyPatient(e);
                }}
                selectedPatient={this.state.patient}
                portalPatients={this.state.portalPatients}
                states={this.state.states}
                gender={this.state.gender}
                ethnicity={this.state.ethnicity}
                race={this.state.race}
                countries={this.state.countries}
                facilities={this.state.facilities}
                patientFields={this.state.customPatientFields.sort((a,b) => a.Order - b.Order)}
            />
            <ViewTestInfoModal 
              selectedSample={this.state.selectedSample ? this.state.selectedSample : {}} 
              tests={this.state.tests}
              results={this.state.resultTypes}
              getResultsButtonBoolean={this.state.getResultsButtonEnabled}
              
            />
            <div className="container-fluid ">
                <div className={"row"}>
                    {/* <div className="col-12 col-lg-4 col-xl-4 pt-2">
                        <main id="main-content" tabIndex={-1} aria-label="Patient Management">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Patient Sample Filter</h4>
                                </div>
                                <div className="card-body">
                                    {this.renderPatientRecordFilterFields()}
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline "}
                                            onClick={ () => {
                                                this.setState({
                                                    showLoading: true,
                                                    selected_page: {label: 1, value: 1}}, async () => {
                                                    console.log(this.state.filter)
                                                    let result = await SamplesAPI.getFilteredSamples(this.state.selected_page.value, {
                                                      filter: this.getFilteredObj(),
                                                    })
                                                    if (result.totalSamples < 1) {
                                                      sweetalert.fire({
                                                        icon: "error",
                                                        title: "",
                                                        text: "No samples found",
                                                      });
                                                      this.setState({ showLoading: false, tableData: [], totalSamples: 0 });
                                                      return;
                                                    }
                                                    console.log(result)
                                                    this.setState({
                                                      totalSamples: result.totalSamples,
                                                      showLoading: false,
                                                      page_options: getPageOptions(result.num_pages),
                                                      tableData: result.table_data,
                                                      queryResults: result.table_data,
                                                      searched: this.getFilteredObj(),
                                                    });
                                                    // this.queryPatients(this.state.selected_page.value)
                                                })
                                            }}
                                    >Search</button>
                                </div>
                            </div>
                        </main>
                    </div> */}
                    <div className="col-12 col-lg-8 col-xl-4 pt-2">
                        
                            {this.state.customPatientFields && this.state.patientInfo ? <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Patient Info</h4>
                                </div>
                                <div className="card-body">
                                  {/* <div className="col-4">
                                    {buildRow("First Name",
                                        <input 
                                            id="FirstName"
                                            className={"form-control"}
                                            autoComplete={"off"}
                                            readOnly={true}
                                            type={"search"}
                                            name={"FirstName"}
                                            aria-label="First Name"
                                            value={this.state.patient?.firstName}
                                        />
                                    )}
                                    {buildRow("Last Name",
                                        <input 
                                            id="LastName"
                                            className={"form-control"}
                                            autoComplete={"off"}
                                            readOnly={true}
                                            type={"search"}
                                            name={"LastName"}
                                            aria-label="Last Name"
                                            value={this.state.patient?.lastName}
                                        />
                                    )}
                                  </div>
                                  <div className="col-4">
                                    
                                  </div>
                                  <div className="col-4">
                                    
                                  </div> */}
                                  <div className="row">
                                    {this.state.customPatientFields && this.state.customPatientFields.length > 0 && this.state.customPatientFields.map((val, index) => {
                                        if (val.Options.isActive) {
                                          let value = null;
                                          if(this.state.patientInfo?.CustomFieldData && Object.keys(this.state.patientInfo?.CustomFieldData).length > 0){
                                            if(val.FieldTypeID === CustomFields.DATE_FIELD){
                                              value = moment(this.state.patientInfo?.CustomFieldData[val.DisplayName]).utc().format("MM/DD/YYYY")
                                            } else {
                                              value = this.state.patientInfo?.CustomFieldData[val.DisplayName]
                                            }
                                              if(over18 && val.DisplayName.toLowerCase().includes('guardian')){
                                                  return <div className="d-none"></div>
                                              }
                                              return (
                                              <div className="col-12">
                                                  <div className="form-group row px-3">
                                                  {/* <GetField patientSearchReadOnly={true} fromSamples={true} val={val} selectedItem={  this.state.patientInfo ? this.state.patientInfo : {} } handleChange={(e) => { this.setState({ patient: e, }); }} /> */}
                                                  
                                                  {val.FieldTypeID === CustomFields.DATE_FIELD ? 
                                                    <p className="d-flex justify-content-between mw-100" style={{minWidth: "85%"}}><strong className="mr-3">{val.DisplayName}:</strong><section className="w-50">{value}</section></p> 
                                                    : <p className="d-flex justify-content-between mw-100" style={{minWidth: "85%"}}><strong className="mr-3">{val.DisplayName}:</strong><section className="w-50">{value}</section></p> 
                                                    }
                                                  </div>
                                              </div>
                                              );
                                          }
                                          
                                        }
                                    })}
                                </div>
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline "}
                                            onClick={() => {
                                                    showModalNoOutsideClick(PatientInfoModal.ID)
                                            }}
                                    >Edit</button>
                                </div>
                            </div> : <></>}
                        
                    </div>



                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-12 pt-2">
                            <div className="card mt-2 mb-5">
                                <div className="card-header verlag-bold stickToTop">
                                    <h4 className="text-center text-md-left">Sample History
                                    <button className={"ml-2 d-none d-md-inline btn btn-outline-primary"} onClick={() => this.handleExportToCSV()} >Export to CSV </button>
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                    <h4 style={{ float: "right" }} aria-label="Total Records" role="alert">Total: {this.state.totalSamples}</h4>
                                    <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                    <div className=" align-middle float-right pages ">
                                        <Select
                                            isSearchable={true}
                                            placeholder={"1"}
                                            noOptionsMessage={() => "No option"}
                                            aria-label="Table Page Number"
                                            value={this.state.selected_page}
                                            onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                () => this.changePage(e.value))}
                                            className={"state_select"}
                                            options={this.state.page_options}
                                        />
                                    </div>
                                    </section>    
                                    </h4>
                                </div>
                                <div className="p-0 table-responsive">
                                <SimpleTable ref={el => (this.componentRef = el)} columns={[
                                    {label:"Name", key:"Name", rawFormat:(val:Sample) => {
                                            return <a href={"#top"}
                                                      className={'tableNameLinkColor'}
                                                      onClick={() => this.setState({getResultsButtonEnabled: val.ResultIDs ? !!(JSON.parse(val.ResultIDs).length > 0) : false, selectedSample:JSON.parse(JSON.stringify(val))}, () => showModalNoOutsideClick(PatientRecordModal.ID))}>{val.PatientFirstName + " " + val.PatientLastName}</a>}},
                                    {label:"DOB", key:"PatientDOB", formatFunc:parseDate},
                                    {label:"Appt #", key:"LinkedApptsID"},
                                    {label:"Conf #", key:"ReqNum"},
                                    {label:"Collection Date", key:"CollectionDate", formatFunc:toLocalDateTime},
                                    {label:"Phone #", key:"PatientPhone"},
                                    {label:"Specimen ID", key:"SpecimenID"},
                                    {label:"Int. Specimen ID", key:"InternalSpecimenID"},
                                    {label:"Batch #", key:"BatchNum"},
                                    {label:"Service", key:"ServiceID", rawFormat:(val) => {
                                      if(val.ServiceID){
                                        return(<p>{getNameFromID(val.ServiceID, this.state.services)}</p>)
                                      }
                                      else{
                                        return null
                                      }
                                    }},
                                    {label:"Test Info", key:"",
                                      rawFormat: (val:Sample) => (
                                      JSON.parse(val.TestIDs)?.length > 0 ?
                                      <button 
                                        type={'button'} 
                                        className={'btn btn-outline-success'} 
                                        onClick={() =>  this.setState({getResultsButtonEnabled: val.ResultIDs ? !!(JSON.parse(val.ResultIDs).length > 0) : false, selectedSample:JSON.parse(JSON.stringify(val))}, () => showModalNoOutsideClick(ViewTestInfoModal.ID))}>{JSON.parse(val.TestIDs).length > 1 ? 'View Tests Info' : 'View Test Info'}
                                      </button> : <div>No Test Assigned</div> ) 
                                    },
                                    {label:"Result Accessed", key:"ResultsAccessedDate",
                                      rawFormat: (val:Sample) => (
                                      val.ResultsAccessedDate ? 
                                      <div>{toLocalDateTime(val.ResultsAccessedDate)}</div> : 
                                      <p>Not Accessed</p> )
                                    }
                                ]} table_data={this.state.tableData} columnClickedCallback={(col =>{
                                    this.useSorter(col);
                                })}/>
                              </div>
                            </div>
                            
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>)
    }
}