import React from "react";
import FilterCard, { FIELD_TYPE } from "../FilterCard";
import Overlay from "../Overlay";
import ServicesAPI from "../../network/ServicesAPI";
import ResultsAPI from "../../network/ResultsAPI";
import CommTemplatesAPI from "../../network/CommTemplatesAPI";
import TestsAPI from "../../network/TestsAPI";
import BatchesAPI from "../../network/BatchesAPI";
import AdminAPI from "../../network/AdminAPI";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import { isEmptyObject } from "jquery";
import { AuditLog } from "./AuditLog";
import PatientAPI from "../../network/PatientAPI";
import SystemAPI from "../../network/SystemAPI";
import PricingAPI from "../../network/PricingAPI";
import DiscountAPI from "../../network/DiscountAPI";

interface AuditLogsState {
  show_loading: boolean;
  filter: object;
  AuditTypeList: any;
  facilities: any;
  testTypes: any;
  batches: any;
  batchesForSamples: any;
  services: any;
  templates: any;
  customFields: any;
  customQuestions: any;
  customPatientFields: any;
  labinfo: any;
  insurance: any;
  tests: any;
  results: any;
  patients: any;
  races: any;
  genders: any;
  ethnicities: any;
  pswdConfigs: any;
  files: any;
  banners: any;
  branding: any;
  pricing:any;
  discount:any;
}

export class AuditLogs extends React.Component<any,AuditLogsState>{

    constructor(props) {
        super(props);
        this.state = {
            show_loading: false,
            filter: {},
            AuditTypeList:[
                { label: "Samples", value: "sample" },
                { label: "Facility", value: "facility" },
                // { label: "Schedule", value: "schedule" },
                // { label: "Alert", value: "alert" },
                { label: "Service", value: "service"},
                { label: "Result", value: "result"},
                { label: "Communication", value: "commTemplate"},
                { label: "Lab Information", value: "labInformation"},
                { label: "Insurance", value: "insurance" },
                { label: "Test", value: "test" },
                { label: "Custom Field", value: "customField" },
                { label: "Custom Question", value: "customQuestion" },
                { label: "Patient Field", value: "customPatientField" },
                { label: "Patient", value: "patient" },
                { label: "Password Requirements", value: "pswdReq" },
                { label: "Banner", value: "banner" },
                { label: "File", value: "file" },
                { label: "Pricing", value:"pricing"},
                { label: "Discount", value:"discount"}

            ],
            facilities: [],
            testTypes: [],
            batches: [],
            batchesForSamples: [],
            services: [],
            results: [],
            templates: [],
            customFields: [],
            customQuestions: [],
            customPatientFields: [],
            labinfo: [],
            insurance: [],
            tests: [],
            patients: [],
            races: [],
            genders: [],
            ethnicities: [],
            pswdConfigs: [],
            files: [],
            banners: [],
            branding: [],
            pricing:[],
            discount:[]
        };
    }

  componentDidMount() {
    document.title = 'Audit Logs Page';
    this.setState({ show_loading: true }, () => {
      TestsAPI.getAllTestDetails().then((data) => {
        let mappedTests = data.tests.map(t => {
          return {
            label: t.Name,
            value: t.ID
          }
        })
        this.setState({ tests: mappedTests, show_loading: false });
      });
      BatchesAPI.getBatchesForSamples().then((data) => {
        this.setState({
          batchesForSamples: data,
          batches: data.map((obj) => {
            return obj.label;
          }),
        });
      });
      AdminAPI.getFacilitiesForSubmissionForm().then((data) => {
        let facilityNamesMapped = data.authorizedFacilities.map((fac) => {
          return {
            label: fac.FacilityName,
            value: fac.ID,
          };
        });
        this.setState({ facilities: facilityNamesMapped });
      });
      ServicesAPI.getAllServicesForApptForm().then((data) => {
        this.setState({
          services: data.data.map((obj) => {
            return obj.Name;
          }),
        });
      });
      CommTemplatesAPI.getEditableTemplates().then((data) => {
        this.setState({
          templates: data.data.map((obj) => {
            return obj.Name;
          }),
        });
      });
      CustomFieldsAPI.getAllCustomFields().then((data) => {
        let customFields = data.data.customFields.map((obj) => {
          return obj.DisplayName;
        })
        let customQuestions = data.data.customQuestions.map((obj) => {
          return obj.DisplayName;
        })
        let customPatientFields = data.data.customPatientFields.map((obj) => {
          return obj.DisplayName;
        })
        this.setState({
          customFields: customFields,
          customQuestions: customQuestions,
          customPatientFields: customPatientFields
        });
      });
      ResultsAPI.getAllResultsData().then(data => {
        let resultsList = data.data.results.map(result => ({label: result.Value, value: result.Value}))
        this.setState({results: resultsList})
        })
      });
      PatientAPI.getPatientsFromPatientPortal().then(async data => {
        this.setState({patients: data.patients})
      })
      SystemAPI.getAllRaces().then((data) => {
        this.setState({ races: data });
      });
      SystemAPI.getAllGenders().then((data) => {
        this.setState({ genders: data });
      });
      SystemAPI.getAllEthnicities().then((data) => {
        this.setState({ ethnicities: data });
      });
      let productID;
      let currentURL = window.location.href;
      SystemAPI.getProductBrandingFromURL(currentURL).then(dataFromProduct => {
          productID = dataFromProduct.ProductID;
          AdminAPI.getDocuments().then(data => {
            let documentList = data.documents.map(document => ({label: document.DocumentName, value: document.ID}))
              this.setState({files: documentList})
          })
          SystemAPI.getBanners(productID).then(data => {
            let mappedBanners = data.banners.map(m => {return {label: m.ProductName, value: m.BannerID}})
            this.setState({banners: mappedBanners})
          })
      })
      SystemAPI.getBranding().then(data => {
        this.setState({branding: data.branding, show_loading: false})
      })
      
      PricingAPI.getAllPricingForSelect().then((response)=>{
        this.setState({pricing:response.data})
      })
      
      DiscountAPI.getAllDiscountsForSelect().then((response)=>{
        this.setState({discount:response.data})
      })
  }

  render() {
    return (
      <React.Fragment>
        <Overlay show_loading={this.state.show_loading} />
        <div className="container-fluid  min-vh-100 ">
          <div className={"row"}>
              <div className="col-12 col-lg-6 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="Audit Logs">
                <div
                  className={
                    this.state.filter["AuditType"]
                      ? "card mt-2 border-bottom-0"
                      : "card mt-2"
                  }
                >
                  <div className="card-header verlag-bold">
                    <h4>Audit Logs</h4>
                  </div>
                  <div className="card-body">
                    <FilterCard
                      fields={[
                        {
                          label: "Audit Type",
                          key: "AuditType",
                          type: FIELD_TYPE.SELECT,
                          options: this.state.AuditTypeList,
                          isMapped: true,
                          isMulti: false,
                          textType: "text",
                        },
                      ]}
                      filterChanged={(filter) =>
                        this.setState({ filter: filter })
                      }
                    />
                  </div>
                </div>
                </main>
              </div>
          </div>

          {!isEmptyObject(this.state.filter) && (
            <AuditLog
              key={this.state.filter["AuditType"]}
              auditLogType={this.state.filter["AuditType"]}
              batchesForSamples={this.state.batchesForSamples}
              batches={this.state.batches}
              templates={this.state.templates}
              facilities={this.state.facilities}
              services={this.state.services}
              customFields={this.state.customFields}
              customQuestions={this.state.customQuestions}
              customPatientFields={this.state.customPatientFields}
              tests={this.state.tests}
              results={this.state.results}
              patients={this.state.patients}
              races={this.state.races}
              genders={this.state.genders}
              ethnicities={this.state.ethnicities}
              files={this.state.files}
              banners={this.state.banners}
              branding={this.state.branding}
              pricing={this.state.pricing}
              discount={this.state.discount}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}