import NetworkUtil from "./NetworkUtil";
import {CustomFieldType} from "../types/CustomFieldType";
import {ServerResponse} from "../types/ServerResponse";

import Banner from "../types/Banner";
import TestType from "../types/TestType";
import { PasswordConfigurationValues } from "../types/PasswordConfig";

export default class SystemAPI {

    public static async getBanner(id):Promise<{show:boolean, banner:Banner}>{
        return NetworkUtil.makePost("/api/system/banner", {id: id});
    }

    public static async getBanners(id):Promise<{show:boolean, banners:Banner[]}>{
        return NetworkUtil.makeGet("/api/system/getBanners?productID="+id);
    }

    public static async getProductBrandingFromURL(url):Promise<{ProductID:number, Product:string, ProductLogoURL:string, Name:string, ProductBackgroundImageURL:string, ProductButtonColorHexValue:string, ProductAdminNavLogoURL:string, ProductAdminNavBackgroundColorHexValue:string, ProductHelpEmail:string, ProductAltLogoURL:string, reason?:string, success:boolean}>{
        return NetworkUtil.makePost("/api/system/logoURL", {currentURL:url});
    }

    public static async getBranding():Promise<{branding}>{
        return NetworkUtil.makeGet("/api/system/branding");
    }

    public static async getAllStates():Promise<{label:string, value:string}[]>{
        return NetworkUtil.makeGet("/api/system/states");
    }

    public static async getAllCountries():Promise<{countries}>{
        return NetworkUtil.makeGet("/api/system/countries");
    }

    public static async getAllRaces():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/race");
    }

    public static async getAllGenders():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/gender");
    }

    public static async getAllEthnicities():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/ethnicity");
    }

    public static async getAllSpecimenSources():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/specimensource");
    }

    public static async getAllSpecimenSourcesForTestingCheckin():Promise<{success:boolean, reason:string, data?:[]}>{
        return NetworkUtil.makeGet("/api/system/specimensourceForTestingCheckin");
    }

    public static async getAllTests():Promise<TestType[]>{
        return NetworkUtil.makeGet("/api/system/tests");
    }


    public static async getConsentEntities():Promise<{ID:number,Name:string,FacilityID:number,AccessCode:string}[]>{
        return NetworkUtil.makeGet("/api/system/consentEntities");
    }

    public static async getAllPods(includeData:boolean=false):Promise<any>{
        return NetworkUtil.makePost("/api/admin/pods/filter", {includedata: includeData})
    }
    
    public static async getSpecimenLabels(body):Promise<{success:boolean, idList:any}>{
        return NetworkUtil.makePost("/api/admin/specimen", body)
    }
    
    public static async getCustomAppointmentQuestions():Promise<CustomQuestionsReponse>{
        return NetworkUtil.makeGet("/api/system/appointment/questions");
    }

    public static async getCustomQuestions(page):Promise<CustomQuestionsReponse>{
        return NetworkUtil.makePost("/api/system/appointment/questions", {page: page});
    }

    public static async getCustomQuestionsByID(id):Promise<CustomQuestionsReponse>{
        return NetworkUtil.makePost("/api/system/appointment/questions", {id: id});
    }

    public static async getPasswordRequirementConfig():Promise<{success:boolean,reason:string,data:PasswordConfigurationValues}>{
        return NetworkUtil.makeGet("/api/system/getPswdConfig");
    }

    public static async getAllResultTypes():Promise<{label:string, value:number, description:string}[]>{
        return NetworkUtil.makeGet("/api/system/resulttypes");
    }

    public static async getReportTypes():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/reportTypes");
    }

    public static async serviceIsRapid(serviceID){
        return NetworkUtil.makePost("/api/system/serviceIsRapid", {serviceID: serviceID});
    }

    public static async assignedFacilityHasRapidInfo(serviceID){
        return NetworkUtil.makePost("/api/system/assignedFacilityHasRapidInfo", {serviceID: serviceID});
    }
}

interface CustomQuestionsReponse extends ServerResponse{
    questions:CustomFieldType[]
}