import NetworkUtil from "./NetworkUtil";
import { Patient } from "../types/Patient";
import {ServerResponse} from "../types/ServerResponse";

export default class PatientAPI {

    public static async getPatientsFromPatientPortal():Promise<{patients: [], success:boolean, reason:string}>{
        return NetworkUtil.makeGet("/api/admin/patient/getPatientsFromPatientPortal");
    }

    public static async filterPatients(filter):Promise<{success:boolean, data:Patient[], reason:string}>{
        return await NetworkUtil.makePost("/api/admin/patient/patientfilter", filter);
    }

    public static async editPatient(body):Promise<ServerResponse>{
        return await NetworkUtil.makePost("/api/admin/patient/edit", body);
    }

}