import React from "react";
import SystemAPI from "../network/SystemAPI";
import InBetweenOverlay from "./InBetweenOverlay";
import Overlay from "./Overlay";

interface HomeState {
    logoURL:string
    productName:string
    buttonColorHexValue:string
    showLoading:boolean
}

class Home extends React.Component<{}, HomeState> {
    constructor(props) {
        super(props);
        this.state = {
            logoURL: '',
            productName: '',
            showLoading: false,
            buttonColorHexValue: '',
        };
    }

    componentDidMount(): void {
        document.title = 'Home';
        this.setState({showLoading: true},  async () => {
            document.body.style.backgroundColor = 'black';
               
            let currentURL = window.location.href;

            SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
                let backgroundImageURL = data.ProductBackgroundImageURL;
                //@ts-ignore
                document.body.style.backgroundImage = `url(${backgroundImageURL}`;
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";
                this.setState({logoURL: data.ProductLogoURL, productName: data.Name, buttonColorHexValue: data.ProductButtonColorHexValue, showLoading: false});
            })
        })
        
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading} />
            <InBetweenOverlay showInBetween={true} zIndex={-10}/>
            <div className={this.state.showLoading ? "d-none" : "container min-vh-100 "}>
                <div className="row responsive-p mt-5">
                    <div className={"col-12 pb-4"}>
                        <h1 className={"text-center verlag-light"}>
                            <img src={this.state.logoURL}
                                className={"homepage-logo"}
                                alt={this.state.productName}/>
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p className="text-center text-light mt-3">Welcome to the <b>{this.state.productName} Portal</b></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-center mt-3">
                        <a href={"/login"} className={"btn btn-lg text-light"} style={{backgroundColor: this.state.buttonColorHexValue}}>
                            
                                Log In
                            
                        </a>
                        <a href={"/results"} className={"btn btn-lg text-light ml-2"} style={{backgroundColor: this.state.buttonColorHexValue}}>
                            
                                Results
                            
                        </a>
                    </div>
                </div>
            </div>
            
        </React.Fragment>)
    }
}
export default Home;