import React from "react";
import {
    getFieldTypeLabel,
    hideModal,
    showModalNoOutsideClick,
    getBoolSelectYN
} from "../../util/FormatUtil";
import { CustomFieldsModal } from "./CustomFieldsModal";
import InBetweenOverlay from "../InBetweenOverlay";
import Overlay from "../Overlay";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import { sweetalert } from "../../App";
import {BiMoveVertical} from "react-icons/bi";

interface PatientFieldsOrderState{
    selectedPatientField?
    showInBetween
    patientFields
    showLoading
    showSaveOrderButton
    mouseGrabbing
}
interface PatientFieldsOrderProps{
    selectedPatientField?
    handleInBetween?
    allPatientFields
    selectedService?
}
export class PatientFieldsOrderModal extends React.Component<PatientFieldsOrderProps, PatientFieldsOrderState>{
    public dragItemRef;
    public dragOverItemRef;
    public static ID = "patientFieldsOrder";
    
    constructor(props) {
        super(props);
        
        this.state = {
          showSaveOrderButton: false,
          showLoading: false,
          showInBetween: false,
          patientFields: [],
          mouseGrabbing: false
        }

        this.dragItemRef = React.createRef();
        this.dragOverItemRef = React.createRef();
    }

    componentDidMount(): void {
      $(`#${PatientFieldsOrderModal.ID}`).on('shown.bs.modal', function() {
        $(document).off('focusin.modal');
    });
    }

    componentWillReceiveProps(nextProps:any, nextContext: any) {
        console.log('nextProps', nextProps)
        this.setState({patientFields: nextProps.allPatientFields ? nextProps.allPatientFields.sort((a, b) => a.Order - b.Order) : []})
    }

    handleSort = () => {
      //duplicate items
      let _patientFields = [...this.state.patientFields];

      //remove and save the dragged item content
      const draggedItemContent = _patientFields.splice(this.dragItemRef.current, 1)[0]

      //switch the position
      _patientFields.splice(this.dragOverItemRef.current, 0, draggedItemContent)

      //reset the position ref         
      this.dragItemRef.current = null
      this.dragOverItemRef.current = null

      //map through patientFields to get new order
      _patientFields.map((q, index) => {
          q.Order = index + 1
      })

      //update the state
      this.setState({patientFields: _patientFields, showSaveOrderButton: true})
    }

    saveOrder = () => {
      this.setState({showLoading: true}, async() => {
        let response = await CustomFieldsAPI.updateSortOrder(this.state.patientFields)
        if(!response.success){
          let msg = `Unable to update sort order at this time.`;
          if(response.reason){
            msg = response.reason;
          }
          this.setState({showLoading: false})
          return sweetalert.fire({icon: 'error', title: "", text: msg});
        }
        sweetalert.fire({icon: "success", title: "", text: `Sort order saved`})
          .then(() => {
            this.setState({showLoading: false, showSaveOrderButton: false})
            this.props.handleInBetween()
            hideModal(PatientFieldsOrderModal.ID)
          })
      })
    }
   
    render(){
        
        return(
            <>
            <Overlay show_loading={this.state.showLoading} zIndex={100005}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100004} />
            <div className="modal fade form_modal " id={PatientFieldsOrderModal.ID} tabIndex={-1} style={{zIndex: 100003}} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                              <h5 className="modal-title" id="result_modal_label">Patient Fields Order Management {this.props.selectedService ? `for ${this.props.selectedService.Name}` : ''}</h5>
                              <button  style={{outline: 'none'}} type="button" className="close" data-dismiss="modal" aria-label="Close" 
                              onClick={() => {
                                this.setState({showSaveOrderButton: false})
                                this.props.handleInBetween()
                              }}>
                                  <span aria-hidden="true">&times;</span>
                              </button>                                                             
                        </div>
                        <div className="modal-body m-3 ">

                          {/* mobile  */}
                          {this.state.patientFields.length > 0 ?
                          <div className="d-md-none">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope='col' style={{width: '18%'}}>Display Name</th>
                              <th scope='col' style={{width: '18%'}}>Field Type</th>                              
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.patientFields.length > 0 && this.state.patientFields.map((patientFields, index) => {
                              return (
                                <tr>
                                  <td scope='row'>{patientFields.DisplayName}</td>
                                  <td scope='row'>{getFieldTypeLabel(patientFields.FieldTypeID)}</td>
                                </tr>
                            )})}
                            
                          </tbody>
                        </table>
                        </div>
                        : 
                        <div className="d-md-none text-center text-bold" style={{fontSize: '1.5rem'}}>No Patient Fields to display</div>

                        }


                         {/* tablet  */}
                         {this.state.patientFields.length > 0 ?
                          <div className="d-none d-md-block d-lg-none">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope='col' style={{width: '18%'}}>Display Name</th>
                              <th scope='col' style={{width: '18%'}}>Field Type</th>   
                              <th scope='col' style={{width: '18%'}}>Active</th>
                              <th scope='col' style={{width: '18%'}}>Required?</th>                           
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.patientFields.length > 0 && this.state.patientFields.map((patientFields, index) => {
                              return (
                                <tr>
                                  <td scope='row'>{patientFields.DisplayName}</td>
                                  <td scope='row'>{getFieldTypeLabel(patientFields.FieldTypeID)}</td>
                                  <td scope='row'>{getBoolSelectYN(patientFields.Options.isActive)}</td>
                                  <td scope='row'>{getBoolSelectYN(patientFields.Options.isRequired)}</td>
                                </tr>
                            )})}
                            
                          </tbody>
                        </table>
                        </div>
                        : 
                        <div className="d-none d-md-block d-lg-none text-center text-bold" style={{fontSize: '1.5rem'}}>No Patient Fields to display</div>

                        }



                          {/* desktop  */}

                        {this.state.patientFields.length > 0 ?
                        <div className="d-none d-lg-block">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope='col' style={{width: '10%'}}>Sort</th>
                              <th scope='col' style={{width: '18%'}}>Display Name</th>
                              <th scope='col' style={{width: '18%'}}>Field Type</th>
                              <th scope='col' style={{width: '18%'}}>Required?</th>
                              <th scope='col' style={{width: '18%'}}>PHI?</th>
                              <th scope='col' style={{width: '18%'}}>Active</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.patientFields.length > 0 && this.state.patientFields.map((patientFields, index) => {
                              return (
                              <tr 
                                key={index} 
                                draggable 
                                onDragStart={(e) => this.dragItemRef.current = index}
                                onDragEnter={(e) => this.dragOverItemRef.current = index}
                                onDragEnd={this.handleSort}
                                onDragOver={(e) => e.preventDefault()}
                              >
                                <td scope='row'><BiMoveVertical /></td>
                                <td scope='row'>{patientFields.DisplayName}</td>
                                <td scope='row'>{getFieldTypeLabel(patientFields.FieldTypeID)}</td>
                                <td scope='row'>{getBoolSelectYN(patientFields.Options.isRequired)}</td>
                                <td scope='row'>{getBoolSelectYN(patientFields.Options.isPHI)}</td>
                                <td scope='row'>{getBoolSelectYN(patientFields.Options.isActive)}</td>
                              </tr>
                            )})}
                            
                          </tbody>
                        </table>
                        </div>
                        : 
                        <div className="d-none d-lg-block text-center text-bold" style={{fontSize: '1.5rem'}}>No Patient Fields to display</div>
                        }

                        </div>
                        <div className="modal-footer">
                          <button type="button" className={this.state.showSaveOrderButton ? "btn btn-primary" : "invisible" } onClick={this.saveOrder}>
                                Save Order
                          </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}