import * as React from "react"
import Overlay from "../Overlay"
import { sweetalert } from "../../App"
import SystemAPI from "../../network/SystemAPI"
import { PasswordConfiguration, DefaultPasswordConfiguration, PasswordConfigurationValues, DefaultPswdConfigValues } from "../../types/PasswordConfig"
import Validator, { ValidationEntry } from "../../validation/Validator"
import { Validators } from "../../validation/Validators"
import AdminAPI from "../../network/AdminAPI"

interface PasswordSettingsState {
    showLoading:boolean
    pswdSettingsArrowDown?
    pswdConfigs:PasswordConfiguration
    pswdConfigValues:PasswordConfigurationValues // object of the values of the PasswordConfiguration stored in the DB
    formChanged:boolean
}
interface PasswordSettingsProps {}

export class PasswordSettings extends React.Component<PasswordSettingsProps,PasswordSettingsState>{
    constructor(props){
        super(props);
        this.state = {
            pswdSettingsArrowDown: true,
            showLoading: false,
            pswdConfigs: {
                required: "",
                minLength:{value: null,message: ""},
                pattern: {value: null,message: ""}
            }, // holds the object used to set the password configuration within the Login Component
            // defaultPswdConfigs: DefaultPasswordConfiguration, // holds the application default settings
            formChanged: false, // set true if form input changes
            pswdConfigValues: null
        }
        this.restoreDefaultPswdConfigs = this.restoreDefaultPswdConfigs.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount(){
        document.title = 'Password Requirements Management Page';
        try {
            this.setState({ showLoading: true }, () => {
                SystemAPI.getPasswordRequirementConfig().then((settings)=>{
                    let pswdConfigValues: PasswordConfigurationValues = settings?.data

                    if (!pswdConfigValues) {
                        return sweetalert.fire({
                            text: 'No Password Configurations found.',
                            title: 'Error',
                            icon: "error"
                        })
                    }
                    // build regex and message used for pattern
                    this.composeRegExp(pswdConfigValues).then((regex)=>{
                        this.setState({
                            pswdConfigValues: pswdConfigValues,
                            pswdConfigs: {
                                required: DefaultPasswordConfiguration.required,
                                minLength: {
                                    value: Number(pswdConfigValues.numChar),
                                    message: `Password should contain at least ${pswdConfigValues.numChar} characters`,
                                },
                                pattern: {
                                    value: new RegExp(regex.configString),
                                    message: regex.configMessage
                                }
                            },
                            showLoading: false
                        });
                    })
                }); // end getPasswordRequirementConfig()
            });
        } catch(e){
            console.error(e);
            sweetalert.fire({
                icon:'error',
                title: 'Error',
                text: e.message
            });
            this.setState({showLoading:false});
        }
    }

    restoreDefaultPswdConfigs(e){
        e.preventDefault();
        sweetalert.fire({
            title: 'Attention',
            html:`<p>Restoring default Password Requirements will force <b>all</b> users to reset their password the next time they log into the application.</p><p>Are you sure you want to continue?</p>`,
            showCloseButton: false,
            showDenyButton: true,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'Restore Defaults',
            denyButtonText: 'Cancel',
        }).then(async (response) => {
            if (response.isConfirmed) {
                this.setState({
                    pswdConfigs: DefaultPasswordConfiguration,
                    pswdConfigValues: DefaultPswdConfigValues
                }, async () => {
                    let response = await AdminAPI.setPswdConfig(this.state.pswdConfigValues)
                    if(response.success){
                        sweetalert.fire("Password Requirements Updated.")
                    }
                    if(!response.success){
                        sweetalert.fire(response.reason)
                    }

                });
            }

            return;
        });
    }

    async composeRegExp(pswdConfigValues) {
        let upper = pswdConfigValues.numUpper
        let symbol = pswdConfigValues.numSymbols
        let digits = pswdConfigValues.numDigits
        let char = pswdConfigValues.numChar

        // build string
        let startAnchor = `^`
        let newUpper = `(?=(.*[A-Z]){${upper}})`
        let newSymbol = `(?=(.*[!@#$%^&*]){${symbol}})`
        let newDigits = `(?=(.*[0-9]){${digits}})`
        let newChar = `.{${char},}`
        let endAnchor = `$`

        let regExpString = startAnchor + newUpper + newSymbol + newDigits + newChar + endAnchor;

        // build message 
        let message = `Password should contain at least ${char} characters `

        if ((upper  && upper  !== "0") ||
            (symbol && symbol !== "0") || 
            (digits && digits !== "0")
        ){
            message += `and include `
        }

        if(upper && upper !== "0"){ message += ` ${upper} uppercase (A-Z)` }
        if(symbol && symbol !== "0"){ message += ` ${symbol} symbol (!@#$%^&*) ` }
        if(digits && digits !== "0"){ message += ` ${digits} digits (0-9) ` }
        
        return { configMessage: message, configString: regExpString}
    }

    async onSubmit(e){
        e.preventDefault();
        let validateForm = await this.validateFormValues(e);

        if(!validateForm.success){ return sweetalert.fire({icon:'error',title:'',text: validateForm.error})}

        if(validateForm.success){
            let totalCountOfDigitsSymbolsUpper = Number(this.state.pswdConfigValues.numDigits) + Number(this.state.pswdConfigValues.numSymbols) + Number(this.state.pswdConfigValues.numUpper)
            let maxCharCount = Number(this.state.pswdConfigValues.numChar)
            let exceedsMaxCharCount = totalCountOfDigitsSymbolsUpper > maxCharCount;
            if (exceedsMaxCharCount){
                return sweetalert.fire({
                    icon:'error',
                    title:'',
                    text: "The combined number of required Digits, Symbols, and Uppercase exceeds the total character count."
                })
            } else {
                sweetalert.fire({
                    title: 'Attention',
                    html:`<p>Updating Password Requirements will force <b>all</b> users to reset their password the next time they log into the application.</p><p>Are you sure you want to continue?</p>`,
                    showCloseButton: false,
                    showDenyButton: true,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: 'Update Requirements',
                    denyButtonText: 'Cancel',
                }).then(async (response)=>{
                    if(response.isConfirmed){
                        let configResponse = await AdminAPI.setPswdConfig(this.state.pswdConfigValues)

                        if(!configResponse.success){
                            return sweetalert.fire({icon:'error',title:'',text: configResponse.reason})
                        }
                        if(configResponse.success){
                            return sweetalert.fire({icon:'success',title:'Success',text:'Users will be prompted to reset their password on their next login.'})
                        }
                    }

                    return;
                });
            }
        }
    }

    async validateFormValues(e){
        e.preventDefault();
        let formValidation = {
            numChar: this.state.pswdConfigValues.numChar,
            numUpper: this.state.pswdConfigValues.numUpper,
            numSymbols: this.state.pswdConfigValues.numSymbols,
            numDigits: this.state.pswdConfigValues.numDigits
        }
        let validator = new Validator<any>()
            .withComposedValidation("numChar",
                new ValidationEntry(Validators.requireNotBlankValidator("Total Number of Characters")),
                new ValidationEntry(Validators.requireRange(8,64, "Total Number of Characters")), 
                new ValidationEntry(Validators.requireIsInt("Total Number of Characters"))
            )
            .withComposedValidation("numUpper",
                new ValidationEntry(Validators.requireNotBlankValidator("Total Number of UPPERCASE")),
                new ValidationEntry(Validators.requireRange(0,Number(this.state.pswdConfigValues.numChar), "Total Number of UPPERCASE")),
                new ValidationEntry(Validators.requireIsInt("Total Number of UPPERCASE"))
            )
            .withComposedValidation("numSymbols",
                new ValidationEntry(Validators.requireNotBlankValidator("Total Number of Symbols")),
                new ValidationEntry(Validators.requireRange(0,Number(this.state.pswdConfigValues.numChar), "Total Number of Symbols")), 
                new ValidationEntry(Validators.requireIsInt("Total Number of Symbols"))
            )
            .withComposedValidation("numDigits",
                new ValidationEntry(Validators.requireNotBlankValidator("Total Number of Digits")),
                new ValidationEntry(Validators.requireRange(0,Number(this.state.pswdConfigValues.numChar), "Total Number of Digits")), 
                new ValidationEntry(Validators.requireIsInt("Total Number of Digits"))
            )
            
        
        let validationResponse = validator.validate(formValidation)
        return validationResponse;
    }

    render() {
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading} />
                <div className="container-fluid  min-vh-100 ">
                    <div className={"row"}>
                        <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
                            <main id="main-content" tabIndex={-1} aria-label="Password Requirements for Login">
                                <div className="card mb-2">
                                    <div className="card-header verlag-bold">
                                        <h4>Password Requirements for Login</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="small">
                                            <p>Set the password strength requirements for your users.
                                            You may select the total number of characters, number of uppercase characters, number of digits, and number of symbols required to compose a secure password.</p>
                                            <p>Please Note:
                                                The minimum number of characters required for a secure password is 8.
                                                By default this application is set to require at least 14 characters composed with:
                                                <ul>
                                                    <li>1 uppercase A-Z</li>
                                                    <li>1 digit 0-9</li>
                                                    <li>1 symbol !@#$%^&*</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <form id="PswdReqConfigForm" name="PswdReqConfigForm" noValidate>
                                            <div className="form-group row">
                                                <label htmlFor={"numChar"} className="col-sm-4 col-form-label"
                                                >Total Number of Characters <span style={{ color: "#ec0000", fontSize: "1.2rem" }}>*</span></label>
                                                <div className="col-sm-8  p-0 m-0" id={"numChar"}>
                                                    <input className="form-control"
                                                        id="numChar"
                                                        name={"numChar"}
                                                        aria-label="Total Number of Characters"
                                                        max={64}
                                                        min={8}
                                                        autoComplete="off"
                                                        type={"number"}
                                                        onChange={(e) => {
                                                            this.setState((prevState) => ({
                                                                pswdConfigValues: {
                                                                    ...prevState.pswdConfigValues,
                                                                    numChar: e.target.value
                                                                },
                                                                formChanged: true
                                                            }))
                                                        }}
                                                        value={this.state.pswdConfigValues?.numChar}
                                                        required
                                                    />
                                                </div>
                                                <label htmlFor={"numUpper"} className="col-sm-4 col-form-label"
                                                >Total Number of UPPERCASE<span style={{ color: "#ec0000", fontSize: "1.2rem" }}>*</span></label>
                                                <div className="col-sm-8  p-0 m-0" id={"numUpper"}>
                                                    <input className="form-control"
                                                        id="numUpper"
                                                        max={64}
                                                        min={0}
                                                        autoComplete="off"
                                                        type={"number"}
                                                        name={"numUpper"}
                                                        aria-label="Total Number of UPPERCASE"
                                                        onChange={(e) => {
                                                            this.setState((prevState)=>({
                                                                pswdConfigValues: {
                                                                    ...prevState.pswdConfigValues,
                                                                    numUpper: e.target.value,
                                                                },
                                                                formChanged: true
                                                            }))
                                                        }}
                                                        value={this.state.pswdConfigValues?.numUpper}
                                                        required
                                                    />
                                                </div>
                                                <label htmlFor={"numSymbols"} className="col-sm-4 col-form-label"
                                                >Total Number of Symbols<span style={{ color: "#ec0000", fontSize: "1.2rem" }}>*</span></label>
                                                <div className="col-sm-8  p-0 m-0" id={"numSymbols"}>
                                                    <input className="form-control"
                                                        id="numSymbols"
                                                        max={8}
                                                        min={0}
                                                        autoComplete="off"
                                                        type={"number"}
                                                        name={"numSymbols"}
                                                        aria-label="Total Number of Symbols"
                                                        onChange={(e) => {
                                                            this.setState((prevState)=>({
                                                                pswdConfigValues: {
                                                                    ...prevState.pswdConfigValues,
                                                                    numSymbols: e.target.value,
                                                                },
                                                                formChanged: true
                                                            }))
                                                        }}
                                                        value={this.state.pswdConfigValues?.numSymbols}
                                                        required
                                                    />
                                                </div>
                                                <label htmlFor={"numDigits"} className="col-sm-4 col-form-label"
                                                >Total Number of Digits<span style={{ color: "#ec0000", fontSize: "1.2rem" }}>*</span></label>
                                                <div className="col-sm-8  p-0 m-0" id={"numDigits"}>
                                                    <input className="form-control"
                                                        id="numDigits"
                                                        autoComplete="off"
                                                        type={"number"}
                                                        name={"numDigits"}
                                                        aria-label="Total Number of Digits"
                                                        onChange={(e) => {
                                                            this.setState((prevState)=>({
                                                                pswdConfigValues: {
                                                                    ...prevState.pswdConfigValues,
                                                                    numDigits: e.target.value,
                                                                },
                                                                formChanged: true
                                                            }))
                                                        }}
                                                        value={this.state.pswdConfigValues?.numDigits}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-footer">
                                        <button className="btn btn-outline-primary float-right"
                                            type="submit" onClick={ (e)=>{
                                                // form validations
                                                this.onSubmit(e);
                                            } }
                                            disabled={!this.state.formChanged} >
                                            Update
                                        </button>
                                        <button className="btn btn-outline-danger"
                                            type="button" onClick={ (e)=>{
                                                this.restoreDefaultPswdConfigs(e);
                                            } }>
                                            Restore Defaults
                                        </button>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}